import React from 'react';
import { Col, Row, Typography } from "antd";
import moment from "moment";
import TextLabel from "@/common/TextLabel";
import TextValue from "@/common/TextValue";

const { Text } = Typography;

const ReportExpense = ({ messInfor }) => {
  const {
    creationDate,
    quantityIncome,
    quantityResponse,
    quantityExistAccount,
    quantityTemp,
    quantityReference,
    quantityClosing
  } = messInfor || {}
  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <TextLabel>Số lượng mess về: </TextLabel>{" "}
        <TextValue>{quantityIncome || '--'}</TextValue>
      </Col>
      <Col span={8}>
        <TextLabel>Số lượng mess phản hồi: </TextLabel>{" "}
        <TextValue>{quantityResponse || '--'}</TextValue>
      </Col>
      <Col span={8}>
        <TextLabel>Số lượng mess có tài khoản: </TextLabel>{" "}
        <TextValue>{quantityExistAccount || '--'}</TextValue>
      </Col>
      <Col span={8}>
        <TextLabel>Số lượng mess rác: </TextLabel>{" "}
        <TextValue>{quantityTemp || '--'}</TextValue>
      </Col>
      <Col span={8}>
        <TextLabel>Số lượng mess tham khảo: </TextLabel>{" "}
        <TextValue>{quantityReference || '--'}</TextValue>
      </Col>
      <Col span={8}>
        <TextLabel>Số lượng mess chốt được: </TextLabel>{" "}
        <TextValue>{quantityClosing || '--'}</TextValue>
      </Col>
      <Col span={8}>
        <TextLabel>Thời gian báo cáo: </TextLabel>
        <TextValue>{creationDate ? moment(creationDate + "z").format("DD/MM/YYYY HH:mm") : "--"}</TextValue>
      </Col>
    </Row>
  );
};

export default ReportExpense;