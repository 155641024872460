import React from "react";
import { Button, Col, Form, InputNumber, Row, Select, Typography, Tooltip } from "antd";
import DetailAmountCampForm from "./DetailAmountCampForm";
import DetailAmountForm from "./DetailAmountForm";
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { formatCurrencyVND } from "@/utils/common";
import { getAmountDetail } from "@/utils/utils";
import { useRecoilValue } from "recoil";
import { allProducts } from "@/recoil/atoms";

const CategorySelect = ({ name, restField }) => {
  const listProducts = useRecoilValue(allProducts);
  return (
    <Form.Item
      label="Danh mục đề xuất"
      name={[name, "categoryId"]}
      {...restField}
      rules={[{
        required: true, message: "Vui lòng chọn danh mục",
      },]}
    >
      <Select
        allowClear
        showSearch
        placeholder="Chọn danh mục đề xuất"
        filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
        options={listProducts?.map(product => ({
          label: product.categoryName,
          value: product.id
        }))}
      />
    </Form.Item>
  )
};

const validateAmount = (key, value, dataDetail, isDailyReport) => {
  const amountItem = getAmountDetail(key, dataDetail);

  if (value < 0) {
    return Promise.reject("Số tiền không được âm!");
  }

  if (isDailyReport) {
    
    if (value > dataDetail?.calAmountExpenseRequest?.calculateAmount) {
      return Promise.reject(`Số tiền không được lớn hơn ngân sách khả dụng: ${formatCurrencyVND(dataDetail?.calAmountExpenseRequest?.calculateAmount)}`);
    }
  
    if (value > amountItem.amount) {
      return Promise.reject(`Số tiền không được vượt quá ngân sách phê duyệt cho mục này: ${formatCurrencyVND(amountItem.amount)}`);
    }
  }

  return Promise.resolve();
};

const renderTooltipContent = (key, dataDetail) => {
  const { amountInUse, amount } = getAmountDetail(key, dataDetail);
  return (
    <>
      Số tiền NS đã sử dụng: {formatCurrencyVND(amountInUse)}.<br />
      Số tiền NS đã được duyệt: {formatCurrencyVND(amount)}.<br />
    </>
  );
};

const AmountInput = ({ id, name, restField, dataDetail, isDailyReport, isCloseRequest }) => (
  <Form.Item
    label={
      <>
        Tổng tiền
        {isDailyReport && (
          <Tooltip
            overlayClassName="!min-w-[320px]"
            title={renderTooltipContent(id, dataDetail)}
          >
            <QuestionCircleOutlined className="ml-2" />
          </Tooltip>
        )}
      </>
    }
    {...restField}
    name={[name, "amount"]}
    rules={[
      {
        required: (isDailyReport || isCloseRequest) ? true : false,
        message: "Vui lòng nhập số tiền",
      },
      {
        validator: (_, value) => validateAmount(id, value, dataDetail, isDailyReport),
      },
    ]}
  >
    <InputNumber
      min={0}
      disabled={(isDailyReport || isCloseRequest) ? false : true}
      className={"w-full"}
      placeholder={isDailyReport ? "Nhập số tiền" : ""}
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
    />
  </Form.Item>
);

const AddCategoryForm = ({ dataDetail, totalAmount, isDailyReport, isCloseRequest }) => {
  const spanNumber = 6;

  return (
    <>
      <Form.List name="expenseDetails">
        {(fields, { add, remove }) => (
          <>
            {fields?.map(({ key, name, ...restField }, index) => {
              return (
                <Row key={key} gutter={[10, 0]} className="relative">
                  <Col span={spanNumber}>
                    <CategorySelect
                      name={name}
                      restField={restField}
                    />
                  </Col>
                  {(isDailyReport || isCloseRequest) ? (
                    <DetailAmountCampForm
                      spanNumber={spanNumber}
                      name={name}
                      restField={restField}
                    />
                  ) : (
                    <DetailAmountForm
                      spanNumber={spanNumber}
                      name={name}
                      restField={restField}
                    />
                  )}
                  <Col span={spanNumber-1}>
                    <AmountInput
                      id={key}
                      name={name}
                      restField={restField}
                      dataDetail={dataDetail}
                      isDailyReport={isDailyReport}
                      isCloseRequest={isCloseRequest}
                    />
                  </Col>
                  <Col span={1}>
                    {fields.length > 1 && (
                      <MinusCircleOutlined className="text-[16px] absolute right-1 top-[52px]" onClick={() => remove(name)} />
                    )}
                  </Col>
                </Row>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Thêm danh mục đề xuất
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      {isDailyReport && (
        <Form.Item
          label="Tổng tiền"
          name="totalAmount"
          rules={[{
            validator: (_, value) => {
              if (String(value).length <= 12) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Tổng số tiền không được quá 12 số"));
            },
          },]}
        >
          <Typography.Text>
            {formatCurrencyVND(totalAmount || 0)}
          </Typography.Text>
        </Form.Item>
      )}
    </>
  );
};

export default AddCategoryForm;