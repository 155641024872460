import React from "react";
import { Col, Row, Tag, Tooltip, Typography } from "antd";
import ExpenseLink from "@/common/ExpenseLink";
import { useLocation } from "react-router-dom";
import GroupUserPopover from "@/components/popover/GroupUserPopover";
import moment from "moment";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { addDays, getDateTimeOfNextMonth } from "@/utils/utils";
import TextLabel from "@/common/TextLabel";
import TextValue from "@/common/TextValue";
import CategoryTag from "@/common/CategoryTag";

const { Text } = Typography;

const ExpenseInfo = ({ dataDetail }) => {
  const {
    id,
    refId,
    refCode,
    expenseType,
    processType,
    teamSupervisorCamp,
    userAssignTo,
    assignGroupUser,
    userSupervisorCamp,
    processingUser,
    purpose,
    group,
    creationDate,
    startDate,
    endDate,
    isSupportPackage,
    numOfPage,
    note,
    expenseSpendingHistory,
    calAmountExpenseRequest,
    expenseClose,
  } = dataDetail || {};
  const location = useLocation();
  const route = location.pathname.split("/")[1];
  const expenseCode = `${expenseType?.shortName}${id}`;
  const suffixGroup = purpose?.purposeCode === "Tiktok" ? "a" : "";
  const endReportDate = getDateTimeOfNextMonth(endDate, 1);
  const endApprovalDate = addDays(startDate, 7);
  const shouldDisplayRef =
    [
      "increase-request",
      "decrease-request",
      "daily-report",
      "close-request",
    ].includes(route) &&
    refId &&
    refCode;

  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <TextLabel strong>Mã phiếu: </TextLabel>
        <TextValue>
          <Tag className="mr-0">{expenseCode}</Tag>
        </TextValue>
        {shouldDisplayRef && (
          <>
            <TextValue> | </TextValue>
            <TextLabel>Mã phiếu gốc: </TextLabel>
            <TextValue>
              <Tag className="cursor-pointer hover:opacity-70" color="blue">
                <ExpenseLink id={refId} page="NS">
                  {refCode}
                </ExpenseLink>
              </Tag>
            </TextValue>
          </>
        )}
      </Col>

      {["expense-request"].includes(route) && (
        <>
          <Col span={8}>
            <TextLabel>Phiếu báo cáo: </TextLabel>
            <TextValue>
              {expenseSpendingHistory?.length > 0 ? (
                expenseSpendingHistory?.map((item, index) => (
                  <Tag
                    className={"cursor-pointer text-blue-500 font-bold"}
                    key={index}
                  >
                    <ExpenseLink id={item} page="BC">{`BC${item}`}</ExpenseLink>
                  </Tag>
                ))
              ) : (
                <strong> Chưa có báo cáo</strong>
              )}
            </TextValue>
          </Col>

          {expenseClose && (
            <Col span={8}>
              <TextLabel>Phiếu kết toán: </TextLabel>
              <TextValue>
                <Tag className="cursor-pointer text-blue-500 font-bold">
                  <ExpenseLink
                    id={expenseClose}
                    page="KT"
                  >{`KT${expenseClose}`}</ExpenseLink>
                </Tag>
              </TextValue>
            </Col>
          )}

          <Col span={8}>
            <TextLabel>Số page: </TextLabel>
            <TextValue>{numOfPage ?? "--"}</TextValue>
          </Col>
        </>
      )}

      <Col span={8}>
        <TextLabel>Loại hình quảng cáo: </TextLabel>
        <TextValue>
          <CategoryTag text={purpose?.purposeName} />
        </TextValue>
      </Col>

      <Col span={8}>
        <TextLabel>Quy trình: </TextLabel>
        <TextValue>{processType?.name}</TextValue>
      </Col>

      <Col span={8}>
        <TextLabel>Nhân viên được giao: </TextLabel>
        <TextValue>
          {userAssignTo?.fullName ?? "--"}
          <GroupUserPopover userGroup={assignGroupUser} />
        </TextValue>
      </Col>

      <Col span={8}>
        <TextLabel>Nhóm hậu đài: </TextLabel>
        <TextValue>
          {group?.groupName}
          {suffixGroup}
        </TextValue>
      </Col>

      <Col span={8}>
        <TextLabel>Phụ trách camp: </TextLabel>
        <TextValue>
          {userSupervisorCamp?.fullName
            ? `${userSupervisorCamp.fullName}${
                teamSupervisorCamp?.teamName
                  ? ` - ${teamSupervisorCamp.teamName}`
                  : ""
              }`
            : "--"}
        </TextValue>
      </Col>

      <Col span={8}>
        <TextLabel>Nhân viên xử lý: </TextLabel>
        <TextValue>{processingUser?.fullName || "--"}</TextValue>
      </Col>

      {["daily-report"].includes(route) && (
        <Col span={8}>
          <TextLabel>Xác nhận camp cuối cùng: </TextLabel>
          <TextValue>{dataDetail?.isLastReport ? "Có" : "Không"}</TextValue>
        </Col>
      )}

      {["expense-request"].includes(route) && (
        <Col span={8}>
          <TextLabel>Ngân sách hỗ trợ: </TextLabel>
          <TextValue>{isSupportPackage ? "Có" : "Không"}</TextValue>
        </Col>
      )}

      <Col span={8}>
        <TextLabel>Thời gian tạo: </TextLabel>
        <TextValue>
          {moment(creationDate + "z").format("DD/MM/YYYY HH:mm")}
        </TextValue>
      </Col>

      <Col span={8}>
        <TextLabel>Thời gian sử dụng ngân sách: </TextLabel>
        <TextValue>
          {moment(dataDetail?.startDate + "").format("DD/MM/YYYY")}
        </TextValue>
        <TextValue>
          {" "}
          - {moment(dataDetail?.endDate + "").format("DD/MM/YYYY")}
        </TextValue>
        <Tooltip
          overlayClassName="!min-w-[270px]"
          title={
            <>
              <div>
                Hạn chót báo cáo:{" "}
                {moment(endReportDate).format("DD/MM/YYYY HH:mm")}
              </div>
              <div>
                Hạn chót phê duyệt:{" "}
                {moment(endApprovalDate).format("DD/MM/YYYY HH:mm")}
              </div>
            </>
          }
        >
          <QuestionCircleOutlined className="ml-2" />
        </Tooltip>
      </Col>

      <Col span={8}>
        <TextLabel>Thời gian yêu cầu thực tại: </TextLabel>
        <TextValue>
          {moment(calAmountExpenseRequest?.startDate + "").format("DD/MM/YYYY")}
        </TextValue>
        <TextValue>
          {" "}
          - {moment(calAmountExpenseRequest?.endDate + "").format("DD/MM/YYYY")}
        </TextValue>
        <Tooltip
          overlayClassName="!min-w-[270px]"
          title={
            <div>
              <Text className={"text-white"}>
                Đây là thời gian thực tại tổng hợp của tất cả các phiếu bao gồm
                ngày của phiếu cha và tổng hợp kết thúc là ngày cuối cùng của
                những phiếu con
              </Text>
            </div>
          }
        >
          <QuestionCircleOutlined className="ml-2" />
        </Tooltip>
      </Col>

      <Col span={8}>
        <TextLabel>Ghi chú: </TextLabel>
        <TextValue>{note || "--"}</TextValue>
      </Col>
    </Row>
  );
};

export default ExpenseInfo;
