import React from "react";
import { Form, Input } from "antd";

const PageNumberForm = () => {
  return (
    <Form.Item
      label="Số page"
      name="numOfPage"
      rules={[
        {
          required: true,
          message: "Vui lòng điền số page",
        },
      ]}
    >
      <Input type="number" allowClear placeholder="Nhập số page" />
    </Form.Item>
  );
};

export default PageNumberForm;
