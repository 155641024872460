import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { getDetailExpenseApi, processingUserExpense } from "@/services/base";
import { EXPENSE_TYPE_CODES, PROCESS_STATUS } from "@/utils/const";
import { Button, Collapse, Dropdown, Flex, message, Spin, Modal } from "antd";
import { ArrowLeftOutlined, ArrowsAltOutlined, DownOutlined, ShrinkOutlined } from "@ant-design/icons";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { TbMoneybag } from "react-icons/tb";
import { CiBank } from "react-icons/ci";
import { HiOutlineUserGroup } from "react-icons/hi";
import { IoIosAttach } from "react-icons/io";
import { MdAutoDelete, MdHistory } from "react-icons/md";
import ApproveModalForm from "@/components/expenseDetail/ApproveModalForm";
import { getSteps } from "@/utils/fetchData";
import GroupButtonProcess from "@/components/expenseDetail/GroupButtonProcess";
import ProgressBar from "@/common/ProgressBar";
import TagStatus from "@/components/listRequset/TagStatus";
import {
  AttachFiles,
  BankInfo,
  ExpenseInfo,
  HistoryProcess,
  PartnerInfo,
  ProposeCategory,
  ReportExpense
} from "@/components/expenseDetail";
import RejectReason from "@/common/RejectReason";
import HistoryDeleteProcess from "../expenseDetail/HistoryDeleteProcess";

const CloseRequestDetail = () => {
  const nav = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [closeRequestTicket, setCloseRequestTicket] = useState();
  const navigate = useNavigate();
  const [steps, setSteps] = useState([]);
  const [approveModal, setApproveModal] = useState({
    isOpen: false,
  });
  const { processStatus, expenseType } = closeRequestTicket || {};
  const { statusCode } = processStatus || {};
  const [activeKeys, setActiveKeys] = useState(['1', '2', '3', '4']);

  const fetchCloseRequestTicket = async (id) => {
    setLoading(true);
    try {
      const res = await getDetailExpenseApi(id, EXPENSE_TYPE_CODES.RequestClose);
      if (
        (res.code === 400 && res.error === -4) ||
        (res.code === 404 && res.error === -5)
      )
        return nav("/404");
      if (res.code !== 200 && res.error !== 0) return message.error(res?.error);
      setCloseRequestTicket(res.data);
      await getSteps(res?.data?.processTypeId, setSteps);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCloseRequestTicket(id);
  }, [id]);

  const confirmProcessingExpense = () => {
    Modal.confirm({
      title: "Xác nhận",
      content: "Bạn có chắc chắn chắn muốn tiếp nhận xử lý phiếu này?",
      cancelText: "Hủy",
      onOk: async () => await handleProcessingUserExpense(),

      cancelButtonProps: {
        type: "primary",
        danger: true,
      },
    });
  }

  const handleProcessingUserExpense = async () => {
    try {
      const res = await processingUserExpense(closeRequestTicket?.id);
      if (res.isError) {
        message.error(res.message);
        return;
      }

      message.success(res?.message);
      fetchCloseRequestTicket(id);
    } catch (error) {
      message.error("Error: " + error);
    }
  };

  const dropdownItems = [
    {
      key: "handleProcess",
      label: <a href onClick={confirmProcessingExpense}>Tiếp nhận xử lý</a>,
      disabled: [PROCESS_STATUS.done, PROCESS_STATUS.close, PROCESS_STATUS.reject].includes(statusCode),
    },
  ];

  const items = [
    {
      key: '1',
      label: <div className="m-0 p-0 flex ">
        <span className="font-semibold text-xl flex items-center"><IoIosInformationCircleOutline /><span className="ml-2">{expenseType?.name}</span></span>
        <TagStatus className="text-[1rem] ml-3 flex items-center" record={closeRequestTicket} />
        <RejectReason dataDetail={closeRequestTicket} />
      </div>,
      children: <ExpenseInfo dataDetail={closeRequestTicket} />,
    },
    {
      key: '2',
      label: <span className="font-semibold text-xl flex items-center"><IoIosAttach /><span className="ml-2">Tệp đính kèm</span></span>,
      children: <AttachFiles dataDetail={closeRequestTicket} handleReload={() => fetchCloseRequestTicket(id)} />,
    },
    {
      key: '3',
      label: <span className="font-semibold text-xl flex items-center"><TbMoneybag /><span className="ml-2">Báo cáo ngân sách chi tiêu</span></span>,
      children: <ProposeCategory dataDetail={closeRequestTicket} />,
    },
    {
      key: '4',
      label: <span className="font-semibold text-xl flex items-center"><CiBank /><span className="ml-2">Ngân hàng</span></span>,
      children: <BankInfo dataDetail={closeRequestTicket} />,
    },
    {
      key: '5',
      label: <span className="font-semibold text-xl flex items-center"><HiOutlineUserGroup /><span className="ml-2">Đối tác</span></span>,
      children: <PartnerInfo partner={closeRequestTicket?.partner} />,
      show: closeRequestTicket?.partner ? "1" : "0"
    },
    {
      key: '6',
      label: <span className="font-semibold text-xl flex items-center"><MdHistory /><span className="ml-2">Lịch sử</span></span>,
      children: <HistoryProcess approvalHistory={closeRequestTicket?.approvalHistory} />,
    },
    {
      key: '8',
      label: <span className="font-semibold text-xl flex items-center"><MdAutoDelete /><span className="ml-2">Lịch sử xóa</span></span>,
      children: <HistoryDeleteProcess />,
    },
  ];

  const panels = items?.map(item => item.key)
  const handleExpandAll = () => setActiveKeys(panels);
  const handleCollapseAll = () => setActiveKeys([]);
  const visiableItems = items?.filter(e => e?.show !== "0");

  return (
    <>
      {loading ? (
        <div className="w-full min-h-[80vh] flex items-center justify-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Flex className="flex-col min-h-[80vh]">
            <ProgressBar dataDetail={closeRequestTicket} steps={steps}/>
            <section className='mt-6 relative flex-grow'>
              <Flex justify="space-between" className="mb-3">
                <Flex gap={16}>
                  <Button className="px-3" onClick={handleExpandAll}><ArrowsAltOutlined className="text-[18px]"/>Mở tất cả</Button>
                  <Button className="px-3" onClick={handleCollapseAll}><ShrinkOutlined className="text-[18px]"/>Đóng tất
                    cả</Button>
                </Flex>
                <Dropdown menu={{ items: dropdownItems }} trigger={['click']}>
                  <Button type="primary"
                          className="bg-gradient-primary hover:!bg-gradient-secondary active:!bg-gradient-secondary border-l-0">
                    Chức năng
                    <DownOutlined/>
                  </Button>
                </Dropdown>
              </Flex>
              <Collapse activeKey={activeKeys} items={visiableItems} onChange={setActiveKeys}/>
            </section>
            <Flex justify="space-between" className="w-full">
              <Button
                type="primary"
                className="px-4 mt-4"
                onClick={() => navigate(-1)}
              >
                <ArrowLeftOutlined/>
                Trở về
              </Button>
              <GroupButtonProcess
                dataDetail={closeRequestTicket}
                id={id}
                steps={steps}
                loading={loading}
                setApproveModal={setApproveModal}
                getExpenseDetail={fetchCloseRequestTicket}
                setLoading={setLoading}
              />
            </Flex>
          </Flex>
          <ApproveModalForm
            approveModal={approveModal}
            handleCancel={() => setApproveModal({ isOpen: false })}
            dataDetail={closeRequestTicket}
            handleReload={() => fetchCloseRequestTicket(id)}
          />
        </>
      )}
    </>
  );
};

export default CloseRequestDetail;
