import React from 'react';
import {Button, Dropdown, Flex, message, Tag} from "antd";
import {formatCurrencyVND} from "@/utils/common";
import {deleteNotification, viewNotification} from "@/services/base";
import {getFormattedTimeDescription} from "@/utils/utils";
import {
    BankOutlined,
    RiseOutlined,
    FallOutlined,
    SnippetsOutlined,
    FileDoneOutlined
} from "@ant-design/icons";
import {TbUrgent} from "react-icons/tb";

const NotifitcationItem = ({item, dataUser, refreshNotification, isViewAll}) => {
    const totalAmount = formatCurrencyVND(item?.expenseRequest?.totalAmount || 0);
    const groupSite = item?.expenseRequest?.group?.groupName;

    const getTitle = () => {
        const expenseTypeName = item?.expenseRequest?.expenseType?.name;
        const stepName = item?.expenseRequest?.processStep?.stepName
        const expenseCode = item?.expenseRequest?.code;
        const expenseInfo = `${expenseCode}: ${stepName}`;
        if (item.objectType === "Expense") {
            return item?.expenseRequest?.userCreate?.userName === dataUser.userName
                ? (
                    <>
                        <span><BankOutlined/></span>
                        {` ${expenseInfo} thành công`}
                    </>
                )
                : (
                    <>
                        <span><BankOutlined/></span>
                        {` ${expenseInfo}`}
                    </>
                );
        }



        const titles = {
            "ExpenseDailyReport": "Yêu cầu báo cáo hiệu quả chiến dịch",
            "ExpenseReportNvlWeb": expenseInfo,
            // Expense
            "ExpenseInprogress": `${expenseInfo}`,
            "ExpenseAproved": `${expenseInfo}`,
            "ExpenseDone": `${expenseInfo}`,
            "ExpensePending": `${expenseInfo} `,
            "ExpenseReject": `${expenseInfo} `,
            "ExpenseClose": `${expenseInfo} `,
            // Expense Increase
            "ExpenseIncrease": `${expenseInfo}`,
            "ExpenseIncreaseAproved": `${expenseInfo}`,
            "ExpenseIncreaseReject": `${expenseInfo}`,
            "ExpenseIncreasePending": `${expenseInfo}`,
            "ExpenseIncreaseInprogress": `${expenseInfo}`,
            "ExpenseIncreaseDone": `${expenseInfo}`,
            "ExpenseIncreaseClose": `${expenseInfo}`,
            // "ExpenseIncreaseEndCamp":`${expenseInfo}`,
            // ExpenseDecrease
            "ExpenseDecrease": `${expenseInfo}`,
            "ExpenseDecreaseAproved": `${expenseInfo}`,
            "ExpenseDecreaseReject": `${expenseInfo}`,
            "ExpenseDecreasePending": `${expenseInfo}`,
            "ExpenseDecreaseInprogress": `${expenseInfo}`,
            "ExpenseDecreaseDone": `${expenseInfo}`,
            "ExpenseDecreaseClose": `${expenseInfo}`,
            // Report
            "ExpenseReport": `${expenseInfo}`,
            "ExpenseReportAproved": `${expenseInfo}`,
            "ExpenseReportReject": `${expenseInfo}`,
            "ExpenseReportPending": `${expenseInfo}`,
            "ExpenseReportInprogress": `${expenseInfo}`,
            "ExpenseReportDone": `${expenseInfo}`,
            "ExpenseReportClose": `${expenseInfo}`,
            // Close
            "ExpenseCloseAproved": `${expenseInfo}`,
            "ExpenseCloseReject": `${expenseInfo}`,
            "ExpenseClosePending": `${expenseInfo}`,
            "ExpenseCloseInprogress": `${expenseInfo}`,
            "ExpenseCloseDone": `${expenseInfo}`,
            "ExpenseCloseClose": `${expenseInfo}`,
        };

        const iconMapping = {
            "ExpenseDailyReport": <SnippetsOutlined/>,
            "ExpenseAproved": <BankOutlined/>,
            "ExpensePending": <BankOutlined/>,
            "ExpenseReject": <BankOutlined/>,
            "ExpenseClose": <FileDoneOutlined/>,
            "ExpenseIncrease": <RiseOutlined/>,
            "ExpenseDecrease": <FallOutlined/>,
            "ExpenseReportPending": <SnippetsOutlined/>,
            "ExpenseReport": <SnippetsOutlined/>,
            "ExpenseReportAproved": <SnippetsOutlined/>,
            "ExpenseReportReject": <SnippetsOutlined/>,
            "ExpenseCloseAproved": <FileDoneOutlined/>,
            "ExpenseClosePending": <FileDoneOutlined/>,
            "ExpenseCloseReject": <FileDoneOutlined/>,
            "ExpenseIncreaseAproved": <RiseOutlined/>,
            "ExpenseIncreaseReject": <RiseOutlined/>,
        };
        const title = titles[item.objectType] ?? "Thông báo";
        const icon = iconMapping[item.objectType] || <BankOutlined/>;
        return (
            <>
                <span>{icon}</span>
                {` ${title}`}
            </>
        );
    };

    const getAdType = () => {
        return item?.expenseRequest?.purpose?.purposeName ? (
            <p className="p-0 m-0">
                Loại hình quảng cáo: {item?.expenseRequest?.purpose?.purposeName}
            </p>
        ) : null;
    };

    const getPiority = () => {
        return (
            item?.isTopNotification && <TbUrgent className="text-2xl"/>
        );
    };

    const getTextColor = (item) => {
        if (item?.isViewed) {
            return "text-[#999]";
        } else if (item?.isTopNotification) {
            return "text-red-500 hover:text-red-500";
        }

        return "text-primary"
    }

    const getStatus = () => {
        if (item?.expenseRequest?.processStatus) {
            const statusName = item?.expenseRequest?.processStatus?.statusName;
            const statusColor = {
                "Từ chối": "red",
                "Đã phê duyệt": "green",
                "Đang xử lý": "blue",
                "Pending": "orange",
            }[statusName] || "orange";

            return (
                <p className="p-0 m-0">
                    <span>Trạng thái: </span>
                    <Tag color={statusColor}>{statusName}</Tag>
                </p>
            );
        }
        return null;
    };

    const handleViewDetails = async (item) => {
        try {
            const params = {
                userId: dataUser.id,
                notificationId: item.notifyId,
            };
            await viewNotification(params);
            refreshNotification();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteNotification = async (notificationId) => {
        try {
            const params = {
                userId: dataUser.id,
                notificationId,
            };
            const res = await deleteNotification(params);
            if (res.code === 200 && res.error === 0) {
                refreshNotification();
                message.success("Xóa thông báo thành công!");
            }
        } catch (error) {
            console.error(error);
            message.error("Xóa thông báo thất bại!");
        }
    };

    const markViewedNotification = async (notificationId) => {
        try {
            const params = {userId: dataUser.id, notificationId};
            const res = await viewNotification(params);
            if (res.code === 200) {
                refreshNotification();
                message.success("Đánh dấu đã xem thành công!");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleClickDropdown = (e, notificationId) => {
        if (e.key === '1') {
            markViewedNotification(notificationId)
        } else {
            handleDeleteNotification(notificationId)
        }
    }

    const generateLink = (item) => {
        const {objectType, expenseRequest} = item;
        const {id} = expenseRequest;

        const paths = {
            "ExpenseDailyReport": `/expense-request`,
            "ExpenseClose": `/close-request/${id}`,
            "ExpenseCloseAproved": `/close-request/${id}`,
            "ExpenseClosePending": `/close-request/${id}`,
            "ExpenseCloseReject": `/close-request/${id}`,
            "ExpenseReport": `/daily-report/${id}`,
            "ExpenseReportAproved": `/daily-report/${id}`,
            "ExpenseReportPending": `/daily-report/${id}`,
            "ExpenseReportReject": `/daily-report/${id}`,
            "ExpenseIncrease": `/increase-request/${id}`,
            "ExpenseIncreaseAproved": `/increase-request/${id}`,
            "ExpenseIncreasePending": `/increase-request/${id}`,
            "ExpenseIncreaseReject": `/increase-request/${id}`,
            "ExpenseDecrease": `/decrease-request/${id}`,
        };

        const defaultPath = `/expense-request/${id}`;
        return paths[objectType] || defaultPath;
    };

    return (
        <div className={`${item?.isTopNotification ? "bg-red-100" : ""} px-2 pb-2 py-2 mr-2 mb-1 rounded-md`}>
            <div className="flex justify-between">
                <div className={`${item?.isViewed ? "text-[#999]" : ""} w-full`}>
                    <a
                        href={generateLink(item)}
                        className={`${getTextColor(item)} flex font-semibold gap-x-2 w-full items-start p-0 m-0 cursor-pointer hover:opacity-70`}
                        onClick={() => handleViewDetails(item)}
                    >
                        {getTitle()}
                        {getPiority()}
                    </a>
                    <p className="p-0 m-0">
                        Hậu đài: {groupSite}
                    </p>
                    {getAdType()}
                    {getStatus()}
                </div>
                <Flex>
                    <Dropdown
                        placement="bottomRight"
                        trigger={['click']}
                        menu={{
                            items: [
                                {
                                    label: 'Đánh dấu là đã xem',
                                    key: '1',
                                    disabled: item?.isViewed
                                },
                                {
                                    label: 'Xóa thông báo',
                                    key: '2',
                                },
                            ],
                            onClick: (e) => {
                                handleClickDropdown(e, item.notifyId)
                            },
                        }}
                    >
                        <Button className="-mt-2" type="text" shape="circle">
                            <span className="font-bold text-[#555]">...</span>
                        </Button>
                    </Dropdown>
                </Flex>
            </div>
            <p className="p-0 pr-[10px] m-0 text-gray-400 text-[0.7rem] text-end">{getFormattedTimeDescription(item?.modifiedDate)}</p>
        </div>
    );
};

export default NotifitcationItem;