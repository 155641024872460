import DataTable from "@/common/DataTable";
import { Button, Divider, Dropdown, message, Modal, Popconfirm, Typography, Upload, } from "antd";
import {
  DownloadOutlined,
  DownOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CountData from "@/common/CountData";
import { getListGroups } from "@/utils/fetchData";
import GroupsFilterForm from "@/components/groups/GroupsFilterForm";
import GroupFormAction from "@/components/groups/GroupFormAction";
import { deleteGroup, exportFileGroup, getListGroup, importFileGroup, getGroupInfor } from "@/services/base";
import { StatusResponse } from "@/utils/const";
import GroupActionButton from "@/common/GroupActionButton";
import { downloadExcelFile } from "@/utils/utils";

const initStateModal = {
  isOpenModal: false,
  isCreate: false,
  titleModal: "",
  buttonOkModal: "",
  editData: {},
};

const initParams = {
  textSearch: "",
  pageIndex: 1,
  pageSize: 20,
};

const GroupPage = () => {
  document.title = "Quản lý nhóm hậu đài";

  const [listGroup, setListGroup] = useState([]);
  const [listGroupInfor, setListGroupInfor] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [stateModal, setStateModal] = useState(initStateModal);
  const [params, setParams] = useState(initParams);
  const [paginationGroup, setPaginationGroup] = useState({
    pageIndex: 1,
    pageSize: 10,
    total: 0,
    pages: 1,
  });
  const [exportParams, setExportParams] = useState({
    siteId: null,
    departmentId: null,
    areaId: null,
    teamId: null,
  });

  const getGroups = async (params) => {
    setLoading(true);
    try {
      const res = await getListGroup(params);
      if (res.code !== StatusResponse.SUCCESS) {
        message.error(res.message);
        return;
      }

      const data = res.data?.map((item) => ({ ...item, key: item.id })) || [];
      setListGroup(data);
      setPaginationGroup((prev) => ({
        ...prev,
        total: res.pagination?.total,
      }));
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchGroupInfor = async () => {
    try {
      const res = await getGroupInfor();
      if (res.code!== StatusResponse.SUCCESS) {
        message.error(res.message);
        return;
      }

      setListGroupInfor(res?.data)
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchGroupInfor();
  }, [])

  useEffect(() => {
    getGroups(params);
  }, [params]);

  const columns = [
    {
      title: "Tên nhóm",
      dataIndex: "groupName",
      key: "groupName",
      align: "center",
      render: (value) => (value ? value : "--"),
    },
    {
      title: "Thông tin nhóm",
      dataIndex: "groupInfo",
      key: "groupInfo",
      align: "center",
      render: (value) => (value ? value?.groupInfoName : "--"),
    },
    {
      title: "Trang",
      dataIndex: "site",
      key: "site",
      align: "center",
      render: (value) => (value ? value.name : "--"),
    },
    {
      title: "Bộ phận",
      dataIndex: "department",
      key: "department",
      align: "center",
      render: (value) => (value ? value.departmentName : "--"),
    },
    {
      title: "Tổ",
      dataIndex: "team",
      key: "team",
      align: "center",
      render: (value) => (value ? value.name : "--"),
    },
    {
      title: "Khu",
      dataIndex: "area",
      key: "area",
      align: "center",
      render: (value) => (value ? value.name : "--"),
    },
    {
      title: "Thời gian tạo",
      dataIndex: "creationDate",
      key: "creationDate",
      align: "center",
      render: (value) => moment(value + "z").format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Hành động",
      key: "action",
      align: "center",
      render: (_, record) => (
        <GroupActionButton
          record={record}
          showModalDelete={showModalConfirmDelete}
          updateFn={updateGroup}
        />
      ),
    },
  ];

  const onDeleteTeam = async (id) => {
    setLoading(true);
    try {
      const res = await deleteGroup(id);
      if (res.code === StatusResponse.SUCCESS) {
        message.success("Xóa nhóm hậu đài thành công");
        reloadPage();
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  const showModalConfirmDelete = (record) => {
    Modal.confirm({
      title: "Bạn có chắc chắn muốn xóa?",
      icon: <ExclamationCircleFilled />,
      content: "Khi xóa sẽ không thể hoàn tác!",
      okText: "Xóa",
      okType: "primary",

      cancelText: "Hủy",
      onOk() {
        onDeleteTeam(record);
      },
      onCancel() {},
      cancelButtonProps: {
        type: "primary",
        danger: true,
      },
    });
  };

  const handlechangeSearch = (data) => {
    setExportParams({
      departmentId: data.departmentId,
      siteId: data.siteId,
      areaId: data.areaId,
      teamId: data.teamId,
    });
    setParams({
      ...data,
      pageIndex: 1,
      pageSize: 10,
    });
  };

  const createGroup = () => {
    setStateModal({
      isOpenModal: true,
      isCreate: true,
      titleModal: "Thêm nhóm hậu đài mới",
      buttonOkModal: "Thêm",
      editData: null,
    });
  };

  const updateGroup = (data) => {
    setStateModal({
      isOpenModal: true,
      isCreate: false,
      titleModal: "Chỉnh sửa hậu đài mới",
      buttonOkModal: "Chỉnh sửa",
      editData: data,
    });
  };

  const closeModal = () => {
    setStateModal(initStateModal);
  };

  const reloadPage = () => {
    getListGroups(initParams, setListGroup, setPaginationGroup);
    setParams(initParams);
    setSelectedGroups([]);
  };

  const handleOnchangePage = (pageIndex, pageSize) => {
    setParams((prev) => ({ ...prev, pageIndex, pageSize }));
  };

  const deleteMultipleAreas = async () => {
    setLoading(true);
    try {
      const results = await Promise.all(
        selectedGroups.map((group) =>
          deleteGroup(group).catch((err) => {
            console.error(`Failed to delete group ${group}:`, err);
            return { isError: true, message: err.message };
          })
        )
      );

      const deleteFails = results
        .filter((res) => res.isError)
        .map((res) => res.message);

      if (deleteFails.length > 0) {
        deleteFails.forEach((item) => message.error(item));
      } else {
        message.success("Thành công");
      }

      reloadPage();
    } catch (err) {
      console.error("Error in deleteMultipleAreas:", err);
    } finally {
      setLoading(false);
    }
  };

  const handlePopconfirm = {
    confirm: deleteMultipleAreas,
    cancle: () => {},
  };

  const onChangeFile = async (file) => {
    try {
      Modal.confirm({
        title: "Xác nhận",
        content: <p className="-ml-6">Xác nhận import dữ liệu</p>,
        okText: "Tiếp tục",
        cancelText: "Hủy",
        onOk: async () => {
          const res = await importFileGroup({
            file: file.file.originFileObj,
            fileType: file.file.type,
          });
          if (res?.code === 200 && res?.error === 0) {
            message.success("Import file thành công!");
            downloadExcelFile(res.data, `Dữ liệu Nhóm hậu đài đã imported`);
            getGroups(params);
          } else {
            message.error("Import file thất bại!");
          }
        },
        cancelButtonProps: {
          type: "primary",
          danger: true,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportFile = async () => {
    try {
      Modal.confirm({
        title: "Xác nhận",
        content: <p className="-ml-6">Tải file dữ liệu về!</p>,
        okText: "Tải về",
        cancelText: "Hủy",
        onOk: async () => {
          const data = {
            textSearch: params.textSearch,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            siteId: exportParams.siteId,
            areaId: exportParams.areaId,
            departmentId: exportParams.departmentId,
            teamId: exportParams.teamId,
          };
          const response = await exportFileGroup(data);
          if (response.code === 200 && response.error === 0) {
            const { data } = response;
            downloadExcelFile(data, "Quản lý nhóm hậu đài");
            message.success("Export file thành công!");
          } else {
            message.error("Export file thất bại!");
            return;
          }
        },
        cancelButtonProps: {
          type: "primary",
          danger: true,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <div onClick={createGroup}>
          <PlusOutlined className="mr-2 text-[1rem]" />
          Thêm nhóm hậu đài
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <Upload
          accept=".xlsx, .xls"
          onChange={onChangeFile}
          fileList={[]}
          style={{ width: "100%", height: "100%" }}
        >
          <UploadOutlined className="mr-2 text-[1.2rem]" />
          Import file
        </Upload>
      ),
    },
    {
      key: "3",
      label: (
        <div onClick={handleExportFile}>
          <DownloadOutlined className="mr-2 text-[1.2rem]" />
          Export file
        </div>
      ),
    },
  ];

  return (
    <>
      <section className="flex items-end justify-between mb-4">
        <GroupsFilterForm
          onchangeSearch={handlechangeSearch}
          loading={loading}
        />
        <div className="flex gap-3">
          {selectedGroups.length > 0 && (
            <Popconfirm
              title="Xóa nhóm hậu đài"
              placement="topLeft"
              description="Bạn có thật sự muốn xóa những nhóm hậu đài đã chọn, điều này không thể hoàn tác?"
              onConfirm={handlePopconfirm.confirm}
              onCancel={handlePopconfirm.cancle}
              okText="Xác nhận"
              cancelText="Hủy"
              cancelButtonProps={{
                type: "primary",
                danger: true,
              }}
            >
              <Typography.Text
                className="bg-red-500 hover:bg-red-600 text-white flex items-center px-2 rounded-lg cursor-pointer"
                size="middle"
              >
                Xóa {selectedGroups.length} nhóm hậu đài đã chọn
              </Typography.Text>
            </Popconfirm>
          )}

          <Dropdown
            menu={{
              items,
            }}
          >
            <Button type="primary" onClick={(e) => e.preventDefault()}>
              Chức năng
              <DownOutlined />
            </Button>
          </Dropdown>

          {/* <div className="flex gap-4">
            <Upload
              accept=".xlsx, .xls"
              // onChange={onChangeFile}
              fileList={[]}
            >
              <Button>
                <UploadOutlined />
                Import file
              </Button>
            </Upload>
            <Button
            // onClick={handleExportFile}
            >
              <DownloadOutlined />
              Export file
            </Button>
          </div> */}
        </div>
      </section>
      <CountData
        loading={loading}
        total={paginationGroup.total}
        text="nhóm hậu đài"
      />
      <Divider />
      <DataTable
        columns={columns}
        dataSource={listGroup}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={paginationGroup.total}
        selectedRowKeys={selectedGroups}
        setSelectedRowKeys={setSelectedGroups}
        onPageChange={handleOnchangePage}
        loading={loading}
        scroll={{ x: "max-content", y: 540 }}
      />
      <GroupFormAction
        listGroupInfor={listGroupInfor}
        stateModal={stateModal}
        closeModal={closeModal}
        reloadPage={reloadPage}
      />
    </>
  );
};

export default GroupPage;
