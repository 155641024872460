import React from 'react';
import { DatePicker, Form } from "antd";

const RangePickerForm = () => {
  return (
    <Form.Item name="date" className="!m-0">
      <DatePicker.RangePicker
        placeholder={["Ngày Bắt Đầu", "Ngày Kết Thúc"]}
        format={"DD/MM/YYYY"}
        allowClear
        size="middle"
        className="!w-[280px]"
      />
    </Form.Item>
  );
};

export default RangePickerForm;