import React from 'react';
import { Form, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const SearchForm = ({
  name,
  overridePlaceholder = "Tìm kiếm",
  overrideWidth = 200,
  isSearchIcon = false,
}) => {
  return (
    <Form.Item name={name} className="!m-0">
      <Input
        size="middle"
        type="search"
        allowClear
        placeholder={overridePlaceholder}
        style={{ width: `${overrideWidth}px` }}
        addonBefore={isSearchIcon ? <SearchOutlined/> : null}
      />
    </Form.Item>
  );
};

export default SearchForm;