import React, { useState } from 'react';
import { PROCESS_STATUS, EXPENSE_TYPE_CODES } from "@/utils/const";
import { Button, message, Space, Modal } from "antd";
import { disableButtonsProcess, showModalReject, addDays } from "@/utils/utils";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import ModalPending from "@/common/ModalPending";
import { handlePendingExpense, endCamp } from "@/services/base";

const GroupButtonProcess = ({
  dataDetail,
  setLoading,
  setApproveModal,
  loading,
  id,
  steps,
  getExpenseDetail
}) => {  
  const infoState = useRecoilValue(infoUser);
  const [visible, setVisible] = useState(false);
  const expenseTypeCode = dataDetail?.expenseType?.code;
  const isDailyReport = expenseTypeCode == EXPENSE_TYPE_CODES.SpendingHistory;

  const handlePending = async (value) => {
    try {
      setLoading(true);
      const res = await handlePendingExpense({ expenseRequestId: id, stepIdBack: value.step, processNote: value.note });
      if (res.isError) {
        message.error(res.message);
      } else {
        message.success("Đã chuyển trạng thái sang chờ giải quyết");
        getExpenseDetail(id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setVisible(false);
    }
  };

  const handleEndCamp = async () => {
    try {
      const res = await endCamp({
        expenseRequestId: id
      });

      if (res.isError) {
        message.error(res.message);
      } else {
        message.success(res?.message);
        getExpenseDetail(id);
      }
    } catch (error) {
      message.error("Error: ", error);
    }
  }

  const confirmEndCamp = () => {
    Modal.confirm({
      title: "Xác nhận",
      content: `Yêu cầu tắt camp sẽ chuyển phiếu này về cho ads xử lý. Bạn chắc chắn muốn yêu cầu tắt camp?`,
      cancelText: "Hủy",
      onOk: async () => await handleEndCamp(),

      cancelButtonProps: {
        type: "primary",
        danger: true,
      },
    });
  }

  const processStatus = dataDetail?.processStatus?.statusCode;
  const isProcessDisabled = disableButtonsProcess(infoState, dataDetail);
  const filteredSteps = (steps || []).filter(
    step => step?.stepPriority <= dataDetail?.processStep?.stepPriority
  );

  const currentDate = new Date();
  const endApprovalDate = addDays(dataDetail?.startDate, 7);
  const allowedApprovalDate = currentDate <= endApprovalDate;
  const disableWithDate = ["Proposal", "IncreaseAdj"].includes(dataDetail?.expenseType?.code) && !allowedApprovalDate;

  return (
    <div>
      {![PROCESS_STATUS.reject, PROCESS_STATUS.done].includes(processStatus) && (
        <Space className="w-full mt-4 justify-end">
           {isDailyReport && (
            <Button
              type="primary"
              className="bg-[#78909c] hover:!bg-[#546e7a] hover:opacity-[0.8]"
              loading={loading}
              // disabled={isProcessDisabled || disableWithDate}
              onClick={() => confirmEndCamp()}
            >
              Yêu cầu tắt camp
            </Button>
          )}
          {/*{filteredSteps && filteredSteps.length > 0 && ![PROCESS_STATUS.pending].includes(processStatus) && (*/}
            <Button
              type="primary"
              className="custom-yellow-button"
              loading={loading}
              // disabled={isProcessDisabled || disableWithDate}
              onClick={() => setVisible(true)}
            >
              Đợi giải quyết
            </Button>
          {/*)}*/}
          <Button
            onClick={() =>
              setApproveModal({
                isOpen: true,
              })
            }
            type="primary"
            disabled={isProcessDisabled || disableWithDate}
            loading={loading}
          >
            Phê duyệt
          </Button>
          <Button
            loading={loading}
            // disabled={isProcessDisabled}
            onClick={() => showModalReject(id, setLoading, getExpenseDetail)}
            type="primary"
            danger
          >
            Từ chối
          </Button>
        </Space>
      )}
      <ModalPending
        visible={visible}
        steps={filteredSteps}
        onOk={handlePending}
        onCancel={() => setVisible(false)}
      />
    </div>
  );
};

export default GroupButtonProcess;