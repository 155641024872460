import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDetailExpenseApi, processingUserExpense, rollbackDailyReport } from "@/services/base";
import { EXPENSE_TYPE_CODES, PROCESS_STATUS } from "@/utils/const";
import { Button, Collapse, Dropdown, Flex, Form, Input, message, Modal, Spin, Typography } from "antd";
import { ArrowLeftOutlined, ArrowsAltOutlined, DownOutlined, ShrinkOutlined, StopOutlined } from "@ant-design/icons";
import { TbReport } from "react-icons/tb";
import { HiOutlineUserGroup } from "react-icons/hi";
import { MdAutoDelete, MdHistory } from "react-icons/md";
import { IoIosAttach } from "react-icons/io";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { TbMoneybag } from "react-icons/tb";
import { useNavigate } from "react-router";
import ApproveModalForm from "@/components/expenseDetail/ApproveModalForm";
import { getSteps } from "@/utils/fetchData";
import GroupButtonProcess from "@/components/expenseDetail/GroupButtonProcess";
import ProgressBar from "@/common/ProgressBar";
import { isAdmin, isTLB, isTLQC } from "@/utils/utils";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import TagStatus from "@/components/listRequset/TagStatus";
import {
  AttachFiles,
  BankInfo,
  ExpenseInfo,
  HistoryProcess,
  PartnerInfo,
  ProposeCategory,
  ReportExpense
} from "@/components/expenseDetail";
import RejectReason from "@/common/RejectReason";
import HistoryDeleteProcess from "@/components/expenseDetail/HistoryDeleteProcess";

const HistorySpendingDetail = () => {
  document.title ="Phiếu báo cáo hằng ngày";
  const infoState = useRecoilValue(infoUser);
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [historyDetail, setHistoryDetail] = useState();
  const [steps, setSteps] = useState([]);
  const [approveModal, setApproveModal] = useState({
    isOpen: false,
  });
  const nav = useNavigate();
  const [activeKeys, setActiveKeys] = useState(['1', '2', '3', '5']);
  const { processStatus, expenseType } = historyDetail || {};
  const { statusCode } = processStatus || {};

  const fetchHistorySpending = async (id) => {
    setLoading(true);
    try {
      const res = await getDetailExpenseApi(id, EXPENSE_TYPE_CODES.SpendingHistory);
      if (res.code === 403) return nav("/forbidden");
      if (
        (res.code === 400 && res.error === -4) ||
        (res.code === 404 && res.error === -5)
      )
        return nav("/404");
      if (res.code !== 200 && res.error !== 0)
        return message.error(res?.message);
      setHistoryDetail(res.data);
      getSteps(res.data.processTypeId, setSteps);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHistorySpending(id);
  }, [id]);

  const confirmProcessingExpense = () => {
    Modal.confirm({
      title: "Xác nhận",
      content: "Bạn có chắc chắn chắn muốn tiếp nhận xử lý phiếu này?",
      cancelText: "Hủy",
      onOk: async () => await handleProcessingUserExpense(),

      cancelButtonProps: {
        type: "primary",
        danger: true,
      },
    });
  };

  const handleProcessingUserExpense = async () => {
    try {
      const res = await processingUserExpense(historyDetail?.id);
      if (res.isError) {
        message.error(res.message);
        return;
      }

      message.success(res?.message);
      fetchHistorySpending(id);
    } catch (error) {
      message.error("Error: " + error);
    }
  };

  const handleRollback = async () => {
    Modal.confirm({
      title: "Bạn có chắc chắn muốn hoàn tác về bước trước khi phê duyệt báo cáo?",
      content: (
        <Form form={form}>
          <Form.Item
            name="processNote"
            rules={[
              { required: true, message: "Vui lòng điền lý do hoàn tác!" },
            ]}
          >
            <Input.TextArea placeholder="Điền lý do hoàn tác..." rows={2} />
          </Form.Item>
        </Form>
      ),
      okText: "Xác nhận",
      okType: "primary",
      cancelText: "Hủy",
      onOk: handleOk,
      okButtonProps: {
        loading: loading,
        className: "custom-yellow-button",
      },
      cancelButtonProps: {
        type: "primary",
        danger: true,
      },
    });
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);

      const data = {
        expenseRequestId: historyDetail?.id,
        processNote: values.processNote,
      };

      const res = await rollbackDailyReport(data);
      if (res.isError) {
        message.error(res.message);
        return;
      }

      message.success(res?.message);
      fetchHistorySpending(id);
    } catch (error) {
      if (error.errorFields) {
        console.log("Validation Error:", error.errorFields);
        message.error("Vui lòng điền lý do hoàn tác!");
      } else {
        message.error("Error: " + error);
      }
    } finally {
      setLoading(false);
    }
  };

  const dropdownItems = [
    {
      key: "handleProcess",
      label: <a href onClick={confirmProcessingExpense}>Tiếp nhận xử lý</a>,
      disabled: [PROCESS_STATUS.done, PROCESS_STATUS.close, PROCESS_STATUS.reject].includes(statusCode),
    },
    {
      key: "rollback",
      label: <a href onClick={handleRollback}>Hoàn tác</a>,
      disabled: !(statusCode === PROCESS_STATUS.done && (isAdmin(infoState) || isTLQC(infoState) || isTLB(infoState)))
    },
  ];

  const items = [
    {
      key: '1',
      label: <div className="m-0 p-0 flex ">
        <span className="font-semibold text-xl flex items-center"><IoIosInformationCircleOutline /><span className="ml-2">Phiếu chi ngân sách</span></span>
        <TagStatus className="text-[1rem] ml-3 flex items-center" record={historyDetail} />
        <RejectReason dataDetail={historyDetail} />
      </div>,
      children: <ExpenseInfo dataDetail={historyDetail} />,
    },
    {
      key: '2',
      label: <span className="font-semibold text-xl flex items-center"><IoIosAttach /><span className="ml-2">Tệp đính kèm</span></span>,
      children: <AttachFiles dataDetail={historyDetail} handleReload={() => fetchHistorySpending(id)} />,
    },
    {
      key: '3',
      label: <span className="font-semibold text-xl flex items-center"><TbMoneybag /><span className="ml-2">Báo cáo ngân sách chi tiêu</span></span>,
      children: <ProposeCategory dataDetail={historyDetail} />,
    },
    {
      key: '4',
      label: <span className="font-semibold text-xl flex items-center"><HiOutlineUserGroup /><span className="ml-2">Đối tác</span></span>,
      children: <PartnerInfo partner={historyDetail?.partner} />,
      show: historyDetail?.partner ? "1" : "0"
    },
    {
      key: '5',
      label: <span className="font-semibold text-xl flex items-center"><TbReport /><span className="ml-2">Báo cáo hiệu quả chiến dịch</span></span>,
      children: <ReportExpense messInfor={historyDetail?.messInfor} />,
    },
    {
      key: '6',
      label: <span className="font-semibold text-xl flex items-center"><MdHistory /><span className="ml-2">Lịch sử</span></span>,
      children: <HistoryProcess approvalHistory={historyDetail?.approvalHistory} />,
    },
    {
      key: '8',
      label: <span className="font-semibold text-xl flex items-center"><MdAutoDelete /><span className="ml-2">Lịch sử xóa</span></span>,
      children: <HistoryDeleteProcess />,
    },
  ];

  const panels = items?.map(item => item.key)
  const handleExpandAll = () => setActiveKeys(panels);
  const handleCollapseAll = () => setActiveKeys([]);
  const visiableItems = items?.filter(e => e?.show !== "0");

  return (
    <>
      {loading ? (
        <div className="w-full min-h-[80vh] flex items-center justify-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Flex className="flex-col min-h-[80vh]">
            <ProgressBar dataDetail={historyDetail} steps={steps} />
            <section className='mt-6 relative flex-grow'>
              <Flex justify="space-between" className="mb-3">
                <Flex gap={16}>
                  <Button className="px-3" onClick={handleExpandAll}><ArrowsAltOutlined className="text-[18px]"/>Mở tất cả</Button>
                  <Button className="px-3" onClick={handleCollapseAll}><ShrinkOutlined className="text-[18px]"/>Đóng tất
                    cả</Button>
                </Flex>
                <Dropdown menu={{ items: dropdownItems }} trigger={['click']}>
                  <Button type="primary"
                          className="bg-gradient-primary hover:!bg-gradient-secondary active:!bg-gradient-secondary border-l-0">
                    Chức năng
                    <DownOutlined/>
                  </Button>
                </Dropdown>
              </Flex>
              <Collapse activeKey={activeKeys} items={visiableItems} onChange={setActiveKeys}/>
            </section>
            <Flex justify="space-between" className="w-full">
              <Button
                type="primary"
                className="px-4 mt-4"
                onClick={() => navigate(-1)}
              >
                <ArrowLeftOutlined />
                Trở về
              </Button>
              <GroupButtonProcess
                dataDetail={historyDetail}
                id={id}
                steps={steps}
                loading={loading}
                setApproveModal={setApproveModal}
                getExpenseDetail={fetchHistorySpending}
                setLoading={setLoading}
              />
            </Flex>
          </Flex>
          <ApproveModalForm
            approveModal={approveModal}
            handleCancel={() => setApproveModal({ isOpen: false })}
            dataDetail={historyDetail}
            handleReload={() => fetchHistorySpending(id)}
          />
        </>
      )}
    </>
  );
};

export default HistorySpendingDetail;
