import DataTable from "@/common/DataTable";
import { exportFileFigure, getFigureList, getTargetConfigList, importFileFigure, } from "@/services/base";
import { formatCurrencyVND } from "@/utils/common";
import { Button, Divider, Flex, message, Modal, Space, Tag, Tooltip, Typography, Upload } from "antd";
import { DownloadOutlined, QuestionCircleOutlined, UploadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { DEPARTMENT__CODE, ROLES, SITE_SUMMARY_STATUS } from "@/utils/const";
import CountData from "@/common/CountData";
import FormSearch from "@/components/figure/FormSearch";
import "./styles.css";
import { downloadExcelFile } from "@/utils/utils";
import UpdateFigureForm from "@/components/figure/UpdateFigureForm";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";

const Figure = () => {
  document.title = "Quản lý số liệu cược";
  const userInfor = useRecoilValue(infoUser);
  const { role, department } = userInfor;
  const isAdmin = role?.roleCode === ROLES.ADMIN;
  const isTLHD = department?.departmentCode === DEPARTMENT__CODE.TLHD;

  const [listFigures, setListFigures] = useState([]);
  const [listTargetConfig, setListTargetConfig] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [stateModal, setStateModal] = useState({
    isOpen: false,
    data: null,
  });
  const isAllowedPermission = isAdmin || isTLHD;

  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [searchParams, setSearchParam] = useState({
    textSearch: "",
    pageIndex: 1,
    pageSize: 10,
  });
  const [initialValues, setInitialValues] = useState({});

  const sumTotal = (nsqc, nsnvl) => {
    return formatCurrencyVND(nsqc + nsnvl);
  };

  const caculateAvailableAmount = (maxAmount, inUseAmount) => {
    return formatCurrencyVND(maxAmount - inUseAmount);
  };

  const caculateAvailableTotalAmount = (record) => {
    const totalNsqc = record?.totalNsqc ?? 0;
    const totalNvl = record?.totalNsqc ?? 0;
    const totalExpenseQc = record?.totalExpenseQc ?? 0;
    const totalExpenseNvl = record?.totalExpenseNvl?? 0;
    
    return formatCurrencyVND((totalNsqc - totalExpenseQc) + (totalNvl - totalExpenseNvl));
  };

  const Information = ({ text, amount}) => (
    <div>
      <p className="p-0 m-0">{text}: <strong>{amount}</strong></p>
    </div>
  );

  const columns = [
    {
      title: "Hậu đài",
      dataIndex: "groupName",
      key: "groupName",
      align: 'center',
      fixed: 'left'
    },
    {
      title: <span>Số thành viên <Tooltip title="Số thành viên chạy chung"><QuestionCircleOutlined /></Tooltip></span>,
      dataIndex: "teamSize",
      key: "teamSize",
      width: 130,
      align: 'center',
      render: (value) => (value ? value : "--"),
    },
    {
      title: "Loại hậu đài",
      dataIndex: "groupInfoCode",
      key: "groupInfoCode",
      width: 100,
      align: 'center',
      render: (value) => (value ? value : "--"),
    },
    {
      title: "NS xét duyệt/người",
      dataIndex: "nsqc",
      key: "nsqc",
      children: [
        {
          title: 'NSQC',
          dataIndex: "nsqc",
          key: "nsqc",
          align: 'center',
          width: 130,
          render: (value, record) => {
            return (
              <Flex justify="center">
                <span className="truncate">
                  {formatCurrencyVND(value ?? 0)}
                </span>
                <Tooltip title={<Information text="NSQC xét duyệt tối đa" amount={formatCurrencyVND(record?.totalNsqc ?? 0)} />}>
                  <InfoCircleOutlined className="ml-2 text-primary" />
                </Tooltip>
              </Flex>
            );
          },
        },
        {
          title: "NSNVL",
          dataIndex: "nsnvl",
          key: "nsnvl",
          align: 'center',
          width: 130,
          render: (value, record) => {
            return (
              <Flex justify="center">
                <span className="truncate">
                  {formatCurrencyVND(value ?? 0)}
                </span>
                <Tooltip title={<Information text="NSNVL xét duyệt tối đa" amount={formatCurrencyVND(record?.totalNvl ?? 0)} />}>
                  <InfoCircleOutlined className="ml-2 text-primary" />
                </Tooltip>
              </Flex>
            );
          },
        },
        {
          title: "Tổng tiền",
          dataIndex: "total",
          key: "total",
          align: 'center',
          width: 130,
          render: (value, record) => {
            return (
              <Flex justify="center">
                <span className="truncate">
                  {formatCurrencyVND(value ?? 0)}
                </span>
                <Tooltip title={<Information text="Tổng tiền xét duyệt tối đa" amount={sumTotal(record?.totalNsqc ?? 0, record?.totalNvl ?? 0)} />}>
                  <InfoCircleOutlined className="ml-2 text-primary" />
                </Tooltip>
              </Flex>
            );
          },
        },
      ]
    },
    {
      title: "NS đã ứng",
      dataIndex: "nsqc",
      key: "nsqc",
      children: [
        {
          title: 'NSQC',
          dataIndex: "totalExpenseQc",
          key: "totalExpenseQc",
          align: 'center',
          width: 130,
          render: (value) => formatCurrencyVND(value ?? 0),
        },
        {
          title: "NSNVL",
          dataIndex: "totalExpenseNvl",
          key: "totalExpenseNvl",
          align: 'center',
          width: 130,
          render: (value) => formatCurrencyVND(value ?? 0),
        },
        {
          title: "Tổng tiền",
          dataIndex: "total",
          key: "total",
          align: 'center',
          width: 130,
          render: (_, record) => sumTotal(record?.totalExpenseQc ?? 0, record?.totalExpenseNvl ?? 0),
        },
      ]
    },
    // {
    //   title: "NS có thể ứng",
    //   dataIndex: "nsqc",
    //   key: "nsqc",
    //   children: [
    //     {
    //       title: 'NSQC',
    //       dataIndex: "nsqc",
    //       key: "nsqc",
    //       align: 'center',
    //       width: 130,
    //       render: (_, record) => caculateAvailableAmount(record?.totalNsqc ?? 0, record?.totalExpenseQc ?? 0),
    //     },
    //     {
    //       title: "NSNVL",
    //       dataIndex: "nsnvl",
    //       key: "nsnvl",
    //       align: 'center',
    //       width: 130,
    //       render: (_, record) => caculateAvailableAmount(record?.totalNvl ?? 0, record?.totalExpenseNvl ?? 0),
    //     },
    //     {
    //       title: "Tổng tiền",
    //       dataIndex: "total",
    //       key: "total",
    //       align: 'center',
    //       width: 130,
    //       render: (_, record) => caculateAvailableTotalAmount(record),
    //     },
    //   ]
    // },
    // {
    //   title: "NS quảng cáo",
    //   dataIndex: "nsqc",
    //   key: "nsqc",
    //   width: 120,
    //   render: (value) => (value ? formatCurrencyVND(value) : "--"),
    // },
    // {
    //   title: "Tổng ngân sách",
    //   dataIndex: "totalExpense",
    //   key: "totalExpense",
    //   width: 130,
    //   render: (value) => (value ? formatCurrencyVND(value) : "--"),
    // },
    // {
    //   title: <span>Bình quân <Tooltip title="Bình quân ngân sách/ người"><QuestionCircleOutlined /></Tooltip></span>,
    //   dataIndex: "budgetAverage",
    //   key: "budgetAverage",
    //   width: 120,
    //   render: (value) => (value ? formatCurrencyVND(value) : "--"),
    // },
    {
      title: "NS xét duyệt có thể ứng",
      dataIndex: "budgetAvailable",
      key: "budgetAvailable",
      align: 'center',
      width: 120,
      render: (value) => formatCurrencyVND(value ?? 0),
    },
    {
      title: "NSNVL có thể ứng ",
      dataIndex: "budgetAvailableNvl",
      key: "budgetAvailableNvl",
      align: 'center',
      width: 120,
      render: (value) => formatCurrencyVND(value ?? 0),
    },
    {
      title: "Bình quân NS/người",
      dataIndex: "budgetAverage",
      key: "budgetAverage",
      align: 'center',
      width: 120,
      render: (value) => formatCurrencyVND(value ?? 0),
    },
    {
      title: "Điểm KPI",
      dataIndex: "pointKpiGroupSummary",
      key: "pointKpiGroupSummary",
      align: 'center',
      width: 80,
      render: (value) => (value ? value : "--"),
    },
    {
      title: <span>KPI mục tiêu <Tooltip title="Điểm KPI cần đạt của hậu đài quảng cáo"><QuestionCircleOutlined /></Tooltip></span>,
      dataIndex: "pointKpiNeeded",
      key: "pointKpiNeeded",
      align: 'center',
      width: 120,
      render: (value) => (value ? value : "--"),
    },
    {
      title: "Vòng cược",
      dataIndex: "pointRoundGroupSummary",
      key: "pointRoundGroupSummary",
      align: 'center',
      width: 90,
      render: (value) => (value ? value : "--"),
    },
    {
      title: "Tổng nạp/NS",
      dataIndex: "divDeposit",
      key: "divDeposit",
      align: 'center',
      width: 120,
      render: (value) => (value ? value : "--"),
    },
    {
      title: "Tổng nạp",
      dataIndex: "totalAmountGroupSummary",
      key: "totalAmountGroupSummary",
      width: 120,
      render: (value) => formatCurrencyVND(value ?? 0),
    },
    {
      title: "NS đã chuyển trong ngày",
      dataIndex: "totalBankingDaily",
      key: "totalBankingDaily",
      align: 'center',
      width: 130,
      render: (value) => formatCurrencyVND(value ?? 0)
    },
    {
      title: "Thời gian",
      dataIndex: "creationDate",
      key: "creationDate",
      align: 'center',
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
    {
      title: "Trạng thái xét duyệt",
      dataIndex: "statusCheck",
      key: "statusCheck",
      align: 'center',
      fixed: 'right',
      render: (value) => {
        return (
          <Tag className="m-0" color={SITE_SUMMARY_STATUS[value]?.color}>
            {SITE_SUMMARY_STATUS[value]?.text}
          </Tag>
        );
      },
    },
  ];

  useEffect(() => {
    getFigures(searchParams);
  }, [searchParams]);

  useEffect(() => {
    getTargetConfig();
  }, []);

  const getFigures = async (params) => {
    try {
      setLoadingTable(true);
      const res = await getFigureList(params);
      if (res.code === 200 && res.error === 0) {
        setListFigures(res?.data);
        setTotalPage(res?.pagination?.total);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingTable(false);
    }
  };

  const getTargetConfig = async (params) => {
    try {
      setLoading(true);
      const res = await getTargetConfigList(params);
      if (res.code === 200 && res.error === 0) {        
        const formattedCriteria = formatCurrencyVND(res?.data[0]?.criteria);
        const data = res?.data?.map((item) => {
          const isCurrency = ["NSQCOL1", "NSQCOL", "NSNVL", "NSNVL6", "NSQC"].includes(item?.code);

          return {
            ...item,
            criteria: isCurrency ? formatCurrencyVND(item?.criteria || 0) : item?.criteria,
          };
        });
        setListTargetConfig(data);
        setInitialValues({
          [res.data[0].code]: res.data[0].criteria,
          [res.data[1].code]: res.data[1].criteria,
          [res.data[2].code]: res.data[2].criteria,
          [res.data[3].code]: res.data[3].criteria,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSearch = useCallback(
    (searchFields) => {
      const newParams = { ...searchParams, ...searchFields, pageIndex: 1 };
      setSearchParam(newParams);
    },
    [searchParams]
  );

  const onchangePage = (pageIndex, pageSize) => {
    setSearchParam((prevParams) => ({
      ...prevParams,
      pageIndex,
      pageSize,
    }));
  };

  const onChangeFile = async (file) => {
    try {
      Modal.confirm({
        title: "Xác nhận",
        content: (
          <p className="-ml-6">
            Bạn có chắc chắn muốn import file không? Dữ liệu cũ sẽ bị thay thế thành dữ liệu mới!
          </p>
        ),
        okText: "Vẫn tiếp tục",
        cancelText: "Hủy",
        onOk: async () => {
          const res = await importFileFigure({
            file: file.file.originFileObj,
            fileType: file.file.type,
          });
          if (res?.code === 200 && res?.error === 0) {
            message.success("Import file thành công!");
            getFigures(searchParams);
          } else {
            message.error("Import file thất bại!");
          }
        },
        cancelButtonProps: {
          type: "primary",
          danger: true,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportFile = async () => {
    try {
      Modal.confirm({
        title: "Xác nhận",
        content: <p className="-ml-6">Tải file dữ liệu về!</p>,
        okText: "Tải về",
        cancelText: "Hủy",
        onOk: async () => {
          const response = await exportFileFigure();
          if (response.code === 200 && response.error === 0) {
            const { data } = response;
            downloadExcelFile(data, "Quản lý số liệu cược");
            message.success("Export file thành công!");
          } else {
            message.error("Export file thất bại!");
          }
        },
        cancelButtonProps: {
          type: "primary",
          danger: true,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="flex justify-between mb-0">
        <div className="flex flex-col gap-4 justify-between ">
          <FormSearch onchangeSearch={handleChangeSearch} />
          <CountData loading={loading} total={totalPage} text="hậu đài" />
        </div>

        <div className="flex gap-4">
          {isAllowedPermission && (
            <>
              <Upload accept=".xlsx, .xls" onChange={onChangeFile} fileList={[]}>
                <Button>
                  <UploadOutlined />
                  Import file
                </Button>
              </Upload>
              <Button onClick={handleExportFile}>
                <DownloadOutlined />
                Export file
              </Button>
              <Button
                type="primary"
                className="bg-gradient-primary hover:!bg-gradient-secondary border-0"
                onClick={() => setStateModal({ isOpen: true, data: initialValues })}
              >
                Cập nhật tiêu chí
              </Button>
            </>
          )}
        </div>
      </section>
      <Flex className="justify-around py-4" wrap gap={2}>
        {listTargetConfig.map((item, index) => (
          <span key={index} className="min-w-[350px] bg-orange-400/80 flex justify-center py-[6px] rounded-xl">
            <Space split={<Divider type="vertical" />} align="center">
              <Typography.Text className="font-semibold text-[#fff]">
                {item?.name}
              </Typography.Text>
              <Typography.Text className="font-bold text-[#fff]">
                {item?.criteria}
              </Typography.Text>
            </Space>
          </span>
        ))}
      </Flex>
      <DataTable
        columns={columns}
        dataSource={listFigures.map((u) => ({ ...u, key: u.id })) || []}
        currentPage={searchParams.pageIndex}
        pageSize={searchParams.pageSize}
        totalPage={+totalPage}
        loading={loadingTable}
        scroll={{ x: "max-content", y: 470 }}
        isBorder
        onPageChange={onchangePage}
      />
      <UpdateFigureForm
        stateModal={stateModal}
        closeModal={() => {
          setStateModal({ isOpen: false, data: null });
        }}
        reload={() => getFigures()}
        getTargetConfig={getTargetConfig}
      />
    </>
  );
};

export default Figure;
