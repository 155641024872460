import React, { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Space, Typography } from "antd";
import {
  UserOutlined,
  LockOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";

import { useNavigate } from "react-router";
import { LocalStorageKey, routes } from "@/utils/const";
import { useRecoilState } from "recoil";
import { infoUser, signalRState } from "@/recoil/atoms";
import ModalChangePass from "@/components/users/ModalChangePass";
import Notify from "@/components/notify/Notify";
import { deleteSubscriptionNotification } from "@/services/base";
import MenuDrawer from "@/components/header/MenuDrawer";

const HeaderMain = ({ collapsed }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const [userInfor, setUserInfo] = useRecoilState(infoUser);
  const [visible, setVisible] = useState(false);

  const [connectionSignalR, setConnectionSignalR] =
    useRecoilState(signalRState);

  const onClose = () => {
    setVisible(false);
  };

  const handleLogoutClick = async () => {
    const endPointPusNotification = localStorage.getItem(
      LocalStorageKey.END_POINT_PUSH_NOTI
    );

    if (endPointPusNotification) {
      await deleteSubscriptionNotification({
        userId: userInfor?.id,
        endpoint: endPointPusNotification,
      });
    }

    localStorage.clear();
    setUserInfo(null);
    navigate(routes.login);
    const disconnect = connectionSignalR?.disconnect();
    setConnectionSignalR(disconnect);
  }

  const items = [
    {
      key: "1",
      label: (
        <div className="text-[15px] px-2 rounded">
          <UserOutlined className="mr-2"/>
          Chi tiết tài khoản
        </div>
      ),
      onClick: () => {
        navigate(routes.accountInfo);
      },
    },
    {
      key: "2",
      label: (
        <div className="text-[15px] px-2 rounded">
          <LockOutlined className="mr-2"/> Mật khẩu
        </div>
      ),
      onClick: () => setVisible(true),
    },
    {
      key: "3",
      label: (
        <div className="text-[15px] px-2 rounded">
          <PoweroffOutlined className="mr-2"/> Đăng xuất
        </div>
      ),
      onClick: () => handleLogoutClick()
    },
  ];

  return (
    <>
      <div
        className={`sticky top-0 mx-5 xl:mr-12 px-2 md:h-[70px] h-[56px] z-50  py-[5px] flex items-center justify-between text-[#525666] shadow-md bg-white 
        ${!collapsed ? "lg:ml-[260px] xl:ml-[290px]  " : "lg:ml-[100px] xl:ml-[130px] lg:mr-5 "} `}
        style={{
          ...(!collapsed
            ? {
              transition: "margin 0.2s",
            }
            : {
              transition: "margin 0.2s",
            }),
        }}
      >
        <div>
          <MenuDrawer />
        </div>
        <p className={"hidden xl:block text-2xl font-semibold text-center"}>QUẢN LÝ ĐỀ XUẤT</p>
        <div className="md:pr-5 flex items-center gap-8 pr-6">
          <Notify/>

          <Dropdown
            className="cursor-pointer"
            onOpenChange={setIsHovered}
            menu={{
              items,
            }}
            placement="bottomLeft"
          >
            <div className="flex items-center gap-2">
              <Avatar
                size={40}
                icon={<UserOutlined/>}
                src={userInfor?.avataUrl}
                style={{
                  background:
                    "linear-gradient(282deg, #00B0FF 5.54%, #3E98EB 100%)",
                }}
              />

              <div className="flex flex-col">
                <div
                  className={`flex items-center  ${
                    isHovered ? "text-blue-500" : ""
                  }`}
                >
                  <span className="pr-1 text-[16px] font-semibold max-w-[80px] truncate">
                    {userInfor?.fullName}
                  </span>
                  <Space>
                    {isHovered ? (
                      <UpOutlined style={{ fontSize: "10px" }}/>
                    ) : (
                      <DownOutlined style={{ fontSize: "10px" }}/>
                    )}
                  </Space>
                </div>
                <div className="text-[12px] font-light max-w-[80px] truncate">
                  {userInfor?.role?.roleName}
                </div>
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
      <ModalChangePass visible={visible} handleCancel={onClose}/>
    </>
  );
};

export default HeaderMain;
