import { atom } from "recoil";
import {
  selectorDepartments, selectorDepartType,
  selectorProducts,
  selectorPurposes, selectorRoles,
  selectorSite,
  selectorUserInfo
} from "@/recoil/selectors";

export const infoUser = atom({
  key: "infoUser",
  default: selectorUserInfo,
});

export const paginationGlobal = atom({
  key: "paginationGlobal",
  default: { pageIndex: 0, pageSize: 10 },
});

export const isModal = atom({
  key: "isModal",
  default: false,
});

export const signalRState = atom({
  key: "signalRState",
  default: null,
  dangerouslyAllowMutability: true,
});


export const paramsSuperivorState = atom({
  key: "paramsSuperivorState",
  default: {
    pageIndex: 1,
    pageSize: 99,
    total: 0,
    textSearch: "",
    groupId: null,
  },
})

export const allSites = atom({
  key: "allSite",
  default: selectorSite,
});

export const allDepartments = atom({
  key: "allDepartments",
  default: selectorDepartments,
});

export const allPurposes = atom({
  key: "allPurposes",
  default: selectorPurposes,
});

export const allProducts = atom({
  key: "allProducts",
  default: selectorProducts,
});

export const allRoles = atom({
  key: "allRoles",
  default: selectorRoles,
});

export const allDepartType = atom({
  key: "allDepartType",
  default: selectorDepartType,
});