import React, { useEffect, useState } from "react";
import { Empty, Flex, message, Popconfirm, Spin, Tabs } from "antd";
import ProcessingMain from "@/components/processingStep/ProcessingMain";
import { deleteProcessType, getAllProcessType, getListExpenseType, getListPurpose } from "@/services/base";
import { StatusResponse } from "@/utils/const";
import FormCreateProcessType from "@/components/processingStep/FormCreateProcessType";
import FormCopyProcessType from "@/components/processingStep/FormCopyProcessType";
import { CloseOutlined, CopyOutlined, EditOutlined } from "@ant-design/icons";
import qs from "query-string";

const ProcessingSteps = () => {
  document.title = "Quản lý quy trình xử lý";
  const [loading, setLoading] = useState(false);
  const [listExpenseType, setListExpenseType] = useState([]);
  const [listPurpose, setListPurpose] = useState([]);
  const [listProcessType, setListProcessType] = useState([]);

  const [modalState, setModalState] = useState({
    title: "",
    isOpen: false,
    id: null,
    okText: "",
    data: {},
  });
  const [modalCopyState, setModalCopyState] = useState({
    isOpen: false,
    data: {},
  });
  const [expenseTypeId, setExpenseTypeId] = useState(0);
  const [processTypeId, setProcessTypeId] = useState(0);
  const [purposeId, setPurposeId] = useState(null);


  const fetchProcessType = async () => {
    setLoading(true);    

    const body = qs.stringify({
      expenseTypeId: expenseTypeId,
      purposeId: purposeId
    });

    const res = await getAllProcessType(body);
    if (res.code !== StatusResponse.SUCCESS) return message.error(res.message);
    
    setListProcessType(res.data);
    setProcessTypeId(res.data[0]?.id);
    setLoading(false);
  };

  const fetchPurposeList = async () => {
    const res = await getListPurpose();
    if (res.code !== StatusResponse.SUCCESS) return message.error(res.message);
    setListPurpose(res?.data);
    setPurposeId(res.data[0]?.id);
  };

  useEffect(() => {
    fetchPurposeList();
  }, [])

  useEffect(() => {
    if (!expenseTypeId || !purposeId) {
      return;
    }

    fetchProcessType();
  }, [expenseTypeId, purposeId]);

  useEffect(() => {
    const fetchProcessTypeList = async () => {
      const res = await getListExpenseType({});
      if (res.code === StatusResponse.SUCCESS) {
        setListExpenseType(res.data);
        setExpenseTypeId(res.data[0].id);
      } else {
        message.error(res.message);
      }
    };

    fetchProcessTypeList();
  }, []);

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      setModalState({
        title: "Tạo quy trình",
        isOpen: true,
        okText: "Tạo mới",
      });
    }
  };
  const handleDeleteExpen = async (processTypeId) => {
    try {
      const res = await deleteProcessType(processTypeId);
      if (res.code !== StatusResponse.SUCCESS)
        return message.error(res.message);
      message.success(res.message);
      fetchProcessType();
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleOnChangeSubTab = (id) => {
    setProcessTypeId(id);
  };

  const data = listExpenseType?.map((expense) => {
    return {
      key: expense.id,
      label: expense.name,
      children: loading ? (
        <div className="w-full min-h-[360px] flex items-center justify-center ">
          <Spin size="large" />
        </div>
      ) : (
        <Tabs
          destroyInactiveTabPane={true}
          size="small"
          type="editable-card"
          onEdit={onEdit}
          items={listProcessType?.map((processType) => {
            return {
              key: processType.id,
              label: (
                <div className="flex">
                  {processType.name}
                  <div className="flex gap-3">
                    <Flex>
                      <a
                        href
                        className="ml-2"
                        onClick={() =>
                          setModalCopyState({
                            isOpen: true,
                            data: processType,
                          })
                        }
                      >
                        <CopyOutlined />
                      </a>
                      <a
                        href
                        className="ml-2"
                        onClick={() =>
                          setModalState({
                            isOpen: true,
                            title: "Chỉnh sửa quy trình",
                            id: processType?.id,
                            okText: "Chỉnh sửa",
                            data: processType,
                          })
                        }
                      >
                        <EditOutlined />
                      </a>
                    </Flex>
                    <Popconfirm
                      title="XÓA QUY TRÌNH"
                      description={`Bạn có thật sự muốn xóa quy trình ${processType?.name}, Khi xóa không thể hoàn tác !`}
                      okText="Xác nhận"
                      onConfirm={() => {
                        handleDeleteExpen(processType?.id);
                      }}
                      cancelText="Hủy"
                      cancelButtonProps={{
                        type: 'primary',
                        danger: true
                      }}
                    >
                      <span className="text-gray-500">
                        <CloseOutlined />
                      </span>
                    </Popconfirm>
                  </div>
                </div>
              ),
              children: (
                <ProcessingMain
                  processTypeId={processTypeId}
                  purposeId={purposeId}
                />
              ),
              closable: false,
            };
          })}
          onChange={handleOnChangeSubTab}
        />
      ),
    };
  });

  const handleOnChange = (id) => {
    setExpenseTypeId(id);
  };

  const handleOnChangePurpose = (id) => {
    setPurposeId(id);
    setExpenseTypeId(listExpenseType[0].id);
  }

  const getCodeById = (id) => {
    const item = listExpenseType.find(item => item.id === id);
    return item ? item.code : null;
  };

  const expenseCode = getCodeById(expenseTypeId);

  return (
    <>
      <Tabs
        destroyInactiveTabPane={true}
        size="small"
        type="card"
        tabPosition="left"
        items={listPurpose?.map((item) => {
          return {
            label: item?.purposeName,
            key: item?.id,
            children: (
              <Tabs
                destroyInactiveTabPane={true}
                size="small"
                type="card"
                items={data}
                onChange={handleOnChange}
              />
            ),
          };
        })}
        onChange={handleOnChangePurpose}
      />
      {listProcessType.length === 0 && <Empty className="mt-24" />}
      <FormCreateProcessType
        purposeId={purposeId}
        expenseTypeId={expenseTypeId}
        modalState={modalState}
        expenseCode={expenseCode}
        handleClose={() => setModalState({ isOpen: false })}
        reload={() => fetchProcessType(expenseTypeId)}
      />
      <FormCopyProcessType
        purposeId={purposeId}
        processTypeId={processTypeId}
        modalState={modalCopyState}
        listExpenseType={listExpenseType}
        listPurpose={listPurpose}
        handleClose={() => setModalCopyState({ isOpen: false })}
        reload={() => fetchProcessType(expenseTypeId)}
      />
    </>
  );
};

export default ProcessingSteps;
