import React, { useEffect } from "react";
import { Button, Checkbox, Col, Divider, Form, Input, message, Modal, Row, Select, Space, Switch, } from "antd";
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { createProcessingStep, updateProcessingStep, } from "@/services/base";
import { ROLE_LEVER, StatusResponse } from "@/utils/const";
import { useRecoilValue } from "recoil";
import { allDepartments, allRoles } from "@/recoil/atoms";

const ProcessingStepFormAction = ({
  stateModal,
  setStateModal,
  closeModal,
  actionProcess,
  dataProcess,
  processTypeId,
}) => {
  const allRole = useRecoilValue(allRoles);
  const departments = useRecoilValue(allDepartments);
  const {
    isOpenModal,
    isCreate,
    titleModal,
    buttonOkModal,
    editData,
    loading,
  } = stateModal;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      processTypeId,
      ...(editData ? editData : {}),
    });

    return () => {
      form.resetFields();
    };
  }, [isOpenModal, editData, form]);

  const onFinish = async (value) => {
    const data = {
      ...value,
      nextStepId: value.nextStepId !== null ? Number(value.nextStepId) : null,
      listUserRoleId: value.listUserRoleId || null,
      listDepartmentRoleId:
        value.listDepartmentRoleId?.filter(
          (item) => item.departmentId && item.listUserRoleId
        ) || null,
    };
    setStateModal((prev) => ({ ...prev, loading: true }));
    try {
      const res = isCreate
        ? await createProcessingStep(data)
        : await updateProcessingStep({ id: editData.id, ...data });

      if (res.code === StatusResponse.SUCCESS) {
        message.success(
          isCreate
            ? "Tạo mới quy trình thành công"
            : "Cập nhật quy trình thành công"
        );
        actionProcess("", isCreate ? "create" : "update");
        closeModal();
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setStateModal((prev) => ({ ...prev, loading: false }));
    }
  };

  return (
    <Modal
      title={titleModal}
      open={isOpenModal}
      width="600px"
      onCancel={closeModal}
      footer={null}
      style={{ top: 50 }}
    >
      <Divider />
      <div className="modal-body px-2">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item hidden name="processTypeId">
            <Input hidden />
          </Form.Item>
          <Form.Item hidden name="departmentId">
            <Input hidden />
          </Form.Item>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item
                label="Bộ phận xử lý"
                name="departmentId"
                rules={[
                  { required: true, message: "Vui lòng chọn bộ phận xử lý!" },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Chọn bộ phận xử lý"
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().includes(input.toLowerCase())
                  }
                  options={(departments || []).map((department) => ({
                    value: department.id,
                    label: department.departmentName,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Bộ phận phụ trách camp"
                name="supervisorDepartmentId"
                rules={[
                  {
                    required: false,
                    message: "Vui lòng chọn bộ phận phụ trách camp!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Chọn bộ phận phụ trách camp"
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().includes(input.toLowerCase())
                  }
                  options={(departments || []).map((department) => ({
                    value: department.id,
                    label: department.departmentName,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item
                label="Tên quy trình"
                name="stepName"
                rules={[
                  {
                    required: true,
                    message: "Tên quy trình không được bỏ trống!",
                  },
                ]}
              >
                <Input placeholder="Nhập tên quy trình..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Cấp độ vai trò được sử dụng"
                name="roleLevelMin"
                rules={[
                  {
                    required: true,
                    message: "Cấp độ vai trò được sử dụng không bỏ trống!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Chọn vai trò"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {allRole
                    .filter((rol) => rol?.roleLevel !== ROLE_LEVER.ADMIN)
                    .map((role) => {
                      return (
                        <Select.Option key={role.id} value={role.roleLevel}>
                          {role.roleName}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item label="Bước tiếp theo" name="nextStepId">
                <Select
                  allowClear
                  showSearch
                  placeholder="Chọn bước tiếp theo"
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      .includes(input?.toLowerCase())
                  }
                >
                  {dataProcess.map((pro) => (
                    <Select.Option key={pro?.id} value={pro?.id}>
                      {pro.stepName}
                    </Select.Option>
                  ))}
                  <Select.Option key="stepEnd" value={null}>
                    Bước cuối cùng
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Số thứ tự quy trình"
                name="stepPriority"
                rules={[
                  {
                    required: true,
                    message: "Số thứ tự quy trình không được bỏ trống!",
                  },
                ]}
              >
                <Input
                  min={0}
                  type="number"
                  placeholder="Nhập mã quy trình..."
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item
                label="Xác nhận bước gửi ảnh/video"
                name="isUploadBill"
                tooltip={{
                  title: (
                    <div>
                      Gửi ảnh bill này có thể bật khi xác nhận bước này để cho
                      trợ lý gửi ảnh bill lên
                    </div>
                  ),
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Xác nhận bước gửi voice"
                name="isUploadVoice"
                tooltip={{
                  title: (
                    <div>
                      Gửi ảnh voice này có thể bật khi xác nhận bước này để cho
                      trợ lý gửi ảnh voice lên
                    </div>
                  ),
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item
                label="Nhập tiền đề xuất"
                name="isAmount"
                tooltip={{
                  title: <div>Nhập tiền đề xuất</div>,
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Báo cáo số lượng mess"
                name="isMessInfor"
                tooltip={{
                  title: <div>Báo cáo số lượng mess đạt được</div>,
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item
                label="Nhập thông tin ngân hàng"
                name="isBankInfor"
                tooltip={{
                  title: <div>Nhập thông tin ngân hàng</div>,
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Yêu cầu lead bộ phận phụ trách camp"
                name="isSupervisorDepartmentLead"
                tooltip={{
                  title: (
                    <div>
                      Chọn người phụ trách sẽ hiển thị leader hoặc cấp quản lý
                      của bộ phận phụ trách
                    </div>
                  ),
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item
                label="Nhập số page"
                name="isNumOfPage"
                tooltip={{
                  title: <div>Nhập số page đối với quy trình NVL</div>,
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Nhập mô tả"
                name="isNote"
                tooltip={{
                  title: (
                    <div>
                      Nhập mô tả của phiếu
                    </div>
                  ),
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item
                label="Xác nhận báo cáo cuối cùng"
                name="isLastReport"
                tooltip={{
                  title: <div>Xác nhận báo cáo cuối cùng của ngân sách</div>,
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Phê duyệt đặc biệt"
                name="isSpecialApprove"
                tooltip={{
                  title: (
                    <div>
                      Phê duyệt ngân sách không phụ thuộc vào tiêu chí của hậu
                      đài
                    </div>
                  ),
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item
                label="Xác nhận là bước bắt đầu"
                name="isFirstStep"
                tooltip={{
                  title:
                    "Xác nhận đây là bước bắt đầu trong quy trình xử lý ngân sách!",
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Xác nhận là bước kết thúc"
                name="isLastStep"
                tooltip={{
                  title:
                    "Xác nhận đây là bước kết thúc trong quy trình xử lý ngân sách!",
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item
                label="Xử lý vượt ngân sách"
                name="isHandleOverExpense"
                tooltip={{
                  title:
                    "Điều hướng trợ lý đến ứng thêm ngân sách nhằm mục đích xử lý ngân sách vượt hạn mức cho phép!",
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Thông tin đối tác"
                name="isPartnerInfor"
                tooltip={{
                  title:
                    "Cho phép nhập thêm thông tin đối tác.",
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item
                label="Thông báo ưu tiên"
                name="isTopNotification"
                tooltip={{
                  title:
                    "Nếu cấu hình được chọn thì thông báo của bước này sẽ được hiển thị lên đầu tiên của danh sách thông báo.",
                  icon: <QuestionCircleOutlined style={{ fontSize: 20 }} />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="listUserRoleId"
            style={{
              display: "flex",
              width: "100%",
              marginBottom: 25,
              alignItems: "end",
            }}
          >
            <Checkbox.Group style={{ width: "100%" }}>
              <Row gutter={[24, 16]}>
                <Col>
                  <Checkbox value={1}>Gửi thông báo đến Admin</Checkbox>
                </Col>
                <Col>
                  <Checkbox value={5}>Gửi thông báo đến Phó chủ quản</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>

          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.List name="listDepartmentRoleId">
                {(fields, { add, remove }) => (
                  <>
                    {fields?.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          width: "100%",
                          alignItems: "center",
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          label="Chọn bộ phận"
                          name={[name, "departmentId"]}
                          className="w-[230px]"
                          {...restField}
                        >
                          <Select
                            allowClear
                            showSearch
                            placeholder="Chọn bộ phận..."
                            filterOption={(input, option) =>
                              option?.label
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={(departments || []).map((department) => ({
                              value: department.id,
                              label: department.departmentName,
                            }))}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Chọn vai trò"
                          name={[name, "listUserRoleId"]}
                          {...restField}
                          className="w-[230px]"
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            showSearch
                            placeholder="Chọn vai trò"
                            filterOption={(input, option) =>
                              option?.label
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={(allRole || [])
                              .filter(
                                (role) =>
                                  role.roleCode !== "PCQ" &&
                                  role.roleCode !== "Member" &&
                                  role.roleCode !== "Admin"
                              )
                              .map((role) => ({
                                value: role.id,
                                label: role.roleName,
                              }))}
                          />
                        </Form.Item>
                        {fields.length > 1 && (
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        )}
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Thêm Bộ phận sẽ được gửi thông báo đến
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item label="Ghi chú" name="note">
                <Input.TextArea
                  maxLength={200}
                  showCount
                  placeholder="Nhập ghi chú...."
                  className="h-[100px]"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Button loading={loading} type="primary" htmlType="submit">
              {buttonOkModal}
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default ProcessingStepFormAction;
