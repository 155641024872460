import { LocalStorageKey, ROLES } from "@/utils/const";

export const getAccessTokenBase = () => {
  if (typeof window !== "undefined") {
    const access_token = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
    return access_token || null;
  }

  return null;
};

export const setAccessTokenBase = (data) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, data);
  }
};

export const getUserInfor = () => {
  if (typeof window !== "undefined") {
    const user_info = localStorage.getItem(LocalStorageKey.USER_INFO);
    return user_info || null;
  }

  return null;
};

export const setUserInfor = (data) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(LocalStorageKey.USER_INFO, JSON.stringify(data));
  }
};

export const getRefreshTokenBase = () => {
  if (typeof window !== "undefined") {
    const refresh_token = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN);
    console.log("refresh_token", refresh_token);
    return refresh_token !== "undefined" || null;
  }

  return null;
};

export const setRefreshTokenBase = (data) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(LocalStorageKey.REFRESH_TOKEN, data);
  }
};

export const formatCurrencyVND = (amount) => {
  return amount
    .toLocaleString("vi-VN", { style: "currency", currency: "VND" })
    .replace(/\./g, ",");
};

export const formatDiamondNumber = (number) => {
  let currency;
  let unit;

  if (number >= 1e9) {
    currency = (number / 1e9).toFixed(2);
    unit = " Tỷ";
  } else if (number >= 1e6) {
    currency = (number / 1e6).toFixed(2);
    unit = " Triệu";
  } else {
    currency = number ? number.toLocaleString() : 0;
    unit = "";
  }

  return {
    currency,
    unit,
  };
};

export const sortRolesByRoleLevel = (roles) => {
  if (Array.isArray(roles)) {
    const adminRole = roles.filter((role) => role.roleLevel === null);
    const normalRole = roles
      .filter((role) => role.roleLevel !== null)
      .sort((a, b) => b.roleLevel - a.roleLevel);

    return [...adminRole, ...normalRole];
  }

  return roles;
};

export const isFullPermission = (roles) => {
  const fullPermissionRoles = [ROLES.ADMIN, ROLES.PCQ, ROLES.HLV];
  return fullPermissionRoles.includes(roles);
};

export const isFullPermissionManageUser = (roleCode) => {
  const fullPermissionRoles = [
    ROLES.ADMIN,
    ROLES.PCQ,
    ROLES.QL,
    ROLES.HLV,
    ROLES.TT,
  ];
  return fullPermissionRoles.includes(roleCode);
};

/**
 * Chuyển đổi dữ liệu từ API thành options cho Antd Select
 * @param {Array} data - Mảng dữ liệu từ API
 * @param {string} valueKey - Key của giá trị option
 * @param {string} labelKey - Key của nhãn option
 * @returns {Array} - Mảng option cho Select
 */
export const mapDataToSelectOptions = (
  data = [],
  valueKey = "id",
  labelKey = "name"
) => {
  return data
    .map((item) => ({
      value: item[valueKey],
      label: item[labelKey],
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
