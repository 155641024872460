import ButtonPrimary from "@/common/ButtonPrimary";
import TeamsFormAction from "@/components/teams/TeamsFormAction";
import { deleteTeam, getTeamList } from "@/services/base";
import { StatusResponse } from "@/utils/const";
import { Divider, Modal, Popconfirm, Typography, message } from "antd";
import { ExclamationCircleFilled, UserOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import DataTable from "@/common/DataTable";
import moment from "moment";
import CountData from "@/common/CountData";
import { getListTeams } from "@/utils/fetchData";
import TeamsFilterForm from "@/components/teams/TeamsFilterForm";
import GroupActionButton from "@/common/GroupActionButton";
import qs from "query-string";

const initStateModal = {
  isOpenModal: false,
  isCreate: false,
  titleModal: "",
  buttonOkModal: "",
  editData: {},
};

const initParams = {
  textSearch: "",
  pageIndex: 1,
  pageSize: 20,
};

const TeamsPage = () => {
  document.title = "Quản lý tổ";
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [stateModal, setStateModal] = useState(initStateModal);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState(initParams);
  const [paginationTeam, setPaginationTeam] = useState({
    pageIndex: 1,
    pageSize: 10,
    total: 0,
    pages: 1,
  });

  useEffect(() => {
    const getTeams = async (params) => {
      setLoading(true);
      try {
        const res = await getTeamList(qs.stringify(params));

        if (res.code !== 200 && res.error !== 0) {
          message.error(res?.message);
          return;
        }

        const data = res.data?.map((item) => ({ ...item, key: item.id })) || [];
        setTeams(data);

        setPaginationTeam((prev) => ({ ...prev, total: res.pagination?.total }));
      } catch (err) {
        console.error("Error in getListDepartment:", err);
      } finally {
        setLoading(false)
      }
    }
    getTeams(params);
  }, [params]);

  const handlechangeSearch = (data) => {
    setParams({
      ...data,
      pageIndex: 1,
      pageSize: 10,
    });
  }

  const updateTeam = (data) => {
    setStateModal({
      isOpenModal: true,
      isCreate: false,
      titleModal: "Chỉnh sửa tổ",
      buttonOkModal: "Chỉnh sửa",
      editData: data,
    });
  };

  const onDeleteTeam = async (id) => {
    setLoading(true);
    try {
      const res = await deleteTeam(id);
      if (res.code === StatusResponse.SUCCESS) {
        message.success("Xóa tổ thành công");
        reloadPage();
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  const showModalConfirmDelete = (record) => {
    Modal.confirm({
      title: "Bạn có chắc chắn muốn xóa?",
      icon: <ExclamationCircleFilled />,
      content: "Khi xóa sẽ không thể hoàn tác!",
      okText: "Xóa",
      okType: "primary",

      cancelText: "Hủy",
      onOk() {
        onDeleteTeam(record);
      },
      onCancel() {},
      cancelButtonProps: {
        type: 'primary',
        danger: true
      }
    });
  };

  const columns = [
    {
      title: "Tên tổ",
      key: "teamName",
      dataIndex: "teamName",
      align: "center"
    },
    {
      title: "Thành viên",
      key: "totalMember",
      dataIndex: "totalMember",
      align: "center",
      render: (totalMember) => {
        return (
          <div className="flex items-center justify-center">
            <Typography.Text>{totalMember}</Typography.Text>
            <UserOutlined className="ml-1" />
          </div>
        );
      },
    },
    {
      title: "Trang",
      key: "site",
      dataIndex: "site",
      align: "center",
      render: (site) => {
        return site?.name;
      },
    },
    {
      title: "Bộ phận",
      key: "department",
      dataIndex: "department",
      align: "center",
      render: (department) => {
        return department?.departmentName;
      },
    },
    {
      title: "Khu",
      key: "area",
      dataIndex: "area",
      align: "center",
      render: (area) => {
        return area?.name;
      },
    },
    {
      title: "Thời gian tạo",
      key: "creationDate",
      dataIndex: "creationDate",
      align: "center",
      render: (creationDate) => {
        return (
          <Typography.Text>
            {moment(creationDate + "z").format("DD/MM/YYYY HH:mm")}
          </Typography.Text>
        );
      },
    },
    {
      title: "Hành động",
      key: "action",
      align: "center",
      render: (_, record) => (
        <GroupActionButton record={record} showModalDelete={showModalConfirmDelete} updateFn={updateTeam} />
      ),
    },
  ];

  const handleOnchangePage = (pageIndex, pageSize) => {
    setParams((prev) => ({ ...prev, pageIndex, pageSize }));
  };

  const reloadPage = () => {
    getListTeams(initParams, setTeams, setPaginationTeam);
    setParams(initParams);
    setSelectedTeams([]);
  }

  const createTeam = () => {
    setStateModal({
      isOpenModal: true,
      isCreate: true,
      titleModal: "Thêm mới tổ",
      buttonOkModal: "Thêm",
      editData: null,
    });
  };

  const closeModal = () => {
    setStateModal(initStateModal);
  };

  const deleteMultipleTeams = async () => {
    let deleteFails = [];

    const handleDelete = async () => {
      return await Promise.all(
        selectedTeams.map(async (dep) => {
          try {
            const res = await deleteTeam(dep);
            if (res.isError) {
              message.error(res.message);
              deleteFails.push(res.message);
            }
          } catch (err) {
            console.error(`Failed to delete department ${dep}:`, err);
          }
        })
      );
    };

    try {
      setLoading(true);
      await handleDelete();
      if (deleteFails.length === 0) {
        message.success("Thành công");
      }
      await reloadPage();
    } catch (err) {
      console.error("Err deleteMultipleTeams", err);
    } finally {
      setLoading(false);
    }
  };

  const handlePopconfirm = {
    confirm: deleteMultipleTeams,
    cancle: () => {},
  };

  return (
    <>
      <section className="flex items-end justify-between mb-4">
        <TeamsFilterForm loading={loading} onchangeSearch={handlechangeSearch}/>
        <div className="flex gap-3">
          {selectedTeams.length > 0 && (
            <Popconfirm
              title="Xóa tổ"
              placement="topLeft"
              description="Bạn có thật sự muốn xóa những tổ đã chọn, điều này không thể hoàn tác?"
              onConfirm={handlePopconfirm.confirm}
              onCancel={handlePopconfirm.cancle}
              okText="Xác nhận"
              cancelText="Hủy"
              cancelButtonProps={{
                type: 'primary',
                danger: true
              }}
            >
              <Typography.Text
                className="bg-red-500 hover:bg-red-600 text-white flex items-center px-2 rounded-lg cursor-pointer"
                size="middle"
                style={{ border: "none" }}
              >
                Xóa {selectedTeams.length} tổ đã chọn
              </Typography.Text>
            </Popconfirm>
          )}
          <ButtonPrimary text="Thêm tổ" onClick={createTeam}/>
        </div>
      </section>
      <CountData loading={loading} total={paginationTeam.total} text="tổ"/>
      <Divider/>
      <DataTable
        columns={columns}
        dataSource={teams}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={paginationTeam.total}
        loading={loading}
        selectedRowKeys={selectedTeams}
        setSelectedRowKeys={setSelectedTeams}
        onPageChange={handleOnchangePage}
        scroll={{ y: 530 }}
      />
      <TeamsFormAction
        stateModal={stateModal}
        closeModal={closeModal}
        reloadPage={reloadPage}
      />
    </>
  );
};

export default TeamsPage;