import React, { useState } from 'react';
import { Button, Drawer, Menu } from "antd";
import { parentRoute } from "@/layouts/BasicLayout/sider";
import {
  ApartmentOutlined, BankOutlined,
  CalculatorOutlined,
  DatabaseOutlined, FallOutlined, FileDoneOutlined, FileSearchOutlined, FolderOpenOutlined, GoldOutlined,
  PieChartOutlined, ProductOutlined, RiseOutlined, SnippetsOutlined, TeamOutlined,
  UnorderedListOutlined, UserOutlined, MenuOutlined
} from "@ant-design/icons";
import { ROLES } from "@/utils/const";
import { isFullPermissionManageUser } from "@/utils/common";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

const MenuDrawer = () => {
  const [open, setOpen] = useState(false);
  const userInfor = useRecoilValue(infoUser);
  const location = useLocation();
  const roleCode = userInfor?.role?.roleCode;
  const navigate = useNavigate()
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const route = `/${location.pathname.split("/")[1]}`;

  const items = [
    {
      key: "/home",
      icon: <PieChartOutlined style={{ fontSize: "20px" }}/>,
      label: "Trang chủ",
      rolelevel: 0,
      isAllowed: true,
    },
    {
      key: "/figure",
      icon: <CalculatorOutlined style={{ fontSize: "20px" }}/>,
      label: "Quản lý số liệu cược",
      rolelevel: 1,
      isAllowed: true,
    },
    {
      key: "/location",
      icon: <DatabaseOutlined style={{ fontSize: "20px" }}/>,
      label: "Quản lý cấp bộ",
      rolelevel: 1,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
      children: [
        {
          key: "/sites",
          icon: <UnorderedListOutlined style={{ fontSize: "20px" }}/>,
          label: "Quản lý trang",
          rolelevel: 1,
          isAllowed: [ROLES.ADMIN].includes(roleCode),
        },
        {
          key: "/departments",
          icon: <ApartmentOutlined style={{ fontSize: "20px" }}/>,
          label: "Quản lý bộ phận",
          rolelevel: 1,
          isAllowed: [ROLES.ADMIN].includes(roleCode),
        },
        {
          key: "/areas",
          icon: <GoldOutlined style={{ fontSize: "20px" }}/>,
          label: "Quản lý khu",
          rolelevel: 1,
          isAllowed: [ROLES.ADMIN].includes(roleCode),
        },
        {
          key: "/teams",
          icon: <TeamOutlined style={{ fontSize: "20px" }}/>,
          label: "Quản lý tổ",
          rolelevel: 1,
          isAllowed: [ROLES.ADMIN].includes(roleCode),
        },
        {
          key: "/group",
          icon: <DatabaseOutlined style={{ fontSize: "20px" }}/>,
          label: "Nhóm hậu đài",
          rolelevel: 1,
          isAllowed: [ROLES.ADMIN].includes(roleCode),
        },
      ]
    },
    {
      key: "/users",
      icon: <UserOutlined style={{ fontSize: "20px" }}/>,
      label: "Quản lý nhân viên",
      rolelevel: 1,
      isAllowed: isFullPermissionManageUser(roleCode),
    },
    {
      key: "/proposed-category",
      icon: <ProductOutlined style={{ fontSize: "20px" }}/>,
      label: "Danh mục đề xuất",
      rolelevel: 1,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
    },
    {
      key: "/expense-request",
      icon: <BankOutlined style={{ fontSize: "20px" }}/>,
      label: "Quản lý ngân sách",
      rolelevel: 0,
      isAllowed: true,
    },
    {
      key: "/increase-request",
      icon: <RiseOutlined style={{ fontSize: "20px" }}/>,
      label: "Ứng thêm ngân sách",
      rolelevel: 0,
      isAllowed: true,
    },
    {
      key: "/decrease-request",
      icon: <FallOutlined style={{ fontSize: "20px" }}/>,
      label: "Giảm ngân sách",
      rolelevel: 0,
      isAllowed: true,
    },
    {
      key: "/daily-report",
      icon: <SnippetsOutlined style={{ fontSize: "20px" }}/>,
      label: "Quản lý báo cáo",
      rolelevel: 0,
      isAllowed: true,
    },
    {
      key: "/close-request",
      icon: <FileDoneOutlined style={{ fontSize: "20px" }}/>,
      label: "Quản lý kết toán",
      rolelevel: 0,
      isAllowed: true,
    },
    {
      key: "/processing-steps",
      icon: <FolderOpenOutlined style={{ fontSize: "20px" }}/>,
      label: "Quản lý quy trình",
      rolelevel: 4,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
    },
    {
      key: "/log-action",
      icon: <FileSearchOutlined style={{ fontSize: "20px" }}/>,
      label: "Quản lý logs",
      rolelevel: 4,
      isAllowed: [ROLES.ADMIN].includes(roleCode),
    },
  ];
  const visibleItems = items.filter((item) => item.isAllowed);

  const pRoute = parentRoute[route];
  const onClick = (e) => {
    navigate(e.key);
  };
  return (
    <>
      <Button className={"block lg:hidden"} icon={<MenuOutlined/>} type="text" onClick={showDrawer}/>
      <Drawer classNames={{
        body: "!p-1"
      }} placement={"left"} title="Menu" onClose={onClose} open={open}>
        <div>
          <Menu
            style={{
              borderInline: "none",
              backgroundColor: "transparent",
            }}
            mode="inline"
            defaultOpenKeys={[pRoute]}
            defaultSelectedKeys={[pRoute]}
            selectedKeys={[route]}
            className="text-base bg-transparent mt-2 font-semibold"
            onClick={onClick}
            items={visibleItems}
          />
        </div>
      </Drawer>
    </>
  );
};

export default MenuDrawer;