import { getPagingUsers } from "@/services/base";
import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import qs from "query-string";
import { mapDataToSelectOptions } from "@/utils/common";
import {
  handleClearLoadMore,
  handleLoadMore,
  handleSearchLoadMore,
} from "@/utils/utils";

const ListUserAds = ({ onSearch }) => {
  const [listUserAds, setListUserAds] = useState([]);

  const [queries, setQueries] = useState({
    pageIndex: 1,
    pageSize: 20,
    departmentCode: "ads",
    total: null,
    textSearch: null
  });

  useEffect(() => {
    getListUserAds(queries);
  }, [queries.pageIndex, queries.textSearch, queries.pageSize]);

  const getListUserAds = async (queries) => {
    try {
      const res = await getPagingUsers(qs.stringify(queries));
      if (res.code === 200 && !res.isError) {
        const formatData = mapDataToSelectOptions(res.data, "id", "fullName");
        setListUserAds(formatData)
        // setListUserAds((prev) =>
        //   queries.pageIndex === 1 ? formatData : [...prev, ...formatData]
        // );
        setQueries((prev) => ({
          ...prev,
          pageIndex: res.pagination.pageIndex,
          pageSize: res.pagination.pageSize,
          total: res.pagination.total,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Form.Item name="supervisorId" className="!m-0">
      <Select
        allowClear
        showSearch
        placeholder="Thành viên ads..."
        onSearch={(e) => handleSearchLoadMore(e, setQueries)}
        onClear={handleClearLoadMore(setQueries)}
        filterOption={(input, option) =>
          (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onPopupScroll={handleLoadMore(queries, setQueries)}
        className="!w-[200px]"
      >
        {listUserAds?.map((item) => (
          <Select.Option key={item?.value} value={item.value}>
            {item?.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default ListUserAds;
