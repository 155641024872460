import TagStatus from "@/components/listRequset/TagStatus";
import { formatCurrencyVND } from "@/utils/common";
import { Divider, Input, Modal, Tooltip, Typography, Tag } from "antd";
import { QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router";
import { getListExpenseChildAdj } from "@/services/base";
import DataTable from "@/common/DataTable";
import ExpenseLink from "@/common/ExpenseLink";
import { debounce } from "lodash";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";

const ModalExpenseDecreaseIncrease = forwardRef((props, ref) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const infoUserState = useRecoilValue(infoUser);

  const initalParams = {
    textSearch: "",
    expenseId: id,
    pageIndex: 1,
    pageSize: 10,
  };
  const [searchParams, setSearchParam] = useState({
    textSearch: "",
    expenseId: id,
    pageIndex: 1,
    pageSize: 10,
  });
  const [state, setState] = useState({
    open: false,
  });
  const [totalPage, setTotalPage] = useState(0);
  const [dataRequestAdj, setDataRequestAdj] = useState([]);
  const toggle = useCallback(() => {
    setState((p) => ({
      ...p,
      open: !p.open,
    }));
  }, []);

  const fetchDataListExpenseAdj = async (params) => {
    try {
      setLoading(true);
      const res = await getListExpenseChildAdj(params);
      if (res.code === 200 && res.error === 0) {
        setDataRequestAdj(res.data.map((u) => ({ ...u, key: u.id })));
        setTotalPage(res?.pagination?.total);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!state.open) return setSearchParam(initalParams);
    fetchDataListExpenseAdj(searchParams);
  }, [state.open]);

  useImperativeHandle(
    ref,
    () => {
      return {
        toggle,
      };
    },
    [toggle]
  );

  const renderStatusCol = (value) => {
    return <TagStatus record={value} />;
  };

  const columns = [
    {
      title: "Mã phiếu",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 90,
      render: (id, record) => (
        <ExpenseLink
          id={id}
          page={record?.expenseType?.shortName}
        >{`${record.expenseType.shortName}${id}`}</ExpenseLink>
      ),
    },
    {
      title: "Loại phiếu",
      dataIndex: "expenseType",
      key: "expenseType",
      align: "center",
      width: 150,
      render: (expenseType) => {
        return <Typography.Text>{expenseType?.name}</Typography.Text>;
      },
    },
    {
      title: "Loại hình",
      dataIndex: "purpose",
      key: "purpose",
      align: "center",
      render: (value, rec) => {
        const isNVL = value?.purposeCode == "NVL" && rec?.numOfPage;

        if (isNVL) {
          return value?.purposeName ? (
            <>
              {value?.purposeName} -{" "}
              <span className="text-[#1677ff]">{rec?.numOfPage} page</span>
            </>
          ) : (
            "--"
          );
        }

        return value?.purposeName ? value?.purposeName : "--";
      },
    },
    {
      title: (
        <span>
          Số tiền{" "}
          <Tooltip title="Số tiền ngân sách">
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: "totalAmount",
      width: 130,
      key: "totalAmount",
      align: "center",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng ngân sách",
      dataIndex: "calculateAmount",
      width: 130,
      key: "calculateAmount",
      align: "center",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "QUY TRÌNH",
      children: [
        {
          title: "Tên quy trình duyệt",
          dataIndex: "processType",
          key: "processType",
          align: "center",
          render: (value) => (value?.name ? value?.name : "--"),
        },
        {
          title: "Bước xử lý",
          dataIndex: "processStep",
          key: "processStep",
          align: "center",
          render: (value, rec) => {
            const isProcessingCurrentUser =
              infoUserState?.departmentId == value?.departmentId;
            const stepName = value?.stepName ? value?.stepName : "--";
            const allowStatus = ["Inprogress", "Pending"].includes(
              rec?.processStatus?.statusCode
            );
            return isProcessingCurrentUser && allowStatus && value?.stepName ? (
              <Tag color="blue">{stepName}</Tag>
            ) : (
              stepName
            );
          },
        },
      ],
    },
    {
      title: "Người duyệt",
      dataIndex: "userApprove",
      key: "userApprove",
      width: 130,
      align: "center",
      render: (value) => {
        return <>{value?.fullName ? value?.fullName : "--"}</>;
      },
    },
    {
      title: "Ngày chỉnh sửa",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      width: 130,
      align: "center",
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
    {
      title: "Ngày tạo",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 130,
      align: "center",
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
    {
      title: "Trạng thái",
      dataIndex: "processStatus",
      key: "processStatus",
      width: 140,
      align: "center",
      fixed: "right",
      render: (_, rec) => renderStatusCol(rec),
    },

    {
      title: "Phụ trách camp",
      dataIndex: "userSupervisorCamp",
      key: "userSupervisorCamp",
      align: "center",
      fixed: "right",
      render: (value, { teamSupervisorCamp }) =>
        value?.fullName ? (
          <span className="truncate">
            {value.fullName}
            {teamSupervisorCamp?.teamName
              ? ` - ${teamSupervisorCamp.teamName}`
              : ""}
          </span>
        ) : (
          <div className="min-w-[100px]">--</div>
        ),
    },
  ];

  const onchangePage = (pageIndex, pageSize) => {
    setSearchParam((prevParams) => ({
      ...prevParams,
      pageIndex: pageIndex,
      pageSize: pageSize,
    }));
    fetchDataListExpenseAdj({
      pageIndex,
      pageSize,
      expenseId: searchParams.expenseId,
      textSearch: searchParams.textSearch,
    });
  };
  const onSearch = (data) => {
    setSearchParam(data);
    fetchDataListExpenseAdj(data);
  };

  const debouncedSearch = useMemo(() => {
    return debounce((data) => onSearch(data), 300);
  }, []);

  const handleOnchangeSearch = useCallback(
    (event) => {
      const value = event.target.value.trim().replace(/\s\s+/g, " ");
      debouncedSearch({ ...searchParams, textSearch: value });
    },
    [searchParams]
  );

  return (
    <Modal
      title="TẤT CẢ ĐỀ XUẤT BỔ SUNG"
      open={state.open}
      width={1600}
      onCancel={toggle}
      // maskClosable={false}
      className="ModalExpenseDecreaseIncrease top-3"
      footer={false}
    >
      <Divider />
      <div className="max-h-[800px] overflow-y-auto overflow-x-hidden Container__ModalExpenseDecreaseIncrease">
        <div>
          <Input
            className="w-[300px]"
            allowClear
            addonBefore={<SearchOutlined />}
            size="large"
            placeholder="Tìm kiếm...."
            onChange={handleOnchangeSearch}
          />
        </div>
        <Divider />
        <DataTable
          columns={columns}
          dataSource={dataRequestAdj || []}
          currentPage={searchParams.pageIndex}
          pageSize={searchParams.pageSize}
          totalPage={+totalPage}
          loading={loading}
          scroll={{ x: "max-content", y: 500 }}
          isBorder
          onPageChange={onchangePage}
        />
      </div>
    </Modal>
  );
});

export default ModalExpenseDecreaseIncrease;
