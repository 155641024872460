import React, { useEffect, useState } from 'react';
import { Flex, Form, Select, Tooltip } from "antd";
import usePartnerHook from "@/hooks/usePartnerHook";
import { InfoCircleOutlined } from "@ant-design/icons";
import { handleClearLoadMore, handleLoadMore, handleSearchLoadMore, objectToSearchString } from "@/utils/utils";

const PartnerInfo = () => {
  const { partners, getPartnersPage, pagination  } = usePartnerHook();
  const [params, setParams] = useState({
    textSearch: '',
    pageIndex: 1,
    pageSize: 10,
  });

  useEffect(() => {
    getPartnersPage(params);
  }, [params?.textSearch, params?.pageSize]);

  const Information = ({ item }) => (
    <div>
      <p className="p-0 m-0"><strong>Mã DT</strong>: {item.partnerCode}</p>
      <p className="p-0 m-0"><strong>Họ tên</strong>: {item.partnerFullName}</p>
      <p className="p-0 m-0"><strong>Tài khoản</strong>: {item.partnerAccount}</p>
      {item.phoneZalo && <p className="p-0 m-0"><strong>Zalo</strong>: {item.phoneZalo}</p>}
      {item.phoneTele && <p className="p-0 m-0"><strong>Tele</strong>: {item.phoneTele}</p>}
    </div>
  );

  return (
    <Form.Item
      label="Chọn đối tác"
      name="partnerId"
      rules={[{ required: true, message: "Vui lòng chọn đối tác" }]}
    >
      <Select
        allowClear
        onSearch={(e) => handleSearchLoadMore(e, setParams)}
        showSearch
        placeholder="Chọn đối tác"
        options={partners?.map(item => ({
          value: item.id,
          label: (
            <Flex className="justify-between">
              <span className="truncate">{`${item.partnerAccount} - ${item.partnerFullName}`}</span>
              <Tooltip title={<Information item={item} />}>
                <InfoCircleOutlined className="ml-2 text-primary" />
              </Tooltip>
            </Flex>
          ),
          searchObject: objectToSearchString(item)
        }))}
        onClear={handleClearLoadMore(setParams)}
        filterOption={(input, option) =>
          option?.searchObject.toLowerCase().includes(input.toLowerCase())
        }
        notFoundContent={<div className="p-2 text-center text-black">
          Không tìm thấy đối tác. Bạn có muốn <a href="/partners">tạo đối tác mới ?</a>
        </div>}
        onPopupScroll={handleLoadMore(pagination, setParams)}
      />
    </Form.Item>
  );
};

export default PartnerInfo;