import React from 'react';
import { Form, Select } from "antd";

const options = [
  { code: "Reported", name: "Đã báo cáo"},
  { code: "NotReport", name: "Chưa báo cáo"}
];

const DailyReportStatusForm = () => {
  return (
    <Form.Item name="reportDailyStatus" className="!m-0">
      <Select
        allowClear
        className="!w-[200px]"
        placeholder="Trạng thái báo cáo..."
        options={options.map(rec => ({
          value: rec.code,
          label: rec.name,
        }))}
      />
    </Form.Item>
  );
};

export default DailyReportStatusForm;