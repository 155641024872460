import React from 'react';
import { Form, Select } from "antd";
import { useRecoilValue } from "recoil";
import { allDepartments } from "@/recoil/atoms";

const DepartProcessForm = () => {
  const departments = useRecoilValue(allDepartments);

  return (
    <Form.Item name="processDepartmentId" className="!m-0">
      <Select
        allowClear
        className="!w-[200px]"
        placeholder="Bộ phận xử lý..."
      >
        {departments?.map(rec => (
          <Select.Option
            key={rec.id}
            value={rec.id}
          >
            {rec.departmentName}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default DepartProcessForm;