import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DatePicker, message, Select } from "antd";
import { useRecoilValue } from "recoil";
import { allSites, infoUser } from "@/recoil/atoms";
import dayjs from "dayjs";
import qs from "query-string";
import { formatCurrencyVND, formatDiamondNumber } from "@/utils/common";
import { getSumaryTicketStatus } from "@/services/base";
import { handleClearLoadMore, handleLoadMore, handleSearchLoadMore, } from "@/utils/utils";
import { getListAreas, getListGroups, getListTeams, } from "@/utils/fetchData";
import CardItem from "./CardItem";
import {
  AuditOutlined,
  ExceptionOutlined,
  FileExcelOutlined,
  LoadingOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { ReactComponent as DongIcon } from "@/assets/icons/DongIcon.svg";
import { StatusResponse } from "@/utils/const";

const { RangePicker } = DatePicker;

const cardTicketItems = [
  {
    title: "Ngân sách đã tạo",
    total: 0,
    backgroundImage: "linear-gradient(135deg, #8dc7f0 10%, #0396FF 100%)",
    icon: <ExceptionOutlined />,
  },
  {
    title: "Ngân sách đang xử lý",
    total: 0,
    backgroundImage: "linear-gradient( 135deg, #EE9AE5 10%, #5961F9 100%)",
    icon: <SolutionOutlined />,
  },
  {
    title: "Ngân sách hoàn thành",
    total: 0,
    backgroundImage: "linear-gradient( 135deg, #2AFADF 10%, #4C83FF 100%)",
    icon: <AuditOutlined />,
  },
  {
    title: "Ngân sách từ chối",
    total: 0,
    backgroundImage: "linear-gradient( 135deg, #FFD3A5 10%, #FD6585 100%)",
    icon: <FileExcelOutlined />,
  },
];

const cardBudgetItems = [
  {
    title: "Tổng tiền đề xuất ngân sách chưa duyệt",
    total: 0,
    unit: "",
    detail: "",
    backgroundImage: "bg-gradient-primary",
    icon: <DongIcon />,
  },
  {
    title: "Tổng tiền đề xuất ngân sách đã duyệt",
    total: 0,
    unit: "",
    detail: "",
    backgroundImage: "bg-gradient-primary",
    icon: <DongIcon />,
  },
];

const initParams = {
  textSearch: "",
  pageIndex: 1,
  pageSize: 20,
  total: 0,
};

const Statistics = ({
  visibleOptions,
  optionsSelectSite,
  optionsSelectArea,
  optionsSelectTeam,
  optionsSelectGroup,
  isTLBank
}) => {
  const sites = useRecoilValue(allSites);
  const [ticketItems, setTicketItems] = useState(cardTicketItems);
  const [budgetItems, setBudgetItems] = useState(cardBudgetItems);
  const [loading, setLoading] = useState(false);
  const userInfor = useRecoilValue(infoUser);
  const isSupperSite = userInfor?.site?.isSupper;
  const [areaParams, setAreaParams] = useState({
    ...initParams,
    siteId: isSupperSite ? null : userInfor?.siteId,
  });
  const [teamParams, setTeamParams] = useState({
    ...initParams,
    siteId: isSupperSite ? null : userInfor?.siteId,
    areaId: isSupperSite ? null : userInfor?.areaId,
  });
  const [groupParams, setGroupParams] = useState({
    ...initParams,
    siteId: isSupperSite ? null : userInfor?.siteId,
    areaId: isSupperSite ? null : userInfor?.areaId,
    teamId: isSupperSite ? null : userInfor?.teamId,
  });
  const [areas, setAreas] = useState([]);
  const [teams, setTeams] = useState([]);
  const [groups, setGroups] = useState([]);
  const [searchParams, setSearchParams] = useState({
    siteId: isSupperSite ? null : userInfor?.siteId,
    areaId: isSupperSite || isTLBank ? null : userInfor?.areaId,
    teamId: isSupperSite ? null : userInfor?.teamId,
    groupId: isSupperSite ? null : userInfor?.groupId,
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    getListAreas(areaParams, setAreas, setAreaParams);
  }, [areaParams.pageSize, areaParams.textSearch, areaParams.siteId]);

  useEffect(() => {
    getListTeams(teamParams, setTeams, setTeamParams);
  }, [teamParams.pageSize, teamParams.textSearch, teamParams.siteId, teamParams.areaId]);

  useEffect(() => {
    getListGroups(groupParams, setGroups, setGroupParams);
  }, [
    groupParams.pageSize,
    groupParams.textSearch,
    groupParams.siteId,
    groupParams.areaId,
    groupParams.teamId,
  ]);

  useEffect(() => {
    getAnalytics();
  }, [searchParams]);

  const getAnalytics = async () => {
    setLoading(true);

    try {
      const params = qs.stringify(searchParams);
      const res = await getSumaryTicketStatus(params);
      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }

      if (Array.isArray(res.data)) {
        let newTicketItems = [...ticketItems];
        let newBudgetItems = [...budgetItems];

        res.data.map((item, index) => {
          const isTickets = [0, 1, 2, 3].includes(index);

          if (isTickets) {
            newTicketItems[index].total = item?.count;
          } else {
            const currency = formatDiamondNumber(item.sum);

            newBudgetItems[index - 4].total = currency.currency;
            newBudgetItems[index - 4].detail = formatCurrencyVND(item.sum);
            newBudgetItems[index - 4].unit = currency.unit;
          }
        });

        setTicketItems(newTicketItems);
        setBudgetItems(newBudgetItems);
      }
      setLoading(false);
    } catch (error) {
      message.error(error);
      setLoading(false);
    }
  };

  const onChangeDate = (date) => {
    if (date) {
      const startDateString = dayjs(date[0]).add(1, "day").toISOString();
      const endDateString = dayjs(date[1]).add(1, "day").toISOString();

      setSearchParams((prev) => ({
        ...prev,
        startDate: startDateString,
        endDate: endDateString,
      }));
    } else {
      setSearchParams((prev) => ({
        ...prev,
        startDate: "",
        endDate: "",
      }));
    }
  };

  const handleSiteChange = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      siteId: value,
    }));

    setAreaParams((prev) => ({
      ...prev,
      siteId: value,
    }));

    setTeamParams((prev) => ({
      ...prev,
      siteId: value,
    }));

    setGroupParams((prev) => ({
      ...prev,
      siteId: value,
    }));
  };

  const handleAreChange = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      areaId: value,
    }));

    setTeamParams((prev) => ({
      ...prev,
      areaId: value,
    }));

    setGroupParams((prev) => ({
      ...prev,
      areaId: value,
    }));
  };

  const handleTeamChange = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      teamId: value,
    }));

    setGroupParams((prev) => ({
      ...prev,
      teamId: value,
    }));
  };

  const handleGroupChange = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      groupId: value,
    }));
  };

  const filterOption = useCallback(
    (input, option) =>
      option?.label.toLowerCase().includes(input.toLowerCase()),
    []
  );

  const areaOptions = useMemo(
    () =>
      areas?.map(({ id, areaName }) => ({
        value: id,
        label: areaName,
      })),
    [areas]
  );

  const teamOptions = useMemo(
    () =>
      teams?.map(({ id, teamName }) => ({
        value: id,
        label: teamName,
      })),
    [teams]
  );

  const groupOptions = useMemo(
    () =>
      groups?.map(({ id, groupName }) => ({
        value: id,
        label: groupName,
      })),
    [groups]
  );

  return (
    <div>
      <section className="flex items-end gap-2 mb-10">
        <RangePicker
          placeholder={["Ngày Bắt Đầu", "Ngày Kết Thúc"]}
          format={"DD/MM/YYYY"}
          allowClear
          size="medium"
          className="min-w-80"
          onChange={(date) => onChangeDate(date)}
        />
        {visibleOptions?.isShowSite && (
          <Select
            {...optionsSelectSite}
            allowClear
            className="w-[200px]"
            showSearch
            placeholder="Chọn trang"
            onChange={handleSiteChange}
            filterOption={filterOption}
            options={sites?.map(({ id, siteName }) => ({
              value: id,
              label: siteName,
            }))}
          />
        )}

        {visibleOptions?.isShowArea && (
          <Select
            {...optionsSelectArea}
            allowClear
            className="w-[200px]"
            showSearch
            placeholder="Chọn khu"
            onChange={handleAreChange}
            onSearch={(e) => handleSearchLoadMore(e, setAreaParams)}
            onClear={handleClearLoadMore(setAreaParams)}
            filterOption={filterOption}
            options={areaOptions}
            onPopupScroll={handleLoadMore(areaParams, setAreaParams)}
          />
        )}

        {visibleOptions?.isShowTeam && (
          <Select
            {...optionsSelectTeam}
            allowClear
            className="w-[200px]"
            showSearch
            placeholder="Chọn tổ"
            onChange={handleTeamChange}
            onSearch={(e) => handleSearchLoadMore(e, setTeamParams)}
            onClear={handleClearLoadMore(setTeamParams)}
            filterOption={filterOption}
            options={teamOptions}
            onPopupScroll={handleLoadMore(teamParams, setTeamParams)}
          />
        )}

        {visibleOptions?.isShowGroup && (
          <Select
            {...optionsSelectGroup}
            allowClear
            className="w-[200px]"
            showSearch
            placeholder="Chọn hậu đài"
            onChange={handleGroupChange}
            onSearch={(e) => handleSearchLoadMore(e, setGroupParams)}
            onClear={handleClearLoadMore(setGroupParams)}
            filterOption={filterOption}
            options={groupOptions}
            onPopupScroll={handleLoadMore(groupParams, setGroupParams)}
          />
        )}

        {loading && (
          <LoadingOutlined
            style={{
              fontSize: 28,
              color: "#38a7ff",
              fontWeight: "bold",
            }}
          />
        )}
      </section>
      <div className="w-full flex flex-wrap mx-[-10px]">
        {ticketItems.map((card, index) => {
          return (
            <div className="w-1/4 px-[10px]" key={index}>
              {" "}
              <CardItem card={card} />{" "}
            </div>
          );
        })}
      </div>

      <div className="w-full flex flex-wrap mx-[-10px] mt-6">
        {budgetItems.map((card, index) => {
          return (
            <div className="w-1/2 px-[10px]" key={index}>
              <CardItem card={card} tooltip={true} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Statistics;
