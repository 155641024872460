import { Image, List, Tabs } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FaImages } from "react-icons/fa";
import { RiUserVoiceFill } from "react-icons/ri";
import { useParams } from "react-router";
import { listHistoryDelete } from "@/services/base";
import moment from "moment";
import { getFileExtension } from "@/utils/utils";

const BASE_URL = process.env.REACT_APP_FILE;

const HistoryDeleteProcess = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  // const [paramStates, setParamState] = useState({
  //   expenseId: id,
  //   isVoice: false,
  // });
  const [tabKey, setTabkey] = useState("1");
  const [historyDelete, setHistoryDelete] = useState([]);

  const itemTabs = [
    {
      key: "1",
      icon: <FaImages />,
      text: "Hình ảnh",
    },
    {
      key: "2",
      icon: <RiUserVoiceFill />,
      text: "Voice",
    },
  ];

  const listDataForTabKey = useMemo(() => {
    return tabKey === "1"
      ? historyDelete.filter((el) => !el.isVoice)
      : historyDelete.filter((el) => el.isVoice);
  }, [historyDelete, tabKey]);

  console.log(historyDelete, "list");

  useEffect(() => {
    getListHistoryDeletes(id);
  }, [id]);

  const getListHistoryDeletes = async (id) => {
    setLoading(true);
    try {
      const res = await listHistoryDelete({ expenseId: id });
      if (res.code === 200 && !res.isError) {
        setHistoryDelete(res?.data);
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full ">
      <Tabs
        defaultActiveKey={tabKey}
        items={itemTabs.map((item, i) => {
          return {
            key: item.key,
            label: item.text,
            icon: item.icon,
          };
        })}
        onChange={(key) => setTabkey(key)}
      />
      <List
        itemLayout="horizontal"
        dataSource={listDataForTabKey}
        style={{ maxHeight: "500px", overflowY: "auto" }}
        locale={{ emptyText: "Không có dữ liệu" }}
        loading={loading}
        renderItem={(item, index) => {
          const isType = ["mp4", "webm", "ogg", "wmv"];
          const fileExtension = getFileExtension(item?.filePath);
          return (
            <List.Item>
              <div className="w-full flex gap-4">
                {!isType.includes(item.fileType) ? (
                  <Image
                    src={
                      `${BASE_URL}/${item?.filePath}` ||
                      "https://via.placeholder.com/150"
                    }
                    width={240}
                    height={140}
                    preview={true}
                    style={{ objectFit: "cover", borderRadius: "10px" }}
                  />
                ) : item.fileType !== "webm" ? (
                  <video
                    key={index}
                    controls
                    width={250}
                    height={140}
                    autoPlay={false}
                    preload="metadata"
                  >
                    <source
                      src={`${process.env.REACT_APP_FILE}/${item?.filePath}`}
                      type={`video/${fileExtension}`}
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <audio controls height={70} width={140} preload="metadata">
                    <source
                      src={`${BASE_URL}/${item?.filePath}`}
                      type="audio/webm"
                    />
                    Your browser does not support the audio tag.
                  </audio>
                )}

                <div className="flex flex-1 flex-col">
                  <span className="font-bold">
                    Người cập nhật:{" "}
                    <span className="font-thin">
                      {item?.userCreate?.fullName}
                    </span>{" "}
                  </span>
                  <span className="font-bold">
                    Ngày cập nhật:{" "}
                    <span className="font-thin">
                      {moment(item?.modifiedDate).format("DD/MM/YYYY hh:mm")}
                    </span>{" "}
                  </span>
                  {item?.description && (
                    <span className="font-bold">
                      Mô tả:{" "}
                      <span
                        className="font-thin"
                        dangerouslySetInnerHTML={{
                          __html: item?.description || "",
                        }}
                      ></span>
                    </span>
                  )}
                </div>
              </div>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default HistoryDeleteProcess;
