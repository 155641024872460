import React from "react";
import { Col, Form, InputNumber } from "antd";

const DetailAmountForm = ({ spanNumber, name, restField }) => {
  return (
    <>
      <Col span={spanNumber}>
        <Form.Item
          label="Tiền NS"
          {...restField}
          name={[name, "amountCamp"]}
          rules={[
            {
              required: true,
              message: "Vui lòng nhập số tiền",
            },
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error("Số tiền không được âm!"));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber
            min={0}
            className="w-full"
            placeholder="Nhập số tiền NS"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
            onClick={(event) => event.target.select()}
          />
        </Form.Item>
      </Col>
      <Col span={spanNumber}>
        <Form.Item
          label="Phí (%)"
          {...restField}
          name={[name, "amountAdditional"]}
          rules={[
            {
              validator: (_, value) => {
                if (value < 0) {
                  return Promise.reject(new Error("Phí được âm!"));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber
            min={0}
            className="w-full"
            placeholder="Nhập phí"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
            onClick={(event) => event.target.select()}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default DetailAmountForm;
