import React from "react";
import { Tag, Table, Popover, Button, ConfigProvider } from "antd";
import { formatCurrencyVND } from "@/utils/common";
import { ROLES, DEPARTMENT_TYPES, SITE_SUMMARY_STATUS } from "@/utils/const";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import {
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const FigureSiteInfor = ({ groupSummary, totalAmount, isSpecial }) => {
  
  const infoUserState = useRecoilValue(infoUser);
  const { role, departmentType } = infoUserState;
  const isAdmin = role.roleCode === ROLES.ADMIN;
  const isPcq = role.roleCode === ROLES.PCQ;
  const isSaleDepartment = departmentType?.departmentTypeCode === DEPARTMENT_TYPES.SALE;
  const budgetAverage = groupSummary?.budgetAverage || 0;
  const nsqc = groupSummary?.nsqc || 0;
  const totalBanking = groupSummary?.totalBankingDaily || 0;
  const availableBudget = groupSummary?.budgetAvailable || 0;
  const budgetAvailableNvl = groupSummary?.budgetAvailableNvl || 0;
  const nsqcol1 = groupSummary?.nsqcol1 || 0;
  const nsnvl = groupSummary?.nsnvl || 0;

  const showWarningBudget = availableBudget > 0 && totalAmount > 0 && totalAmount > availableBudget;
  const isShowPopover = isAdmin || isPcq || isSaleDepartment;
  
  const figures = [
    {
      name: "Nhóm hậu đài",
      criteria: groupSummary?.groupName,
      code: 'GROUP',
    },
    {
      name: "Nhóm hậu đài đặc biệt",
      criteria: isSpecial ? 'Có' : 'Không',
    },
    {
      name: "Số người chạy chung",
      criteria: groupSummary?.teamSize,
    },
    {
      name: "Ngân sách chạy quảng cáo",
      criteria: formatCurrencyVND(nsqc),
    },
    {
      name: "Ngân sách tổ phó",
      criteria: formatCurrencyVND(nsqcol1),
    },
    {
      name: "Ngân sách nguyên liệu",
      criteria: formatCurrencyVND(nsnvl),
    },
    {
      name: "NS đã bank trong ngày",
      criteria: formatCurrencyVND(totalBanking),
    },
    {
      name: "Số tiền đã ứng trong hậu đài",
      criteria: `${formatCurrencyVND(budgetAverage)}/người`,
    },
    {
      name: "Ngân sách có thể ứng",
      criteria: formatCurrencyVND(availableBudget > 0 ? availableBudget : 0),
      code: 'AVERAGE',
    },
    {
      name: "Ngân sách NVL có thể ứng",
      criteria: formatCurrencyVND(budgetAvailableNvl ?? 0),
    },
    {
      name: "Trạng thái",
      criteria: groupSummary?.statusCheck,
      code: 'STATUS',
    },
  ];

  const columns = [
    {
      title: "Tên tiêu chí",
      dataIndex: "name",
      key: "name",
      render: (value) => (value ? value : "--"),
    },
    {
      title: "Giá trị",
      dataIndex: "criteria",
      key: "criteria",
      render: (value, item) => {
        if (item.code === "STATUS") {
          return (
            <Tag color={SITE_SUMMARY_STATUS[value]?.color}>
              {SITE_SUMMARY_STATUS[value]?.text}
            </Tag>
          );
        } else if (item.code === "AVERAGE" && isSpecial) {
          return 'Không gới hạn';
        }

        return value ? value : "--";
      },
    },
  ];

  const content = (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            cellPaddingBlock: 8,
          },
        },
      }}
    >
      <Table
        className=""
        columns={columns}
        dataSource={figures.map((u) => ({ ...u, key: u.name })) || []}
        bordered
        pagination={false}
      />
    </ConfigProvider>
  );
  return (
    <>
      {isShowPopover && (
        <>
          {showWarningBudget && (
            <div className="text-[#d46b08]">
              <ExclamationCircleOutlined/>
              <span className="ml-1">
                Số tiền ứng ngân sách nên tối đa là{" "}
                {formatCurrencyVND(availableBudget)}
              </span>
            </div>
          )}
          <Popover
            placement="right"
            title={`Tiêu chí để tạo hoặc duyệt ngân sách từ hậu đài ${
              groupSummary?.groupName ? groupSummary?.groupName : ""
            }`}
            content={content}
          >
            Xem tiêu chí để tạo hoặc duyệt ngân sách &nbsp;
            <QuestionCircleOutlined />
          </Popover>
        </>
      )}
    </>
  );
};

export default FigureSiteInfor;
