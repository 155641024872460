import React, { useEffect } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';

const PartnersFormAction = ({ stateModal, closeModal, actionPartner, currentPage, reload }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!stateModal.isCreate && stateModal.editData) {
      form.setFieldsValue(stateModal.editData);
    } else {
      form.resetFields();
    }
  }, [stateModal, form]);

  const handleSubmit = async (values) => {
    const actionType = stateModal.isCreate ? 'create' : 'update';
    const { success, message: apiMessage } = await actionPartner({ ...values, pageIndex: currentPage }, actionType);

    if (success) {
      message.success(apiMessage);
      await reload();
      closeModal();
    } else {
      message.error(apiMessage);
    }
  };

  return (
    <Modal
      title={stateModal.titleModal}
      open={stateModal.isOpenModal}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" type="primary" onClick={closeModal} danger>
          Hủy
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => form.submit()}
        >
          {stateModal.buttonOkModal}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Tài khoản đối tác"
          name="partnerAccount"
          rules={[{ required: true, message: 'Vui lòng nhập tài khoản đối tác!' }]}
        >
          <Input placeholder="Tài khoản đối tác" />
        </Form.Item>
        <Form.Item
          label="Họ tên đối tác"
          name="partnerFullName"
          rules={[{ required: true, message: 'Vui lòng nhập họ tên đối tác!' }]}
        >
          <Input placeholder="Họ tên đối tác" />
        </Form.Item>
        <Form.Item
          label="Số điện thoại Zalo"
          name="phoneZalo"
          // rules={[{ required: true, message: 'Vui lòng nhập số điện thoại Zalo!' }]}
        >
          <Input placeholder="Số điện thoại Zalo" />
        </Form.Item>
        <Form.Item
          label="Số điện thoại Telegram"
          name="phoneTele"
          // rules={[{ required: true, message: 'Vui lòng nhập số điện thoại Telegram!' }]}
        >
          <Input placeholder="Số điện thoại Telegram" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PartnersFormAction;
