import React from "react";
import { Button, Col, Form, InputNumber, Row, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { allProducts } from "@/recoil/atoms";

const CategorySelect = ({ name, restField }) => {
  const listProducts = useRecoilValue(allProducts);
  return (
    <Form.Item
      label="Danh mục đề xuất"
      name={[name, "categoryId"]}
      {...restField}
      rules={[
        {
          required: true,
          message: "Vui lòng chọn danh mục",
        },
      ]}
    >
      <Select
        allowClear
        showSearch
        placeholder="Chọn danh mục đề xuất"
        filterOption={(input, option) =>
          (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={listProducts?.map((product) => ({
          label: product.categoryName,
          value: product.id,
        }))}
      />
    </Form.Item>
  );
};

const CategoryReportForm = () => {
  const spanNumber = 8;

  return (
    <>
      <Form.List name="expenseDetails">
        {(fields, { add, remove }) => (
          <>
            {fields?.map(({ key, name, ...restField }, index) => {
              return (
                <Row key={key} gutter={[10, 0]} className="relative">
                  <Col span={fields.length > 1 ? spanNumber - 1 : spanNumber}>
                    <CategorySelect name={name} restField={restField} />
                  </Col>
                  <Col span={spanNumber}>
                    <Form.Item
                      label={<>Tiền camp</>}
                      {...restField}
                      name={[name, "amountCamp"]}
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập số tiền",
                        },
                        {
                          validator: (_, value) => {
                            if (value < 0) {
                              return Promise.reject(
                                new Error("Số tiền không được âm!")
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        className="w-full"
                        placeholder="Nhập số tiền camp"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                        onClick={(event) => event.target.select()}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={spanNumber}>
                    <Form.Item
                      label={<>Tiền dôi camp</>}
                      {...restField}
                      name={[name, "amountAdditional"]}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value < 0) {
                              return Promise.reject(
                                new Error("Số tiền không được âm!")
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        className="w-full"
                        placeholder="Nhập số tiền dôi camp"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                        onClick={(event) => event.target.select()}
                      />
                    </Form.Item>
                  </Col>
                  {fields.length > 1 && (
                    <Col span={1}>
                      <MinusCircleOutlined
                        className="text-[16px] absolute right-1 top-[52px]"
                        onClick={() => remove(name)}
                      />
                    </Col>
                  )}
                </Row>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Thêm danh mục đề xuất
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default CategoryReportForm;
