import React from "react";

const CategoryTag = ({ text }) => {
  const bgColors = {
    "Facebook": "bg-[#1877F2]",
    "TikTok": "bg-[#000000]",
    "Tiktok": "bg-[#000000]",
    "Google Ads": "bg-[#4285F4]",
    "Dôi camp 1%": "bg-[#FFA500]",
    "Lố ngân sách": "bg-[#FFA07A]",
    "Nguyên vật liệu": "bg-[#D2B48C]",
    "Refund": "bg-[#FF7F50]"
  };

  const backgroundColor = bgColors[text] || "bg-[#1677ff]";
  return <span className={"py-[2px] px-3 rounded-full text-white font-normal " + backgroundColor}>{text}</span>;
};

export default CategoryTag;
