import { Badge, Button, Dropdown, Flex, message, Modal, Spin, Tabs } from "antd";
import { ReloadOutlined, SmileOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { infoUser, signalRState } from "@/recoil/atoms";
import { useRecoilValue } from "recoil";
import { deleteNotification, getNotification, viewNotification } from "@/services/base";
import "./styles.css";
import NotifitcationItem from "@/components/notify/NotifitcationItem";
import { IoNotifications } from "react-icons/io5";

const EVENT_NAMES = [
  "Expense",
  "ExpenseStatusApprove",
  "ExpenseDailyReport",
  "ExpenseModify",
  "ExpenseNeedApproved",
  "ExpenseRequestClose",
  "ExpenseAproved",
  "ExpensePending",
  "ExpenseReject",
  "ExpenseClose",
  "ExpenseIncrease",
  "ExpenseDecrease",
  "ExpenseReportPending",
  "ExpenseReport",
  "ExpenseReportAproved",
  "ExpenseIncreaseAproved",
  "ExpenseReportReject",
  "ExpenseCloseAproved",
  "ExpenseCloseReject",
  "ExpenseIncreaseReject",
  "ExpenseIncreasePending"
];

const Notify = () => {
  const dataUser = useRecoilValue(infoUser);
  const connectionSignalR = useRecoilValue(signalRState);
  const [listNotify, setListNotify] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [countUnRead, setCountUnRead] = useState(0);
  const [isViewAll, setIsViewAll] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingReload, setLoadingReload] = useState(false);

  useEffect(() => {
    fetchNotifications(isViewAll, false, 1);
  }, []);

  const fetchNotifications = async (isViewAll, loadMore = false, currentPageIndex = pageIndex) => {
    try {
      const params = {
        pageIndex: currentPageIndex,
        pageSize: 10,
        userId: dataUser.id,
        isViewAll,
      };
      const res = await getNotification(params);
      const resUnRead = await getNotification({
        userId: dataUser.id,
        isViewAll: false,
      });
      if (res.code === 200 && res.error === 0) {
        if (loadMore) {
          setListNotify((prevList) => [...prevList, ...res.data]);
        } else {
          setListNotify(res.data);
        }
        setTotalItems(res.pagination.total);
        setCountUnRead(resUnRead.pagination.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const markViewedAll = async () => {
    try {
      const params = { userId: dataUser.id };
      const res = await viewNotification(params);
      if (res.code === 200) {
        setCountUnRead(0);
        await fetchNotifications(isViewAll);
        message.success("Đánh dấu đã xem tất cả thông báo thành công!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAllNotifications = async () => {
    try {
      const params = { userId: dataUser.id };
      const res = await deleteNotification(params);
      if (res.code === 200 && res.error === 0) {
        message.success("Xóa tất cả thông báo đã xem thành công!");
        await fetchNotifications(isViewAll);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showConfirmModal = (onOkAction, content) => {
    Modal.confirm({
      title: "Xác nhận",
      content,
      okText: "Đồng ý",
      cancelText: "Hủy",
      onOk: onOkAction,
      cancelButtonProps: { type: "primary", danger: true },
    });
  };

  useEffect(() => {
    if (connectionSignalR?.connection) {
      const handler = () => {
        refreshNotification();
      };

      EVENT_NAMES.forEach((eventName) => {
        connectionSignalR.connection.on(eventName, handler);
      });

      return () => {
        EVENT_NAMES.forEach((eventName) => {
          connectionSignalR.connection.off(eventName, handler);
        });
      };
    }
  }, [connectionSignalR]);

  const refreshNotification = () => {
    setPageIndex(1)
    fetchNotifications(isViewAll, false, 1);
  };

  const contentRender = () => (
    <div className="w-[420px] max-h-[600px] overflow-y-scroll custom-scroll-notify mb-4 pt-2">
      {loadingReload ? <Spin className="w-full my-10" size="large" /> :
        listNotify.length > 0 ? listNotify.map((item, idx) =>
          <NotifitcationItem
            key={idx}
            item={item}
            dataUser={dataUser}
            refreshNotification={refreshNotification}
            isViewAll={isViewAll}
          />
        ) : (
          <Flex className="h-[80%] w-full justify-center items-center flex-col">
            <SmileOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
            <p>Bạn không có thông báo mới nào. Hãy kiểm tra lại sau nhé!</p>
          </Flex>
        )
      }
    </div>
  );

  const handleClickDropdown = (e) => {
    if (e.key === '1') {
      showConfirmModal(markViewedAll, "Bạn có muốn đánh dấu tất cả thông báo là đã xem?");
    } else {
      showConfirmModal(deleteAllNotifications, "Bạn có muốn xóa tất cả thông báo đã xem?");
    }
  }

  const handleLoadMore = async () => {
    const nextPageIndex = pageIndex + 1;
    setLoadingMore(true);
    setPageIndex(nextPageIndex);
    await fetchNotifications(isViewAll, true, nextPageIndex);
    setLoadingMore(false);
  };

  const renderTabs = (
    <div className="relative bg-white pl-4 pr-0 shadow-2xl rounded-lg border border-solid border-gray-300">
      <Tabs
        defaultActiveKey="2"
        onChange={async (key) => {
          setLoadingReload(true);
          setIsViewAll(key === "1");
          setPageIndex(1);
          await fetchNotifications(key === "1", false, 1);
          setLoadingReload(false);
        }}
      >
        <Tabs.TabPane tab="Chưa xem" key="2">
          {contentRender()}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Xem tất cả" key="1">
          {contentRender()}
        </Tabs.TabPane>
      </Tabs>
      <div className="flex gap-4 absolute top-4 right-14 text-[#777]">
        {loadingReload ? <Spin className="flex items-center" size="small" /> : (
          <ReloadOutlined
            title="Tải lại thông báo"
            onClick={async () => {
              setLoadingReload(true);
              setPageIndex(1);
              await fetchNotifications(isViewAll, false, 1);
              setLoadingReload(false);
            }}
            className="cursor-pointer transition-transform duration-300 ease-in-out hover:scale-[1.2] hover:text-[#444]"
          />
        )}
      </div>
      <Dropdown
        placement="bottomRight"
        trigger={['click']}
        menu={{
          items: [
            {
              label: 'Đánh dấu đã xem tất cả',
              key: '1',
            },
            {
              label: 'Xóa tất cả thông báo đã xem',
              key: '2',
            },
          ],
          onClick: (e) => {handleClickDropdown(e)},
        }}
        className="absolute top-[6px] right-3"
      >
        <Button type="text" shape="circle">
          <span className="mb-1 font-bold text-[#555]">...</span>
        </Button>
      </Dropdown>
      <Flex className="mb-4 justify-center">
        {listNotify.length < totalItems && (loadingMore ? (
          <Spin />
        ) : (
          <a href onClick={handleLoadMore}>Xem thêm</a>
        ))}
      </Flex>
    </div>
  );

  return (
    <div className="relative top-1">
      <Dropdown
        overlay={renderTabs}
        trigger={['click']}
        placement="bottomRight"
        onOpenChange={(open) => setIsDropdownOpen(open)}
      >
        <Badge size="default" count={countUnRead} overflowCount={999} color="#38a7ff" className="cursor-pointer">
          <IoNotifications
            title="Thông báo"
            className={`text-[1.5rem] transition-transform duration-200 ease-in-out ${
              isDropdownOpen ? 'text-primary' : 'text-[#999]'
            }`}
          />
        </Badge>
      </Dropdown>
    </div>
  );
};

export default Notify;
