import { Button, Col, Form, InputNumber, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { updateTargetConfig } from "@/services/base";

const UpdateFigureForm = ({ stateModal, closeModal, reload, getTargetConfig }) => {
  const { isOpen, data } = stateModal;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }

    return () => {
      form.resetFields();
    };
  }, [isOpen, data, form]);

  const convertObjectToString = (obj) => {
    return Object.entries(obj)
    .map(([key, value]) => `${key}:${value}`)
    .join("|");
  };

  const onFinish = async (target) => {
    setLoading(true);
    const updatedTarget = {
      BettingRound: target.BettingRound,
      Deposit: target.Deposit,
      KPI: target.KPI,
      NSQC: target.NSQC,
    };

    if (!target.BettingRound && !target.Deposit && !target.KPI && !target.NSQC) return;

    const resultString = convertObjectToString(updatedTarget);
    const data = {
      codeAndValue: resultString,
    };

    try {
      const res = await updateTargetConfig(data);
      if (res?.code === 200 && res?.error === 0) {
        await getTargetConfig();
        reload();
        message.success("Cập nhật tiêu chí thành công!");
      } else {
        message.success("Cập nhật tiêu chí thất bại!");
      }
    } catch (error) {
      console.log(error);
    }
    closeModal();
    setLoading(false);
  };

  return (
    <Modal
      title="Cập nhật tiêu chí"
      open={isOpen}
      width="600px"
      onCancel={closeModal}
      footer={null}
    >
      <Form layout="vertical" onFinish={onFinish} form={form} initialValues={{isBlock: false}}>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item label="Ngân sách quảng cáo" name="NSQC">
              <InputNumber
                min={1}
                style={{ width: "100%" }}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="KPI" name="KPI">
              <InputNumber min={1} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item label="Vòng cược" name="BettingRound">
              <InputNumber min={1} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tổng nạp/Ngân sách" name="Deposit">
              <InputNumber min={1} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" gutter={[8, 8]}>
          <Button
            className="mr-2"
            onClick={() => closeModal()}
            type="primary"
            danger
            disabled={loading}
          >
            Hủy
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={loading}
          >
            Cập nhật
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default UpdateFigureForm;
