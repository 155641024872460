import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { DatePicker, message, Select } from "antd";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { allSites, infoUser } from "@/recoil/atoms";
import qs from "query-string";
import { formatCurrencyVND } from "@/utils/common";
import { LoadingOutlined } from "@ant-design/icons";
import { getSumaryTicketMonth } from "@/services/base";
import { getListAreas, getListTeams } from "@/utils/fetchData";
import { StatusResponse } from "@/utils/const";
import { handleClearLoadMore, handleLoadMore, handleSearchLoadMore, } from "@/utils/utils";

const initSeries = [];
const initLabels = [];
const initOptions = {
  labels: initLabels,
  colors: [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#A133FF",
    "#33FFA1",
    "#FF5733",
    "#FF33FF",
    "#33FFFF",
    "#A1FF33",
    "#5733FF",
    "#FFA133",
    "#33A1FF",
    "#FF3357",
    "#33FF33",
    "#3333FF",
    "#FF3333",
    "#33FFA1",
    "#A1FF33",
    "#3357FF",
    "#FFA1FF",
    "#57FF33",
    "#33FF57",
    "#5733A1",
    "#FF5733",
    "#33A1FF",
    "#FFA1A1",
    "#33FFA1",
    "#A1FF33",
    "#3357A1",
    "#FF5733",
    "#A1A1FF",
    "#FF33FF",
    "#33A1A1",
    "#5733FF",
    "#FF3357",
    "#33FF33",
    "#A1A1A1",
    "#FF33A1",
    "#33FFA1",
    "#A1FF33",
    "#5733A1",
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#A133FF",
    "#33FFA1",
    "#FF5733",
    "#FF33FF",
    "#33FFFF",
    "#A1FF33",
    "#5733FF",
    "#FFA133",
    "#33A1FF",
    "#FF3357",
    "#33FF33",
    "#3333FF",
    "#FF3333",
    "#33FFA1",
    "#A1FF33",
    "#3357FF",
    "#FFA1FF",
    "#57FF33",
    "#33FF57",
    "#5733A1",
    "#FF5733",
    "#33A1FF",
    "#FFA1A1",
    "#33FFA1",
    "#A1FF33",
    "#3357A1",
    "#FF5733",
    "#A1A1FF",
    "#FF33FF",
    "#33A1A1",
    "#5733FF",
    "#FF3357",
    "#33FF33",
    "#A1A1A1",
    "#FF33A1",
    "#33FFA1",
    "#A1FF33",
    "#5733A1",
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#A133FF",
    "#33FFA1",
    "#FF5733",
    "#FF33FF",
    "#33FFFF",
    "#A1FF33",
    "#5733FF",
  ],
  chart: {
    type: "donut",
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
  tooltip: {
    y: {
      formatter: function (val) {
        return formatCurrencyVND(val);
      },
      title: {
        formatter: function (seriesName) {
          return seriesName + ":";
        },
      },
    },
  },
};

const initParams = {
  textSearch: "",
  pageIndex: 1,
  pageSize: 20,
  total: 0,
};

const PieChart = ({
  isAdmin,
  visibleOptions,
  optionsSelectSite,
  optionsSelectArea,
  optionsSelectTeam,
  isTLBank
}) => {
  const sites = useRecoilValue(allSites);
  const defaultMonthYear = dayjs(new Date());
  const userInfor = useRecoilValue(infoUser);
  const isSupperSite = userInfor?.site?.isSupper;
  const [loading, setLoading] = useState(false);
  const [series, setSeries] = useState(initSeries);
  const [options, setOptions] = useState(initOptions);
  const [labels, setLabels] = useState(initLabels);

  const [areaParams, setAreaParams] = useState({
    ...initParams,
    siteId: isSupperSite ? null : userInfor?.siteId,
  });
  const [teamParams, setTeamParams] = useState({
    ...initParams,
    siteId: isSupperSite ? null : userInfor?.siteId,
    areaId: isSupperSite ? null : userInfor?.areaId,
  });
  const [areas, setAreas] = useState([]);
  const [teams, setTeams] = useState([]);
  const [searchParams, setSearchParams] = useState({
    siteId: isSupperSite ? null : userInfor?.siteId,
    areaId: isSupperSite || isTLBank ? null : userInfor?.areaId,
    teamId: isSupperSite ? null : userInfor?.teamId,
    month: dayjs(new Date()).format("YYYY-MM"),
  });
  const [title, setTitle] = useState("");

  useEffect(() => {
    getListAreas(areaParams, setAreas, setAreaParams);
  }, [areaParams.pageSize, areaParams.textSearch, areaParams.siteId]);

  useEffect(() => {
    getListTeams(teamParams, setTeams, setTeamParams);
  }, [teamParams.pageSize, teamParams.textSearch, teamParams.siteId, teamParams.areaId]);

  useEffect(() => {
    if (searchParams?.teamId) {
      setTitle("hậu đài");
    } else if (!searchParams?.teamId && searchParams?.areaId) {
      setTitle("tổ");
    } else if (!searchParams?.areaId && searchParams?.siteId) {
      setTitle("khu");
    } else {
      setTitle("trang");
    }

    getAnalytics();
  }, [searchParams]);

  useEffect(() => {
    setOptions({
      ...options,
      labels: labels,
    });
  }, [labels]);

  const getAnalytics = async () => {
    setLoading(true);

    try {
      const params = qs.stringify(searchParams);

      const res = await getSumaryTicketMonth(params);

      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }

      if (Array.isArray(res.data)) {
        const newLabels = [];
        const newSeries = [];
        res.data.map((item, index) => {
          const sum = item?.sum || 0;
          const localtionName = item?.localtionName || "";

          newLabels.push(localtionName);
          newSeries.push(sum);
        });

        setLabels(newLabels);
        setSeries(newSeries);
      }

      setLoading(false);
    } catch (error) {
      message.error(error);
      setLoading(false);
    }
  };

  const onChangeMonthYear = (value) => {
    if (value) {
      setSearchParams((prev) => ({
        ...prev,
        month: dayjs(value).format("YYYY-MM"),
      }));
    }
  };

  const handleSiteChange = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      siteId: value,
    }));

    setAreaParams((prev) => ({
      ...prev,
      siteId: value,
    }));

    setTeamParams((prev) => ({
      ...prev,
      siteId: value,
    }));
  };

  const handleAreChange = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      areaId: value,
    }));

    setTeamParams((prev) => ({
      ...prev,
      areaId: value,
    }));
  };

  const handleTeamChange = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      teamId: value,
    }));
  };

  const filterOption = useCallback(
    (input, option) =>
      option?.label?.toLowerCase().includes(input.toLowerCase()),
    []
  );

  const areaOptions = useMemo(
    () =>
      areas?.map(({ id, areaName }) => ({
        value: id,
        label: areaName,
      })),
    [areas]
  );

  const teamOptions = useMemo(
    () =>
      teams?.map(({ id, teamName }) => ({
        value: id,
        label: teamName,
      })),
    [teams]
  );

  return (
    <div>
      <h4 className="mb-7 mt-0">
        Thống kê số tiền ngân sách đã duyệt theo {title}
      </h4>
      <div className="flex gap-3 mb-3">
        <DatePicker
          picker="month"
          placeholder="Chọn tháng"
          allowClear={false}
          defaultValue={defaultMonthYear}
          format={"MM/YYYY"}
          onChange={onChangeMonthYear}
        />
        {visibleOptions?.isShowSite && (
          <Select
            {...optionsSelectSite}
            allowClear
            className="w-[120px]"
            showSearch
            placeholder="Chọn trang"
            onChange={handleSiteChange}
            filterOption={filterOption}
            options={sites?.map(({ id, siteName }) => ({
              value: id,
              label: siteName,
            }))}
          />
        )}

        {visibleOptions?.isShowArea && (
          <Select
            {...optionsSelectArea}
            allowClear
            className="w-[120px]"
            showSearch
            placeholder="Chọn khu"
            onChange={handleAreChange}
            onSearch={(e) => handleSearchLoadMore(e, setAreaParams)}
            onClear={handleClearLoadMore(setAreaParams)}
            filterOption={filterOption}
            options={areaOptions}
            onPopupScroll={handleLoadMore(areaParams, setAreaParams)}
          />
        )}

        {visibleOptions?.isShowTeam && (
          <Select
            {...optionsSelectTeam}
            allowClear
            className="w-[120px]"
            showSearch
            placeholder="Chọn tổ"
            onChange={handleTeamChange}
            onSearch={(e) => handleSearchLoadMore(e, setTeamParams)}
            onClear={handleClearLoadMore(setTeamParams)}
            filterOption={filterOption}
            options={teamOptions}
            onPopupScroll={handleLoadMore(teamParams, setTeamParams)}
          />
        )}
        {loading && (
          <LoadingOutlined
            style={{
              fontSize: 28,
              color: "#38a7ff",
              fontWeight: "bold",
            }}
          />
        )}
      </div>
      <div className="relative">
        {loading && (
          <div className="absolute bg-white opacity-25 z-[99999] w-full h-full"></div>
        )}
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          height={350}
          isAdmin={isAdmin}
        />
      </div>
    </div>
  );
};

export default PieChart;
