import React, { useState } from "react";
import {
  Button,
  Col,
  Flex,
  Image,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import { disableButtonsProcess, getFileExtension } from "@/utils/utils";
import { EXPENSE_TYPE_CODES } from "@/utils/const";
import { PictureOutlined, MinusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import { deleteFile } from "@/services/base";

import ImageCarousel from "@/components/carousel/ImageCarousel";
import ImagePreviewGroup from "@/components/carousel/ImagePreviewGroup";
import TextLabel from "@/common/TextLabel";
import { IoMdCloseCircleOutline } from "react-icons/io";

const { Text } = Typography;

const AttachFiles = ({ dataDetail, handleReload }) => {
  const { processStatus: { statusCode } = {} } = dataDetail || {};
  const [isOpenFileModal, setIsOpenFileModal] = useState(false);
  const [selectedIndexFile, setSelectedIndexFile] = useState(false);
  const [listCarousel, setListCarousel] = useState([]);
  const infoState = useRecoilValue(infoUser);
  const hasPermisionInStep = !disableButtonsProcess(infoState, dataDetail);
  const isFinish = ["Done", "Close", "Reject"].includes(statusCode);
  const isAllowDeleteImage = hasPermisionInStep && !isFinish;
  const expenseTypeCode = dataDetail?.expenseType?.code;
  const expenseAttachFiles = dataDetail?.expenseAttachFiles?.filter(
    (file) => !file?.isVoice
  );
  const attFiles = dataDetail?.attFiles?.filter((file) => !file?.isVoice);
  const isShowAllImageButton =
    expenseTypeCode == EXPENSE_TYPE_CODES.Proposal &&
    expenseAttachFiles?.length > 0;
  const [currentPreview, setCurrentPreview] = useState(1);
  const [previewOpen, setPreviewOpen] = useState(false);

  const renderListFiles = (listFiles, isShowRemoveFile) => {
    return (
      <div className="flex mt-4 gap-4 flex-wrap">
        {listFiles?.map((item, index) => {
          const fileExtension = getFileExtension(item?.filePath);
          const isVideo = ["mp4", "webm", "ogg", "wmv"].includes(fileExtension);
          if (item.isVoice === false) {
            const fileExtension = getFileExtension(item?.filePath);
            return (
              <Flex className="flex-col relative" key={index}>
                {isAllowDeleteImage && isShowRemoveFile && (
                  <Button
                    type="link"
                    className="absolute px-1 -top-5 -right-2 z-10 text-red-600 text-lg"
                    onClick={() => confirmDeleteFile(item, isVideo)}
                  >
                    <MinusCircleOutlined />
                  </Button>
                )}
                <div
                  className="w-[180px] h-[140px] overflow-hidden relative image-border flex items-center justify-center"
                  onClick={() => {
                    const listImages = listFiles?.filter((e) => !e?.isVoice);
                    const indexFile = listImages?.findIndex(
                      (e) => e?.id === item?.id
                    );

                    setListCarousel(listImages);
                    setSelectedIndexFile(indexFile);
                    setCurrentPreview(indexFile);
                    setPreviewOpen(true);
                    // setIsOpenFileModal(true);
                  }}
                >
                  {isVideo ? (
                    <video
                      controls
                      className="absolute top-0 left-0 w-full h-full cursor-pointer"
                      autoPlay={false}
                      preload="metadata"
                      onClick={(event) => event.preventDefault()}
                    >
                      <source
                        src={`${process.env.REACT_APP_FILE}/${item?.filePath}`}
                        type={`video/${fileExtension}`}
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <Image
                      key={index}
                      src={`${process.env.REACT_APP_FILE}/${item?.filePath}`}
                      alt={item?.filePath}
                      className="w-full h-full object-cover cursor-pointer"
                      preview={false}
                    />
                  )}
                </div>
                <div className="italic mt-1 text-xs">
                  {moment(item?.creationDate + "z").format("DD/MM/YYYY HH:mm")}{" "}
                </div>
                <div
                  className="min-h-[1rem] mt-2 max-w-[180px] break-words"
                  dangerouslySetInnerHTML={{ __html: item?.description }}
                />
              </Flex>
            );
          }
        })}
      </div>
    );
  };
  const singleFileExpenseOutput = renderListFiles(dataDetail?.attFiles, true);

  const handleDeleteFile = async (keyId) => {
    try {
      const res = await deleteFile({
        keyId: keyId,
        expenseId: dataDetail?.id,
      });
      if (res.code === 200 && res.error === 0) {
        handleReload();
        message.success(res?.message);
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      message.error("Error: ", error);
    }
  };

  const confirmDeleteFile = (item, isVideo) => {
    Modal.confirm({
      title: "Xác nhận",
      content: `Bạn chắc chắn muốn xóa ${
        isVideo ? "video" : "hình"
      } này không?`,
      cancelText: "Hủy",
      onOk: async () => await handleDeleteFile(item?.id),

      cancelButtonProps: {
        type: "primary",
        danger: true,
      },
    });
  };

  const handleClickViewAllFile = () => {
    setSelectedIndexFile(0);
    setIsOpenFileModal(true);
    setListCarousel(expenseAttachFiles);
  };

  return (
    <div>
      {previewOpen && (
        <ImagePreviewGroup
          previewOpen={previewOpen}
          currentPreview={currentPreview}
          setPreviewOpen={setPreviewOpen}
          listFiles={attFiles}
        />
      )}

      {isOpenFileModal && (
        <ImageCarousel
          data={listCarousel?.filter((file) => !file?.isVoice)}
          viewCarousel={isOpenFileModal}
          selectedIndex={selectedIndexFile}
          setViewCarousel={(value) => setIsOpenFileModal(value)}
        />
      )}

      <Col span={24}>
        <Flex align="center" gap={"10px"}>
          <TextLabel>Hình ảnh: </TextLabel>
          {isShowAllImageButton && (
            <Button
              type="primary"
              icon={<PictureOutlined />}
              size="medium"
              onClick={() => handleClickViewAllFile()}
            />
          )}
        </Flex>
        {singleFileExpenseOutput}
      </Col>
      <Row className="mt-5">
        <TextLabel>Voice xác nhận của Tổ trưởng:</TextLabel>
      </Row>
      <div className="grid grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-6 overflow-hidden">
        {dataDetail?.attFiles?.map((item, index) => {
          if (item.isVoice === true) {
            const fileExtension = getFileExtension(item?.filePath);
            const isVideo = ["mp4", "webm", "ogg", "wmv"].includes(fileExtension);
            return (
              <div className="relative w-full">
                <p className="absolute top-0 right-0 z-40">
                <Button
                    type="link"
                    className="absolute px-1 -top-4 -right-2 z-10 text-red-600 text-lg"
                    onClick={() => confirmDeleteFile(item, isVideo)}
                  >
                    <MinusCircleOutlined />
                  </Button>
                </p>
                {fileExtension === "webm" && (
                  <video
                    key={index}
                    controls
                    className="h-[70px] w-full"
                    autoPlay={false}
                    preload="metadata"
                  >
                    <source
                      src={`${process.env.REACT_APP_FILE}/${item?.filePath}`}
                      type={`video/${fileExtension}`}
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default AttachFiles;
