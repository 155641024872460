import React from 'react';
import { Form, Select } from "antd";
import { processStatus } from "@/data/processStatus";

const ProcessStatusForm = () => {
  const listProcessStatus = processStatus.filter(process => ![1, 4].includes(process.id));

  return (
    <Form.Item name="statuses" className="!m-0">
      <Select
        mode="multiple"
        allowClear
        className="!w-[200px]"
        showSearch
        placeholder="Trạng thái..."
        filterOption={(input, option) =>
          (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
        }
      >
        {listProcessStatus.map((status) => (
          <Select.Option key={status.id} value={status.id}>
            {status.statusCode === "Done" ? "Hoàn thành" : status.statusName}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default ProcessStatusForm;