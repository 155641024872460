export const LocalStorageKey = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  SETTING_BASE: "setting_base",
  USER_INFO: "user_info",
  REF: "ref_code",
  END_POINT_PUSH_NOTI: "end_point_push_notification"
};

export const StatusResponse = {
  SUCCESS: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  REQUEST_TIMEOUT: 408,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
};

export const routes = {
  any: "*",
  home: "/home",
  login: "/login",
  users: "/users",
  accountInfo: "/users/account-info",
};

export const extractManyId = (str) => {
  const departmentRegex = /department_([a-z0-9]+)/;
  const siteRegex = /site_([a-z0-9]+)/;

  const departmentMatch = str?.match(departmentRegex);
  const siteMatch = str?.match(siteRegex);

  let department = null;
  let site = null;

  if (departmentMatch && departmentMatch[1]) {
    department = departmentMatch[1];
  }

  if (siteMatch && siteMatch[1]) {
    site = siteMatch[1];
  }

  return {
    department: department ? +department : department,
    site: site ? +site : site,
  };
};

export const convertIdforTreeSelect = (departmentId, siteId) => {
  let id;
  if (departmentId) {
    id = `site_${siteId}_department_${departmentId}`;
  } else {
    id = `site_${siteId}`;
  }
  return id;
};

export const ROLES = {
  MEMBER: "Member",
  TT: "TT",
  PCQ: "PCQ",
  TL: "TL",
  TLB: "TLB",
  HLV: "HLV",
  QL: "QL",
  ADMIN: "Admin",
};

export const DEPARTMENT_TYPES = {
  SALE: "Sale"
}

export const PROCESS_STEP = {
  init: "Init",
  verify: "Verify",
  approved_request: "ApprovedRequest",
  approve_budget: "ApproveBudget",
};

export const PROCESS_STATUS = {
  new: "New",
  inprogress: "Inprogress",
  pending: "Pending",
  aproved: "Aproved",
  close: "Close",
  reject: "Reject",
  done: "Done",
};

export const SITE_SUMMARY_STATUS = {
  Achieve: {
    text: "ĐẠT",
    color: "green",
  },
  NotAchieve: {
    text: "CHƯA ĐẠT",
    color: "red",
  },
  Available: {
    text: "CHƯA ĐẾN NGƯỠNG",
    color: "orange"
  },
  Unlimited: {
    text: "KHÔNG GIỚI HẠN",
    color: "purple"
  } 
};

export const ROLE_LEVER = {
  MEMBER: 0,
  TT: 1,
  PCQ: 6,
  TL: 2,
  TLB: 3,
  HLV: 4,
  QL: 5,
  ADMIN: null,
};

export const ROLE_ID = {
  MEMBER:7,
  TT:6,
  PCQ:  5,
  TL: 4,
  TLB: 2,
  HLV: 3,
  QL: 8,
  ADMIN: 1,
};


export let TYPE_INCREASE = "up";
export let TYPE_DECREASE = "down";

export const PROCESSING_EXPENSE_CODE = {
  Proposal: 1,
  IncreaseAdj: 2,
  DecreaseAdj: 3,
  Spending: 4,
  SpendingHistory: 5,
  RequestClose: 6,
};

export const DEPARTMENT__CODE = {
  ADS: "Ads",
  SALE: "Sale",
  TLHD: "TLHD",
  TLQC: "TLQC",
};
export const EXPENSE_TYPE_CODES = {
  Proposal: "Proposal",
  IncreaseAdj: "IncreaseAdj",
  DecreaseAdj: "DecreaseAdj",
  Spending: "Spending",
  SpendingHistory: "SpendingHistory",
  RequestClose: "RequestClose",
};

export const CHECK_PROCESSING_EXPENSE = (typeForm) => {
  switch (typeForm) {
    case EXPENSE_TYPE_CODES.Proposal:
      return 1;
    case EXPENSE_TYPE_CODES.IncreaseAdj:
      return 2;
    case EXPENSE_TYPE_CODES.DecreaseAdj:
      return 3;
    case EXPENSE_TYPE_CODES.Spending:
      return 4;
    case EXPENSE_TYPE_CODES.SpendingHistory:
      return 5;
    case EXPENSE_TYPE_CODES.RequestClose:
      return 6;
    default:
      return null; // or any default value you prefer
  }
};
export const CheckExpenseTypeCode=(typeForm)=>{
  switch (typeForm) {
    case EXPENSE_TYPE_CODES.Proposal:
      return "Proposal";
    case EXPENSE_TYPE_CODES.IncreaseAdj:
      return "IncreaseAdj";
    case EXPENSE_TYPE_CODES.DecreaseAdj:
      return "DecreaseAdj";
    case EXPENSE_TYPE_CODES.Spending:
      return "Spending";
    case EXPENSE_TYPE_CODES.SpendingHistory:
      return "SpendingHistory";
    case EXPENSE_TYPE_CODES.RequestClose:
      return "RequestClose";
    default:
      return null; // or any default value you prefer
  }
}