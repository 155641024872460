import React from "react";
import { formatCurrencyVND } from "@/utils/common";

const ContentToolbar = ({ selectedExpense }) => {
  return (
    <div className="py-2 px-2 bg-slate-500 rounded-md text-white">
      <fieldset className="rounded-md py-3">
        <legend className="text-left">Thông tin chuyển khoản</legend>
        <label for="fname" className="font-medium">
          <span>{formatCurrencyVND(selectedExpense?.totalAmount)}</span> -{" "}
          <span>{selectedExpense?.bankName}</span> -{" "}
          <span>{selectedExpense?.accountName}</span> -{" "}
          <span>{selectedExpense?.numberAccount}</span>
        </label>
      </fieldset>
    </div>
  );
};

export default ContentToolbar;
