import { useState } from 'react';
import { getListPartner, createPartner, updatePartner, deletePartner } from "@/services/base";

const usePartnerHook = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 20,
    total: 0,
  });

  const getPartnersPage = async (
    searchParams = { textSearch: '', pageIndex: 1, pageSize: 20 }
  ) => {
    setLoading(true);

    try {
      const { code, isError, data, pagination } = await getListPartner(searchParams) || {};

      if (code === 200 && !isError) {
        setPartners(data);
        setPagination(pagination);
        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;

    } finally {
      setLoading(false);
    }
  };

  const addPartner = async (newPartnerData) => {
    setLoading(true);

    try {
      const { code, data, message } = await createPartner(newPartnerData) || {};

      if (code === 200) {
        setPartners((prev) => [...prev, data]);
        return { success: true, message: message || 'Thêm đối tác thành công' };
      }

      return { success: false, message: message || "Failed to add partner" };

    } catch (error) {
      return { success: false, message: error.message || "Error adding partner" };

    } finally {
      setLoading(false);
    }
  };

  const modifyPartner = async (updatedPartnerData) => {
    setLoading(true);

    try {
      const { code, data, message } = await updatePartner(updatedPartnerData) || {};

      if (code === 200) {
        setPartners((prev) =>
          prev.map((partner) => (partner.id === updatedPartnerData.id ? data : partner))
        );
        return { success: true, message: message || 'Chỉnh sửa đối tác thành công' };
      }

      return { success: false, message: message || "Failed to update partner" };

    } catch (error) {
      return { success: false, message: error.message || "Error updating partner" };

    } finally {
      setLoading(false);
    }
  };

  const removePartner = async (id) => {
    try {
      const { code, message } = await deletePartner(id) || {};

      if (code === 200) {
        setPartners((prev) => prev.filter((partner) => partner.id !== id));
        return { success: true, message: message || "Xóa đối tác thành công" };
      }

      return { success: false, message: message || "Failed to remove partner" };

    } catch (error) {
      return { success: false, message: error.message || "Error removing partner" };
    }
  };

  return {
    partners,
    loading,
    pagination,
    getPartnersPage,
    addPartner,
    modifyPartner,
    removePartner,
  };
};

export default usePartnerHook;
