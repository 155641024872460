import React from 'react';
import { Col, Divider, Row } from "antd";
import { PROCESS_STATUS } from "@/utils/const";
import moment from "moment";

import TextLabel from "@/common/TextLabel";
import TextValue from "@/common/TextValue";

const HistoryProcess = ({ approvalHistory }) => {
  const formattedNote = (note) => note?.replace(/\n/g, '<br />');
  return (
    <div className="max-h-[300px] overflow-y-auto overflow-x-hidden custom-scroll-history">
      {approvalHistory?.map((item, idx) => (
        <Row key={idx} gutter={[16, 16]}>
          <Col span={8}>
            <TextLabel>
              {item.processStatus.statusCode ===
              PROCESS_STATUS.new
                ? "Tạo bởi:"
                : "Phê duyệt bởi:"}&nbsp;
            </TextLabel>
            <TextValue>{item?.approvedBy?.fullName}</TextValue>
          </Col>

          <Col span={8}>
            <TextLabel>Bước:&nbsp;</TextLabel>
            <TextValue>{item?.processStep?.stepName}</TextValue>
          </Col>

          <Col span={8}>
            <TextLabel>Trạng thái duyệt:&nbsp;</TextLabel>
            <TextValue>{item?.processStatus?.statusName}</TextValue>
          </Col>

          <Col span={8}>
            <TextLabel>Người được giao:&nbsp;</TextLabel>
            <TextValue>{item?.assignName}</TextValue>
          </Col>

          <Col span={8}>
            <TextLabel>Người phụ trách:&nbsp;</TextLabel>
            <TextValue>{item?.supervisorName ?? '--'}</TextValue>
          </Col>

          <Col span={8}>
            <TextLabel>
              {item.processStatus.statusCode === PROCESS_STATUS.new ? "Ngày Tạo:" : "Ngày phê duyệt:"}&nbsp;
            </TextLabel>
            <TextValue>
              {moment(item?.creationDate + "z").format(
                "DD/MM/YYYY HH:mm"
              )}
            </TextValue>
          </Col>

          {item?.note && (
            <Col span={8}>
              <TextLabel>Ghi chú: &nbsp;</TextLabel>
              <TextValue>
                <span dangerouslySetInnerHTML={{ __html: formattedNote(item?.note) }}/>
              </TextValue>
            </Col>
          )}
          {idx !== approvalHistory.length - 1 && <Divider className="m-0 mb-2" />}
        </Row>
      ))}
    </div>
  );
};

export default HistoryProcess;