import React from 'react';
import { Form, Select } from "antd";
import { useRecoilValue } from "recoil";
import { allPurposes } from "@/recoil/atoms";

const PurposesForm = () => {
  const purposes = useRecoilValue(allPurposes);

  return (
    <Form.Item name="purposeId" className="!m-0">
      <Select
        allowClear
        className="!w-[200px]"
        placeholder="Loại hình quảng cáo..."
      >
        {purposes?.map(rec => (
          <Select.Option
            key={rec.id}
            value={rec.id}
          >
            {rec.purposeName}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default PurposesForm;