import React, { useState, useEffect } from "react";
import { getFileExtension } from "@/utils/utils";

import { Image } from "antd";

const ImagePreviewGroup = ({
  contentToolbar,
  currentPreview,
  previewOpen,
  listFiles,
  setPreviewOpen,
}) => {
  const [current, setCurrent] = useState(1);
  const [showToolbar, setShowToolbar] = useState(true);

  useEffect(() => {
    setCurrent(currentPreview);
  }, [currentPreview]);

  return (
    <Image.PreviewGroup
      preview={{
        current: current,
        visible: previewOpen,
        onVisibleChange: setPreviewOpen,
        onChange: (number, prevCurrent) => {
          if (number < prevCurrent) {
            setCurrent((prev) => prev - 1);
          } else {
            setCurrent((prev) => prev + 1);
          }
        },
        toolbarRender: (toolbar) => {
          return (
            <div className="text-center">
              {contentToolbar && contentToolbar}
              {showToolbar ? toolbar : null}
            </div>
          );
        },
        imageRender: (img) => {
          const fileExtension = getFileExtension(img?.props?.src);
          const isVideo = ["mp4", "webm", "ogg", "wmv"].includes(fileExtension);

          if (isVideo) {
            setShowToolbar(false);
            return (
              <video
                muted
                width="80%"
                height="80%"
                controls
                src={img.props.src}
              />
            );
          }

          setShowToolbar(true);

          return <>{img}</>;
        },
      }}
      items={listFiles?.map((item, index) => {
        return {
          src: `${process.env.REACT_APP_FILE}/${item?.filePath}`,
          alt: `Thumbnail ${index}`,
          key: item?.id,
        };
      })}
    />
  );
};

export default ImagePreviewGroup;
