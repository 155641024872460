import React, { useState, useEffect } from "react";
import {
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Button,
} from "antd";
import { copyProcessType } from "@/services/base";

const FormCopyProcessType = ({
  modalState,
  handleClose,
  reload,
  processTypeId,
  purposeId,
  listExpenseType,
  listPurpose,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      processTypeSourceId: processTypeId,
      processTypeNameTarget: "",
      processPriority: 0,
    });

    return () => {
      form.resetFields();
    };
  }, [processTypeId, purposeId, modalState, form]);

  const onFinish = async (value) => {
    setLoading(true);
    const res = await copyProcessType(value);

    if (res.code !== 200) {
      return message.error(res.message);
    }

    message.success(res.message);
    handleClose();
    reload();
    setLoading(false);
  };

  return (
    <Modal
      title="Sao chép quy trình"
      open={modalState.isOpen}
      width="600px"
      onCancel={handleClose}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="purposeId" hidden />
        <Form.Item name="processTypeSourceId" hidden>
          <Input />
        </Form.Item>
        <Row className="mt-4">
          <Col span={24}>
            <Form.Item
              label="Tên quy trình"
              name="processTypeNameTarget"
              rules={[
                { required: true, message: "Vui lòng nhập tên quy trình!" },
              ]}
            >
              <Input placeholder="Nhập tên quy trình..." />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Loại hình quảng cáo"
              name="purposeId"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn loại hình quảng cáo!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Chọn loại hình quảng cáo"
                filterOption={(input, option) =>
                  option?.label.toLowerCase().includes(input.toLowerCase())
                }
                options={(listPurpose || []).map((purpose) => ({
                  value: purpose.id,
                  label: purpose.purposeName,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Loại phiếu"
              name="expenseTypeId"
              rules={[{ required: true, message: "Vui lòng chọn loại phiếu!" }]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Chọn loại phiếu"
                filterOption={(input, option) =>
                  option?.label.toLowerCase().includes(input.toLowerCase())
                }
                options={(listExpenseType || []).map((expenseType) => ({
                  value: expenseType.id,
                  label: expenseType.name,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              label="Độ ưu tiên quy trình"
              name="processPriority"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập độ ưu tiên quy trình!",
                },
                {
                  validator: (_, value) =>
                    value < 0
                      ? Promise.reject(
                          new Error("Độ ưu tiên quy trình không thể là số âm!")
                        )
                      : Promise.resolve(),
                },
              ]}
            >
              <InputNumber
                className="w-full"
                placeholder="Nhập độ ưu tiên quy trình..."
                defaultValue={0}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" className="flex gap-4">
          <Button type="primary" danger onClick={handleClose}>
            Hủy bỏ
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            Sao chép
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default FormCopyProcessType;
