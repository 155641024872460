import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getCloseRequestTicket } from "@/services/base";
import { StatusResponse, ROLES, PROCESS_STATUS } from "@/utils/const";
import { Flex, message, Tag, Tooltip, Button, Dropdown } from "antd";
import DataTable from "@/common/DataTable";
import { infoUser } from "@/recoil/atoms";
import FormSearch from "@/common/FormSearch";
import ExportExpense from "@/common/ExportExpense";
import ExpenseLink from "@/common/ExpenseLink";
import CountData from "@/common/CountData";
import CategoryTag from "@/common/CategoryTag";
import { formatCurrencyVND } from "@/utils/common";
import { QuestionCircleOutlined, DownOutlined } from "@ant-design/icons";
import { MdOutlineNoteAlt } from "react-icons/md";
import ContentToolbar from "@/common/ContentToolbar";
import NoteBill from "@/common/NoteBill";
import ImagePreviewGroup from "@/components/carousel/ImagePreviewGroup";
import moment from "moment/moment";

const CloseRequest = () => {
  document.title = "Quản lý yêu cầu kết toán";
  const [closeReqTicket, setCloseReqTicket] = useState([]);
  const [loading, setLoading] = useState(false);
  const infoUserState = useRecoilValue(infoUser);
  const [params, setParams] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [currentPreview, setCurrentPreview] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [listImages, setListImages] = useState([]);
  
  const roleCode = infoUserState?.role?.roleCode;
  const isAdmin = roleCode === ROLES.ADMIN;
  const isTLBank = roleCode === ROLES.TLB;
  const isAllowNoteBill = isAdmin || isTLBank;

  const columns = [
    {
      key: "id",
      title: "Mã KT",
      dataIndex: "id",
      fixed: 'left',
      align: 'center',
      onCell: (record) => {
        if (record?.isNote && isAllowNoteBill) {
          return {
            style: { backgroundColor: '#f6ffed' }
          }
        }
      },
      render: (id, record) =>
        record.isRequestClose ? (
          "--"
        ) : (
          <ExpenseLink
            id={id}
            page={record.expenseType.shortName}
          >{`${record.expenseType.shortName}${id}`}</ExpenseLink>
        ),
    },
    {
      key: "refId",
      title: "Mã NS",
      dataIndex: "refId",
      align: 'center',
      render: (redId, record) => (
        <ExpenseLink
          id={redId || record.id}
          page="NS"
        >{`NS${redId || record.id}`}</ExpenseLink>
      ),
    },
    {
      title: "Loại hình",
      dataIndex: "purpose",
      width: 120,
      key: "purpose",
      align: 'center',
      render: (value) => (value?.purposeName ? <CategoryTag text={value?.purposeName} /> : "--"),
    },
    {
      title: "Hình ảnh",
      dataIndex: "images",
      key: "images",
      align: "center",
      render: (value, rec) => {
        return value?.length > 0 ? (
          <Button
            type="link"
            className="p-0"
            onClick={() => {
              const images = rec?.images || [];
              setListImages(images);
              setCurrentPreview(images?.length > 0 ? images?.length - 1 : 0);
              setPreviewOpen(true);
              setSelectedExpense(rec);
            }}
          >
            {value?.length} hình ảnh
          </Button>
        ) : (
          <div className="w-[50px] text-center">--</div>
        );
      },
    },
    {
      title: <span>Số tiền <Tooltip title="Số tiền refund NS"><QuestionCircleOutlined /></Tooltip></span>,
      dataIndex: "totalAmount",
      width: 130,
      key: "totalAmount",
      align: 'center',
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng ngân sách",
      dataIndex: "calculateAmount",
      width: 130,
      key: "calculateAmount",
      align: 'center',
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng chi tiêu",
      dataIndex: "calculateAmountInUse",
      width: 130,
      key: "calculateAmountInUse",
      align: "center",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Nhóm HD",
      dataIndex: "group",
      key: "group",
      align: 'center',
      render: (value, record) => (value ? `${value.groupName} - ${record?.groupInfo?.groupInfoCode}` : "--"),
    },
    {
      title: "QUY TRÌNH",
      children: [
        {
          title: "Tên quy trình duyệt",
          dataIndex: "processType",
          key: "processType",
          align: 'center',
          render: (value) => (value?.name ? value?.name : "--"),
        },
        {
          title: "Bước xử lý",
          dataIndex: "processStep",
          key: "processStep",
          align: 'center',
          render: (value, rec) => {
            const isProcessingCurrentUser =
              infoUserState?.departmentId == value?.departmentId;
            const stepName = value?.stepName ? value?.stepName : "--";
            const allowStatus = ["Inprogress", "Pending"].includes(rec?.processStatus?.statusCode)
            return isProcessingCurrentUser && allowStatus && value?.stepName ? (
              <Tag color="blue">{stepName}</Tag>
            ) : (
              stepName
            );
          },
        },
      ],
    },
    {
      title: "Số tài khoản",
      dataIndex: "",
      key: "bankAccount",
      align: 'center',
      render: (value) =>
        value?.numberAccount
          ? `${value.numberAccount} - ${value.bankName}`
          : "--",
    },
    {
      title: "Ngày tạo",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 130,
      align: 'center',
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
    {
      title: "Ngày chỉnh sửa",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      align: 'center',
      width: 130,
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
    {
      title: "Trạng thái",
      dataIndex: "processStatus",
      key: "processStatus",
      align: 'center',
      fixed: "right",
      onCell: (record) => {
        if (record?.isNote && isAllowNoteBill) {
          return {
            style: { backgroundColor: '#f6ffed' }
          }
        }
      },
      render: ({ statusCode, statusName }, { processingUser, isNote }) => {
        const fullNameProcessingUser = processingUser?.fullName;
        const textProcessingUser = statusCode == "Inprogress" && fullNameProcessingUser ? `${fullNameProcessingUser} - ` : "";
        
        const colorMap = {
          Done: "green",
          Inprogress: "blue",
          Reject: "red",
          Pending: "yellow",
          default: "default"
        };

        const color = colorMap[statusCode] || colorMap.default;

        return (
          <Tag className="m-0" color={color}>
            {textProcessingUser}
            {statusName}
            {isNote && (
              <span className="ml-1 align-middle">
                <MdOutlineNoteAlt />
              </span>
            )}
          </Tag>
        );
      },
    },
  ];

  const ctaItems = [
    {
      key: "1",
      label: (
        <ExportExpense expenseCode="RequestClose" params={params} />
      ),
    },
    {
      key: "2",
      label: <NoteBill selectedRowKeys={selectedRowKeys} reloadData={() => fetchCloseRequestTicket(params)} />,
      disabled: selectedRowKeys?.length == 0
    },
  ];

  const fetchCloseRequestTicket = async (value) => {
    setLoading(true);
    try {
      const res = await getCloseRequestTicket(value);
      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }
      setTotalPages(res?.pagination?.total);
      const dataWithKey = res?.data
        ? res?.data.map((i) => {
            return { ...i, key: i.id };
          })
        : [];
      setCloseReqTicket(dataWithKey);
      setSelectedRowKeys([]);
    } catch (error) {
      console.log("Error Fetching Close Request Ticket", error);
      message.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCloseRequestTicket(params);
  }, [params]);

  const handleOnchangeSearch = useCallback(
    (searchFields) => {
      const newParams = { ...params, ...searchFields };
      setParams(newParams);
    },
    [params]
  );

  const handleOnchangePage = (pageIndex, pageSize) => {
    setParams((prev) => ({ ...prev, pageIndex, pageSize }));
  };

  return (
    <>
      <section className="flex items-end justify-between mb-4">
        <FormSearch
          setSearchParam={setParams}
          onchangeSearch={handleOnchangeSearch}
          storageKey="closeRequestFilter"
        />
      </section>
      <Flex justify="space-between">
        <CountData loading={loading} total={totalPages} text="kết toán" />
        <Dropdown
          menu={{
            items: ctaItems,
          }}
        >
          <Button
            type="primary"
            className="bg-gradient-primary hover:!bg-gradient-secondary active:!bg-gradient-secondary border-l-0"
            onClick={(e) => e.preventDefault()}
          >
            Chức năng
            <DownOutlined />
          </Button>
        </Dropdown>
      </Flex>
      <DataTable
        className="mt-2"
        columns={columns}
        dataSource={closeReqTicket}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        scroll={{ x: "max-content", y: 560 }}
        totalPage={totalPages}
        loading={loading}
        isBorder={true}
        onPageChange={handleOnchangePage}
        selectedRowKeys={isAllowNoteBill ? selectedRowKeys : false}
        setSelectedRowKeys={setSelectedRowKeys}
        disabledSelectionRow={(record) => {
          const statusCode = record?.processStatus?.statusCode;
          const isDoneStatus = statusCode !== PROCESS_STATUS.done;
          
          return record?.isNote || isDoneStatus;
        }}
        rowClassName={(record) => {
          if (record?.isNote && isAllowNoteBill) {
            return 'bg-[#f6ffed]'
          }
        }}
      />

      {previewOpen && (
        <ImagePreviewGroup
          contentToolbar={<ContentToolbar selectedExpense={selectedExpense} />}
          selectedExpense={selectedExpense}
          previewOpen={previewOpen}
          currentPreview={currentPreview}
          setPreviewOpen={setPreviewOpen}
          listFiles={listImages}
        />
      )}
    </>
  );
};

export default CloseRequest;
