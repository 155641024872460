import React from "react";
import { Form, Flex, Tooltip, Typography } from "antd";
import { formatCurrencyVND } from "@/utils/common";
import { QuestionCircleOutlined } from "@ant-design/icons";

const TooltipContent = ({ calculateAmountInUse, calculateAmount }) => (
  <>
    Số tiền NS đã sử dụng: {formatCurrencyVND(calculateAmountInUse || 0)}
    <br />
    Số tiền NS đã được duyệt: {formatCurrencyVND(calculateAmount || 0)}
  </>
);

const formValidation = (dataDetail, currentTotalAmount) => {
  const validateTotalAmountRule = (_, value) => {
    const calculateAmount = dataDetail?.calAmountExpenseRequest?.calculateAmount;

    if (calculateAmount && currentTotalAmount > calculateAmount) {
      return Promise.reject(
        new Error(
          `Tổng chi tiêu không được vượt quá ngân sách phê duyệt ${formatCurrencyVND(
            calculateAmount
          )}`
        )
      );
    }

    return Promise.resolve();
  };

  return { validateTotalAmountRule };
};

const TotalAmountReportForm = ({ dataDetail, totalAmount }) => {
  const calculateAmountInUse = dataDetail?.calculateAmountInUse;
  const currentTotalAmount = totalAmount + calculateAmountInUse;
  const { validateTotalAmountRule } = formValidation(dataDetail, currentTotalAmount);

  return (
    <>
      <Flex gap={50}>
        <Form.Item label="Chi tiêu">
          <Typography.Text>
            <b>{formatCurrencyVND(totalAmount)}</b>
          </Typography.Text>
        </Form.Item>
        <Form.Item
          name="totalAmount"
          label={
            <>
              Tổng chi tiêu
              <Tooltip
                overlayClassName="!min-w-[320px]"
                title={
                  <TooltipContent {...dataDetail?.calAmountExpenseRequest} />
                }
              >
                <QuestionCircleOutlined className="ml-2" />
              </Tooltip>
            </>
          }
          rules={[{ validator: validateTotalAmountRule }]}
        >
          <Typography.Text>
            <b>
              {formatCurrencyVND(currentTotalAmount)}
            </b>
          </Typography.Text>
        </Form.Item>
      </Flex>
    </>
  );
};

export default TotalAmountReportForm;
