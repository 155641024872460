import TagStatus from "@/components/listRequset/TagStatus";
import { formatCurrencyVND } from "@/utils/common";
import { Flex, Tooltip, Dropdown, Button } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState, } from "react";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import { getListDecreaseRequestAdj, } from "@/services/base";
import { ROLES, PROCESS_STATUS } from "@/utils/const";
import DataTable from "@/common/DataTable";
import ExpenseLink from "@/common/ExpenseLink";
import FormSearch from "@/common/FormSearch";
import ExportExpense from "@/common/ExportExpense";
import CountData from "@/common/CountData";
import CategoryTag from "@/common/CategoryTag";
import ContentToolbar from "@/common/ContentToolbar";
import NoteBill from "@/common/NoteBill";
import ImagePreviewGroup from "@/components/carousel/ImagePreviewGroup";
import { QuestionCircleOutlined, DownOutlined } from "@ant-design/icons";

const DecreaseRequest = () => {
  document.title = "Quản lý giảm ngân sách";
  const infoUserState = useRecoilValue(infoUser);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [searchParams, setSearchParam] = useState({
    textSearch: "",
    expenseId: id,
    pageIndex: 1,
    pageSize: 10,
  });
  const [totalPage, setTotalPage] = useState(0);
  const [dataRequestAdj, setDataRequestAdj] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [currentPreview, setCurrentPreview] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [listImages, setListImages] = useState([]);
  
  const roleCode = infoUserState?.role?.roleCode;
  const isAdmin = roleCode === ROLES.ADMIN;
  const isTLBank = roleCode === ROLES.TLB;
  const isAllowNoteBill = isAdmin || isTLBank;

  const fetchDataListExpenseAdj = async (params) => {
    try {
      setLoading(true);
      const res = await getListDecreaseRequestAdj(params);
      if (res.code === 200 && res.error === 0) {
        setDataRequestAdj(res.data.map((u) => ({ ...u, key: u.id })));
        setTotalPage(res?.pagination?.total);
        setSelectedRowKeys([]);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const renderStatusCol = (value) => {
    return <TagStatus className="m-0" record={value}/>;
  };

  const columns = [
    {
      title: "Mã phiếu",
      dataIndex: "id",
      key: "id",
      width: 85,
      fixed: 'left',
      align: 'center',
      render: (id, record) => (
        <ExpenseLink id={id} page="DXG">{`${record.expenseType.shortName}${id}`}</ExpenseLink>
      ),
      onCell: (record) => {
        if (record?.isNote && isAllowNoteBill) {
          return {
            style: { backgroundColor: '#f6ffed' }
          }
        }
      },
    },
    {
      key: "refId",
      title: "Mã NS",
      dataIndex: "refId",
      width: 85,
      align: 'center',
      render: (redId) => (
        <ExpenseLink id={redId} page="NS">{`NS${redId}`}</ExpenseLink>
      ),
    },
    {
      title: "Loại hình",
      dataIndex: "purpose",
      width: 120,
      key: "purpose",
      align: 'center',
      render: (value) => (value?.purposeName ? <CategoryTag text={value?.purposeName} /> : "--"),
    },
    {
      title: "Hình ảnh",
      dataIndex: "images",
      key: "images",
      align: "center",
      render: (value, rec) => {
        return value?.length > 0 ? (
          <Button
            type="link"
            className="p-0"
            onClick={() => {
              const images = rec?.images || [];
              setListImages(images);
              setCurrentPreview(images?.length > 0 ? images?.length - 1 : 0);
              setPreviewOpen(true);
              setSelectedExpense(rec);
            }}
          >
            {value?.length} hình ảnh
          </Button>
        ) : (
          <div className="w-[50px] text-center">--</div>
        );
      },
    },
    {
      title: <span>Số tiền <Tooltip title="Số tiền giảm ngân sách"><QuestionCircleOutlined /></Tooltip></span>,
      dataIndex: "totalAmount",
      width: 130,
      key: "totalAmount",
      align: 'center',
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng ngân sách",
      dataIndex: "calculateAmount",
      width: 130,
      key: "calculateAmount",
      align: 'center',
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng chi tiêu",
      dataIndex: "calculateAmountInUse",
      width: 130,
      key: "calculateAmountInUse",
      align: "center",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Nhóm HD",
      dataIndex: "group",
      key: "group",
      align: 'center',
      render: (value, record) => (value ? `${value.groupName} - ${record?.groupInfo?.groupInfoCode}` : "--"),
    },
    {
      title: <span>Thời gian <Tooltip title="Thời gian bắt đầu sử dụng ngân sách"><QuestionCircleOutlined /></Tooltip></span>,
      dataIndex: "startDate",
      key: "startDate",
      align: 'center',
      width: 110,
      render: (value) => moment(value + "z").format("DD/MM/YYYY"),
    },
    {
      title: "QUY TRÌNH",
      children: [
        {
          title: "Tên quy trình duyệt",
          dataIndex: "processType",
          key: "processType",
          align: 'center',
          render: (value) => (value?.name ? value?.name : "--"),
        },
        {
          title: "Bước xử lý",
          dataIndex: "processStep",
          key: "processStep",
          align: 'center',
          render: (value) => (value?.stepName ? value?.stepName : "--"),
        },
      ]
    },
    {
      title: "Người tạo",
      dataIndex: "userCreate",
      key: "userCreate",
      align: 'center',
      render: (value) => (value ? value.fullName : "--"),
    },
    {
      title: "Người duyệt",
      dataIndex: "userApprove",
      key: "userApprove",
      align: 'center',
      render: (value) => {
        return <>{value?.fullName ? value?.fullName : "--"}</>;
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "creationDate",
      key: "creationDate",
      align: 'center',
      width: 130,
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
    {
      title: "Ngày chỉnh sửa",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      align: 'center',
      width: 130,
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
    {
      title: "Trạng thái",
      dataIndex: "processStatus",
      key: "processStatus",
      align: 'center',
      fixed: "right",
      render: (_, rec) => renderStatusCol(rec),
      onCell: (record) => {
        if (record?.isNote && isAllowNoteBill) {
          return {
            style: { backgroundColor: '#f6ffed' }
          }
        }
      },
    },
  ];

  const ctaItems = [
    {
      key: "1",
      label: <ExportExpense expenseCode="DecreaseAdj" params={searchParams}/>,
    },
    {
      key: "2",
      label: <NoteBill selectedRowKeys={selectedRowKeys} reloadData={() => fetchDataListExpenseAdj(searchParams)} />,
      disabled: selectedRowKeys?.length == 0
    },
  ];

  const onchangePage = (pageIndex, pageSize) => {
    setSearchParam((prevParams) => ({
      ...prevParams,
      pageIndex: pageIndex,
      pageSize: pageSize,
    }));
    fetchDataListExpenseAdj({
      pageIndex,
      pageSize,
      expenseId: searchParams.expenseId,
      textSearch: searchParams.textSearch,
    });
  };

  useEffect(() => {
    fetchDataListExpenseAdj(searchParams);
  }, [searchParams]);

  const handlechangeSearch = useCallback(
    (searchFields) => {
      const newParams = { ...searchParams, ...searchFields, pageIndex: 1 };

      setSearchParam(newParams);
    },
    [searchParams]
  );

  return (
    <>
      <section className="flex justify-between mb-4">
        <FormSearch
          onchangeSearch={handlechangeSearch}
          setSearchParam={setSearchParam}
          storageKey="decreaseFilter"
        />
      </section>
      <Flex justify="space-between">
        <CountData loading={loading} total={totalPage} text="đề xuất giảm"/>
        <Dropdown
          menu={{
            items: ctaItems,
          }}
        >
          <Button
            type="primary"
            className="bg-gradient-primary hover:!bg-gradient-secondary active:!bg-gradient-secondary border-l-0"
            onClick={(e) => e.preventDefault()}
          >
            Chức năng
            <DownOutlined />
          </Button>
        </Dropdown>
      </Flex>
      <DataTable
        className="mt-2"
        columns={columns}
        dataSource={dataRequestAdj || []}
        currentPage={searchParams.pageIndex}
        pageSize={searchParams.pageSize}
        totalPage={+totalPage}
        loading={loading}
        scroll={{ x: "max-content", y: 500 }}
        isBorder
        onPageChange={onchangePage}
        selectedRowKeys={isAllowNoteBill ? selectedRowKeys : false}
        setSelectedRowKeys={setSelectedRowKeys}
        disabledSelectionRow={(record) => {
          const statusCode = record?.processStatus?.statusCode;
          const isDoneStatus = statusCode !== PROCESS_STATUS.done;
          
          return record?.isNote || isDoneStatus;
        }}
        rowClassName={(record) => {
          if (record?.isNote && isAllowNoteBill) {
            return 'bg-[#f6ffed]'
          }
        }}
      />

      {previewOpen && (
        <ImagePreviewGroup
          contentToolbar={<ContentToolbar selectedExpense={selectedExpense} />}
          selectedExpense={selectedExpense}
          previewOpen={previewOpen}
          currentPreview={currentPreview}
          setPreviewOpen={setPreviewOpen}
          listFiles={listImages}
        />
      )}
    </>
  );
};

export default DecreaseRequest;
