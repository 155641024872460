import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, Pagination, Col, Row, Checkbox, Spin } from "antd";

const DataTable = ({
  dataSource,
  pageSize,
  columns,
  currentPage,
  totalPage,
  onPageChange,
  loading,
  selectedRowKeys,
  setSelectedRowKeys,
  disabledSelectionRow,
  className,
  rowClassName,
  isBorder,
  ...props
}) => {
  const [pageSizeOptions, setPageSizeOptions] = useState([10, 20, 50, 100]);
  const [showSizeChanger, setShowSizeChanger] = useState(true);

  useEffect(() => {
    if (totalPage >= 100) setPageSizeOptions([10, 20, 50, 100]);
    if (totalPage < 100) setPageSizeOptions([10, 20, 50, 100]);
    if (totalPage < 50) setPageSizeOptions([10, 20, 50]);
    if (totalPage < 20) setPageSizeOptions([10, 20]);
    if (totalPage < 10) setPageSizeOptions([10]);
    // eslint-disable-next-line
  }, [dataSource]);

  const handleSelect = (record, selected) => {
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.key]);
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record.key);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
    }
  };
  const toggleSelectAll = () => {
    setSelectedRowKeys((keys) =>
      keys.length === dataSource?.length ? [] : dataSource.map((r) => r.key)
    );
  };

  const headerCheckbox = selectedRowKeys ? (
    <Checkbox
      checked={selectedRowKeys?.length}
      indeterminate={
        selectedRowKeys?.length > 0 &&
        selectedRowKeys?.length < dataSource?.length
      }
      onChange={toggleSelectAll}
    />
  ) : null;

  const rowSelection = {
    selectedRowKeys,
    type: "checkbox",
    fixed: true,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,
    getCheckboxProps: (record) => ({
      disabled: disabledSelectionRow ?  disabledSelectionRow(record) : false,
    }),
  };

  return (
    <div className={className}>
      <div>
        <Table
          columns={columns}
          dataSource={loading ? [] : dataSource}
          pagination={false}
          rowSelection={selectedRowKeys ? rowSelection : null}
          loading={loading}
          scroll={{ x: 1400, ...props.scroll }}
          bordered={isBorder}
          rowClassName={rowClassName ? (record) => rowClassName(record) : null}
        />
        {totalPage > pageSize && (
          <Row gutter={10} className="mt-3">
            <Col span={24} className="mt-1 text-center">
              <Pagination
                className="flex justify-center"
                showSizeChanger={showSizeChanger}
                pageSizeOptions={pageSizeOptions}
                current={currentPage}
                total={totalPage}
                pageSize={pageSize}
                onChange={onPageChange}
              />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

DataTable.propTypes = {
  dataSource: PropTypes.any,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  onPageChange: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
};
export default DataTable;
