import React, { useState } from "react";
import { Form, Switch, Modal } from "antd";

const LastReportForm = ({ isLastReport }) => {
  const [checked, setChecked] = useState(isLastReport);

  const confirmLastReport = () => {
    if (checked) {
      setChecked(false);
      return;
    }

    Modal.confirm({
      content: `Báo cáo cuối cùng là xác nhận đã tắt camp. Bạn có muốn xác nhận?`,
      cancelText: "Hủy",
      onOk: () => setChecked(!checked),

      cancelButtonProps: {
        type: "primary",
        danger: true,
      },
    });
  };
  return (
    <Form.Item
      label="Xác nhận báo cáo cuối cùng"
      name="isLastReport"
      tooltip="Xác nhận báo cáo này là báo cáo chốt cuối cùng của ngân sách!"
    >
      <Switch checked={checked} onClick={confirmLastReport} />
    </Form.Item>
  );
};

export default LastReportForm;