import React from 'react';
import { Form, Select } from "antd";
import { useRecoilValue } from "recoil";
import { allSites } from "@/recoil/atoms";

const SiteForm = ({ handleSiteChange }) => {
  const sites = useRecoilValue(allSites);

  return (
    <Form.Item name="siteId" className="!m-0">
      <Select
        allowClear
        showSearch
        placeholder="Trang..."
        onChange={handleSiteChange}
        filterOption={(input, option) =>
          (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
        }
        className="!w-[200px]"
        options={sites?.map((site) => ({
          value: site.id,
          label: site.siteName,
        }))}
      />
    </Form.Item>
  );
};

export default SiteForm;