import React from "react";
import { Popover, Button } from "antd";

const GroupUserPopover = ({ userGroup }) => {
  const lenghUser = userGroup?.length;

  return (
    <>
      {lenghUser > 0 && (
        <Popover
          className="!py-0"
          placement="bottom"
          title={null}
          trigger="click"
          content={
            <div className="flex max-w-[250px] flex-wrap gap-1">
              Hậu đài ứng: {userGroup[0]?.group?.groupName}
              {userGroup?.map((user, index) => {
                return (
                  <p className="my-0" key={user?.id}>
                    {user?.fullName}
                    {index < userGroup.length - 1 && <span> + </span>}
                  </p>
                );
              })}
            </div>
          }
        >
          <Button type="link" className="px-0 max-h-4">
            (+{lenghUser})
          </Button>
        </Popover>
      )}
    </>
  );
};

export default GroupUserPopover;
