import React, { useEffect, useState } from "react";
import { Modal, Image, Flex } from "antd";
import {
  CloseOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { motion, AnimatePresence } from "framer-motion";

import { MdOutlineImage } from "react-icons/md";
import moment from "moment";

const ImageCarousel = ({
  selectedIndex,
  viewCarousel,
  setViewCarousel,
  data,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isVideo, setIsVideo] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const isExtensionVideo = (extension) => {
    return ["mp4", "webm", "ogg", "wmv"].includes(extension);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handlePreviousCarousel();
      } else if (event.key === "ArrowRight") {
        handleNextCarousel();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex]);

  useEffect(() => {
    const firstImage = data ? data[0] : null;
    setSelectedImage(firstImage);
  }, [data]);

  useEffect(() => {
    setCurrentIndex(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    if (currentIndex !== null) {
      setSelectedImage(data[currentIndex]);
    }
  }, [currentIndex]);

  useEffect(() => {
    setIsVideo(isExtensionVideo(selectedImage?.fileType));
  }, [selectedImage]);

  const handleClose = () => {
    setViewCarousel(false);
  };

  const handlePreviousCarousel = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const handleNextCarousel = () => {
    if (currentIndex < data?.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const getDisabledClass = (disabled) => {
    return disabled
      ? "text-gray-300 cursor-none"
      : "text-primary cursor-pointer";
  };

  return (
    <Modal
      title={null}
      open={viewCarousel}
      footer={null}
      onCancel={handleClose}
      width="80%"
      closable={false}
      className="!p-0"
      centered
    >
      <div className="flex flex-col h-[90vh] bg-white relative">
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleClose}
          className="absolute top-4 right-4 bg-gray-200 rounded-full w-10 h-10 flex items-center justify-center cursor-pointer z-10"
        >
          <CloseOutlined className="text-gray-600 text-lg" />
        </motion.div>

        <div className="relative flex-col flex-grow flex items-center justify-center p-4">
          <AnimatePresence mode="wait">
            <Flex className="w-[90%]">
              {selectedImage && (
                <motion.div
                  key={selectedImage?.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="w-[80%] h-full flex flex-shrink max-h-[600px] items-center justify-center"
                >
                  {isVideo ? (
                    <video
                      controls
                      className="object-cover rounded-lg cursor-pointer max-h-[600px]"
                      autoPlay={false}
                      preload="metadata"
                      width="100%"
                      height="560px"
                    >
                      <source
                        src={`${process.env.REACT_APP_FILE}/${selectedImage?.filePath}`}
                        type={`video/${selectedImage?.fileType}`}
                      />
                    </video>
                  ) : (
                    <Image
                      alt="Selected"
                      src={`${process.env.REACT_APP_FILE}/${selectedImage?.filePath}`}
                      fallback={<MdOutlineImage />}
                      preview={true}
                      className="max-h-[75vh] object-contain"
                    />
                  )}
                </motion.div>
              )}
              <div className="ml-5 border-[1px] border-solid border-primary w-[20%] h-[60vh] rounded-md">
                <div className="bg-primary px-2 h-[30px] text-white flex items-center justify-between">
                  <span>Mô tả</span>
                  <span className="italic text-xs">
                    {moment(selectedImage?.creationDate + "z").format(
                      "DD/MM/YYYY HH:mm"
                    )}
                  </span>
                </div>
                <div
                  className="px-2 min-h-[1rem] break-words"
                  dangerouslySetInnerHTML={{
                    __html: selectedImage?.description,
                  }}
                />
              </div>
            </Flex>
          </AnimatePresence>

          <LeftCircleOutlined
            className={
              "absolute left-4 p-1 text-[35px] h-[45px] !w-[45px] " +
              getDisabledClass(currentIndex == 0)
            }
            onClick={() => handlePreviousCarousel()}
          />
          <RightCircleOutlined
            className={
              "absolute right-4 p-1 text-[35px] h-[45px] !w-[45px " +
              getDisabledClass(currentIndex == data?.length - 1)
            }
            onClick={() => handleNextCarousel()}
          />
        </div>

        <div className="bg-gray-100 p-4 overflow-x-auto">
          <motion.div
            className="flex justify-center space-x-2 min-w-max"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {data?.map((img, index) => {
              const isVideoFile = isExtensionVideo(img?.fileType);
              const source = `${process.env.REACT_APP_FILE}/${img?.filePath}`;

              return (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setCurrentIndex(index)}
                  className={
                    "cursor-pointer border-[2px] w-[80px] h-[80px] " +
                    (currentIndex === index
                      ? "border-[2px] rounded-lg border-solid border-[#38a7ff]"
                      : "")
                  }
                >
                  {isVideoFile ? (
                    <video
                      className="object-cover rounded-lg cursor-pointer"
                      autoPlay={false}
                      preload="metadata"
                      width={80}
                      height={80}
                    >
                      <source src={source} type={`video/${img?.fileType}`} />
                    </video>
                  ) : (
                    <Image
                      alt={`Thumbnail ${index + 1}`}
                      src={source}
                      fallback={<MdOutlineImage />}
                      width={80}
                      height={80}
                      preview={false}
                      className="object-cover rounded-lg"
                    />
                  )}
                </motion.div>
              );
            })}
          </motion.div>
        </div>
      </div>
    </Modal>
  );
};

export default ImageCarousel;
