import React from 'react';
import { PROCESS_STATUS } from "@/utils/const";
import { Typography } from "antd";

const RejectReason = ({ dataDetail }) => {
  let historyItem;
  const statusCode = dataDetail?.processStatus?.statusCode;

  if (statusCode === PROCESS_STATUS.reject) {
    historyItem = dataDetail?.approvalHistory?.filter(item => item?.processStatus?.statusCode === PROCESS_STATUS.reject)?.pop();
  } else if (statusCode === PROCESS_STATUS.pending) {
    historyItem = dataDetail?.approvalHistory?.filter(item => item?.processStatus?.statusCode === PROCESS_STATUS.pending)?.pop();
  }

  const colors = {
    Reject: "text-red-500",
    Pending: "text-orange-500"
  }

  return historyItem?.note ?
    <Typography.Text className={"ml-1 flex items-center font-semibold text-[16px] uppercase " + colors[statusCode]}>Lý do: {historyItem?.note}</Typography.Text> : null;
};

export default RejectReason;