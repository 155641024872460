import React from 'react';
import { Divider } from "antd";
import { AttachFiles, BankInfo, ExpenseInfo, ProposeCategory } from "@/components/expenseDetail/index";

const ExpenseRequestInfo = ({ dataDetail, handleReload }) => {
  return (
    <>
      <ExpenseInfo dataDetail={dataDetail} />
      <Divider className="!my-3" />
      <ProposeCategory dataDetail={dataDetail} />
      <Divider className="!my-3" />
      <BankInfo dataDetail={dataDetail} />
      <Divider className="!my-3" />
      <AttachFiles dataDetail={dataDetail} handleReload={handleReload} />
    </>
  );
};

export default ExpenseRequestInfo;