import React from "react";
import { Col, Row } from "antd";
import TextLabel from "@/common/TextLabel";
import TextValue from "@/common/TextValue";

const BankInfo = ({ dataDetail }) => {
  const { bankName, accountName, numberAccount, bankNote } = dataDetail || {};
  console.log(dataDetail, "data");

  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <TextLabel>Thông tin ngân hàng: </TextLabel>
        <TextValue>{`${numberAccount} - ${accountName} - ${bankName}`}</TextValue>
      </Col>
      {/* <Col span={16}>
        <TextLabel>Tên tài khoản: </TextLabel>
        <TextValue>{accountName ?? "--"}</TextValue>
      </Col>
      <Col span={8}>
        <TextLabel>Số tài khoản: </TextLabel>
        <TextValue>{numberAccount ?? "--"}</TextValue>
      </Col> */}
      <Col span={8}>
        <TextLabel>Nội dung chuyển khoản: </TextLabel>
        <TextValue>{bankNote ?? "--"}</TextValue>
      </Col>
    </Row>
  );
};

export default BankInfo;
