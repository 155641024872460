import React from "react";
import { Form, Select } from "antd";

const options = [
  { value: true, label: "Đã tắt camp"},
  { value: false, label: "Chưa tắt camp"}
];

const LastReportForm = () => {
  return (
    <Form.Item name="isLastReport" valuePropName="checked" className="!m-0">
      <Select
        allowClear
        className="!w-[200px]"
        placeholder="Trạng thái camp..."
        options={options}
      />
    </Form.Item>
  );
};

export default LastReportForm;
