import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";
import ColumnChart from "@/components/home/ColumnChart";
import Statistics from "@/components/home/Statistics";
import PieChart from "@/components/home/PieChart";
import { ROLES } from "@/utils/const";

const Home = () => {
  document.title = "Trang chủ";
  const userInfor = useRecoilValue(infoUser);
  const roleCode = userInfor?.role?.roleCode;
  const isAdmin = roleCode === ROLES.ADMIN;
  const isTLBank = roleCode === ROLES.TLB;
  const isSupperSite = userInfor?.site?.isSupper;
  const isVisibleFullView = [ROLES.ADMIN, ROLES.PCQ, ROLES.HLV, ROLES.TL, ROLES.TLB, ROLES.TT].includes(roleCode) || isSupperSite;
  const visibleOptions = {
    isShowSite: [ROLES.ADMIN, ROLES.TL].includes(roleCode) || isSupperSite,
    isShowArea: [ROLES.ADMIN, ROLES.PCQ, ROLES.TL, ROLES.TLB].includes(roleCode) || isSupperSite,
    isShowTeam: [ROLES.ADMIN, ROLES.PCQ, ROLES.TL, ROLES.TLB, ROLES.HLV].includes(roleCode) || isSupperSite,
    isShowGroup: ![ROLES.MEMBER].includes(roleCode) || isSupperSite,
    isShowPieChart: ![ROLES.MEMBER].includes(roleCode) || isSupperSite
  }
  const [optionsSelectSite, setOptionsSelectSite] = useState({});
  const [optionsSelectArea, setOptionsSelectArea] = useState({});
  const [optionsSelectTeam, setOptionsSelectTeam] = useState({});
  const [optionsSelectGroup, setOptionsSelectGroup] = useState({});

  useEffect(() => {
    if (userInfor?.site && !isVisibleFullView) {
      setOptionsSelectSite({
        defaultValue: {
          label: userInfor?.site?.siteName,
          value: userInfor?.site?.id
        }
      })
    }
    if (userInfor?.area && !isVisibleFullView) {
      setOptionsSelectArea({
        defaultValue: {
          label: userInfor?.area?.areaName,
          value: userInfor?.area?.id
        }
      })
    }
    if (userInfor?.team && !isVisibleFullView) {
      setOptionsSelectTeam({
        defaultValue: {
          label: userInfor?.team?.teamName,
          value: userInfor?.team?.id
        }
      })
    }
    if (userInfor?.group && !isVisibleFullView) {
      setOptionsSelectGroup({
        defaultValue: {
          label: userInfor?.group?.groupName,
          value: userInfor?.group?.id
        }
      })
    }
  }, []);

  return (
    <>
      <Statistics
        optionsSelectSite={optionsSelectSite}
        optionsSelectArea={optionsSelectArea}
        optionsSelectTeam={optionsSelectTeam}
        optionsSelectGroup={optionsSelectGroup}
        visibleOptions={visibleOptions}
        isAdmin={isAdmin}
        isTLBank={isTLBank}
      />
      <div className="flex flex-wrap mx-[-10px]">
        <div
          className="w-[55%] px-[10px]"
          style={{ width: isVisibleFullView ? "55%" : "100%" }}
        >
          <div className="p-[24px] mt-3 bg-[#fff] rounded-lg border border-solid border-[#e0e0e0]">
            <ColumnChart
              optionsSelectSite={optionsSelectSite}
              optionsSelectArea={optionsSelectArea}
              optionsSelectTeam={optionsSelectTeam}
              optionsSelectGroup={optionsSelectGroup}
              visibleOptions={visibleOptions}
              isAdmin={isAdmin}
              isVisibleFullView={isVisibleFullView}
              isTLBank={isTLBank}
            />
          </div>
        </div>
        {isVisibleFullView && (
          <div className="w-[45%] px-[10px] h-full">
            <div className="p-[24px] mt-3 bg-[#fff] rounded-lg border border-solid border-[#e0e0e0] min-h-[494px]">
              <PieChart
                optionsSelectSite={optionsSelectSite}
                optionsSelectArea={optionsSelectArea}
                optionsSelectTeam={optionsSelectTeam}
                visibleOptions={visibleOptions}
                isAdmin={isAdmin}
                isTLBank={isTLBank}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Home;
