import React from 'react';
import { Col, Row } from "antd";
import { formatCurrencyVND } from "@/utils/common";
import { EXPENSE_TYPE_CODES } from "@/utils/const";
import TextLabel from "@/common/TextLabel";
import TextValue from "@/common/TextValue";
import CategoryTag from "@/common/CategoryTag";

const ProposeCategory = ({ dataDetail }) => {
  const { expenseType, expenseDetails, totalAmount, calAmountExpenseRequest } = dataDetail || {};
  const isCost = [
    EXPENSE_TYPE_CODES.Proposal,
    EXPENSE_TYPE_CODES.IncreaseAdj,
    EXPENSE_TYPE_CODES.DecreaseAdj,
  ].includes(expenseType?.code);
  
  const isDailyReport = [EXPENSE_TYPE_CODES.SpendingHistory].includes(
    expenseType?.code
  );

  const bgColors = {
    "Facebook": "border-[#1877F2]",
    "TikTok": "border-[#000000]",
    "Tiktok": "border-[#000000]",
    "Google Ads": "border-[#4285F4]",
    "Dôi camp 1%": "border-[#FFA500]",
    "Lố ngân sách": "border-[#FFA07A]",
    "Nguyên vật liệu": "border-[#D2B48C]",
    "Refund": "border-[#FF7F50]"
  };

  return (
    <div>
      {expenseDetails?.map((item, idx) => (
        <Row key={idx} gutter={[16, 16]} className={'border-dashed border-[1px] rounded-md mb-4 py-4 ' + bgColors[item?.categoryName]}>
          <Col span={8}>
            <TextValue><CategoryTag text={item?.categoryName} />&nbsp;&nbsp;</TextValue>
            <TextValue>{formatCurrencyVND(item?.amount || 0)}</TextValue>
          </Col>
          {isCost ? (
            <>
              <Col span={8}>
                <TextLabel>Tiền NS: </TextLabel>
                <TextValue>
                  {formatCurrencyVND(item?.amountCamp || 0)}
                </TextValue>
              </Col>
              <Col span={8}>
                <TextLabel>Phí (%): </TextLabel>
                <TextValue>{item?.amountAdditional || 0}</TextValue>
              </Col>
            </>
          ) : (
            <>
              <Col span={8}>
                <TextLabel>Tiền camp: </TextLabel>
                <TextValue>
                  {formatCurrencyVND(item?.amountCamp || 0)}
                </TextValue>
              </Col>
              <Col span={8}>
                <TextLabel>Tiền dôi camp: </TextLabel>
                <TextValue>
                  {formatCurrencyVND(item?.amountAdditional || 0)}
                </TextValue>
              </Col>
            </>
          )}
          {/* <Col span={6}>
            <TextLabel strong>Tổng tiền: </TextLabel>
            <TextValue>{formatCurrencyVND(item?.amount || 0)}</TextValue>
          </Col> */}
        </Row>
      ))}
      {isDailyReport ? (
        <Row gutter={[16, 16]} className="mt-4">
          <Col span={8}>
            <TextLabel>Chi tiêu: </TextLabel>
            <TextValue>{formatCurrencyVND(totalAmount || 0)}</TextValue>
          </Col>
          <Col span={8}>
            <TextLabel>
              Tổng chi tiêu:{" "}
            </TextLabel>
            <TextValue>
              {formatCurrencyVND(
                calAmountExpenseRequest?.calculateAmountInUse || 0
              )}
            </TextValue>
          </Col>
          <Col span={8}>
            <TextLabel>Tổng ngân sách đã ứng: </TextLabel>
            <TextValue>
              {formatCurrencyVND(calAmountExpenseRequest?.calculateAmount || 0)}
            </TextValue>
          </Col>
        </Row>
      ) : (
        <Row gutter={[16, 16]} className="mt-4">
          <Col span={8}>
            <TextLabel>Tổng tiền: </TextLabel>
            <TextValue>{formatCurrencyVND(totalAmount || 0)}</TextValue>
          </Col>
          <Col span={8}>
            <TextLabel>Tổng ngân sách đã ứng: </TextLabel>
            <TextValue>
              {formatCurrencyVND(calAmountExpenseRequest?.calculateAmount || 0)}
            </TextValue>
          </Col>
          <Col span={8}>
            <TextLabel>
              Tổng ngân sách chi tiêu:{" "}
            </TextLabel>
            <TextValue>
              {formatCurrencyVND(
                calAmountExpenseRequest?.calculateAmountInUse || 0
              )}
            </TextValue>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProposeCategory;