import { Modal, message } from "antd";
import { MdOutlineNoteAlt } from "react-icons/md";
import React, { useState } from "react";

import { handleNoteBill } from "@/services/base";

const NoteBill = ({ selectedRowKeys, reloadData }) => {
  const [confirmNoteLoading, setConfirmNoteLoading] = useState(false);

  const handleNoteBillExpense = async () => {
    try {
      setConfirmNoteLoading(true);
      const res = await handleNoteBill({
        expenseRequestIds: selectedRowKeys,
        isNote: true
      });

      if (res.code === 200 && res.error === 0) {
        reloadData();
        message.success(res?.message);
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      message.error("Error: ", error);
    } finally {
      setConfirmNoteLoading(false);
    }
  }

  const confirmNoteBill = () => {
    Modal.confirm({
      title: "Xác nhận",
      content: <span>Bạn chắc chắn muốn note bill <b>{selectedRowKeys?.length}</b> ngân sách vừa chọn?</span>,
      cancelText: "Hủy",
      onOk: async () => await handleNoteBillExpense(),
      confirmLoading: confirmNoteLoading,

      cancelButtonProps: {
        type: "primary",
        danger: true,
      },
    });
  }

  return (
    <div onClick={confirmNoteBill} className="flex items-center">
      <MdOutlineNoteAlt className="mr-2 text-[1.2rem]" />
      Note bill
    </div>
  );
};

export default NoteBill;
