import React from "react";
import { Form, Select } from "antd";

const options = [
  { value: true, label: "Đã bill note"},
  { value: false, label: "Chưa bill note"}
];

const BillNoteStatusForm = () => {
  return (
    <Form.Item name="isNote" className="!m-0">
      <Select
        allowClear
        className="!w-[200px]"
        placeholder="Trạng thái bill note..."
        options={options}
      />
    </Form.Item>
  );
};

export default BillNoteStatusForm;
