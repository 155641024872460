import "@/App.css";
import Providers from "@/components/providers";

function App() {
  return (
    <Providers/>
  );
}

export default App;
