import { Input, message, Modal, Upload } from "antd";
import React from "react";
import { DEPARTMENT__CODE, ROLE_LEVER, ROLES } from "@/utils/const";
import { handleRejectExpense, postFile } from "@/services/base";
import { formatCurrencyVND } from "@/utils/common";
import debounce from "debounce";

const { TextArea } = Input;

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const addDays = (time, day = 1) => {
  const dateTime = new Date(time);
  dateTime.setDate(dateTime.getDate() + day);
  dateTime.setHours(23, 59, 59, 999);

  return dateTime;
};

export const getDateTimeOfNextMonth = (time, day = 0) => {
  const dateTime = new Date(time);
  const year = dateTime.getFullYear();
  const month = dateTime.getMonth();

  const dateTimeOfNextMonth = new Date(year, month + 1);
  dateTimeOfNextMonth.setDate(dateTimeOfNextMonth.getDate() + day);
  dateTimeOfNextMonth.setHours(23, 59, 59, 999);

  return dateTimeOfNextMonth;
};

export const getFileExtension = (filename) => {
  const extension = filename?.slice(
    ((filename.lastIndexOf(".") - 1) >>> 0) + 2
  );
  return extension ? extension.toLowerCase() : "";
};

export const beforeUpload = (file) => {
  const isImage = file.type.startsWith("image/");
  const isVideo = file.type.startsWith("video/");
  const isLt2M = file.size / 1024 / 1024 < 2;
  const isLt100M = file.size / 1024 / 1024 < 100;

  const fileExtension = file.name.split(".").pop().toLowerCase();

  const allowedVideoExtensions = ["mp4", "webm", "ogg", "wmv"];
  const allowedImageExtensions = ["jpg", "jpeg", "png"];

  if (isImage && !isLt2M) {
    message.error("Ảnh upload lên phải bé hơn 2mb");
    return Upload.LIST_IGNORE;
  }

  if (isVideo && !isLt100M) {
    message.error("Video upload lên phải bé hơn 80mb");
    return Upload.LIST_IGNORE;
  }

  if (!isImage && !isVideo) {
    message.error("Chỉ được tải ảnh hoặc video lên");
    return Upload.LIST_IGNORE;
  }

  if (isImage && !allowedImageExtensions.includes(fileExtension)) {
    message.error(
      `Chỉ được tải ảnh có đuôi ${allowedImageExtensions.join(", ")} lên`
    );
    return Upload.LIST_IGNORE;
  }

  if (isVideo && !allowedVideoExtensions.includes(fileExtension)) {
    message.error(
      `Chỉ được tải video có đuôi ${allowedVideoExtensions.join(", ")} lên`
    );
    return Upload.LIST_IGNORE;
  }

  return false;
};

export const addAudioElement = (blob, setVoice) => {
  setVoice(blob);
  const url = URL.createObjectURL(blob);
  const audio = document.createElement("audio");
  audio.src = url;
  audio.controls = true;
  const audioContainer = document.getElementById("audio");
  audioContainer.innerHTML = "";
  audioContainer.appendChild(audio);
};

export const handlePreview = async (file, setPreviewImage, setPreviewOpen) => {
  if (!file.url && !file.preview) {
    file.preview = await getBase64(file.originFileObj);
  }
  setPreviewImage(file.url || file.preview);
  setPreviewOpen(true);
};

export const disableButtonsProcess = (infoState, dataDetail) => {
  const { departmentId, siteId, role, id, teamId, site, areaId } = infoState;

  const isAdmin = role.roleCode === ROLES.ADMIN;
  const isPCQ = role.roleCode === ROLES.PCQ;
  const isHVL = role.roleCode === ROLES.HLV;
  const isQL = role.roleCode === ROLES.QL;
  const isTL = role.roleCode === ROLES.TL;
  const isTLB = role.roleCode === ROLES.TLB;
  const isTT = role.roleCode === ROLES.TT;
  const isMember = role.roleCode === ROLES.MEMBER;
  const isAssignTo = id === dataDetail?.assignTo;
  const isSupCamp = id === dataDetail?.userSupervisorCamp?.id;
  const isSuperSite = site?.isSupper;
  const canPerformTaskMember =
    dataDetail?.processStep?.roleLevelMin === ROLE_LEVER.MEMBER;

  const sameRole = dataDetail?.processStep?.roleLevelMin === role.roleLevel;
  const sameSite = dataDetail?.siteId === siteId;
  const sameDepart = dataDetail?.processStep?.departmentId === departmentId;
  const sameArea = dataDetail?.areaId === areaId;
  const sameTeam = dataDetail?.teamId === teamId;

  const allowedRole = !(
    isAdmin ||
    (isPCQ && sameSite) ||
    (isQL && (isSuperSite || sameSite) && sameDepart) ||
    (isHVL && isSuperSite && sameDepart) ||
    (isHVL && sameSite && sameDepart && sameArea) ||
    ((isTL || isTLB) && (sameSite || isSuperSite) && sameDepart && sameRole) ||
    ((isTT || isMember) &&
      isSuperSite &&
      sameDepart &&
      (sameRole || canPerformTaskMember)) ||
    ((isTT || isMember) &&
      sameSite &&
      sameDepart &&
      sameTeam &&
      (sameRole || canPerformTaskMember)) ||
    (isMember &&
      (isAssignTo || isSupCamp) &&
      sameSite &&
      sameDepart &&
      sameTeam &&
      sameRole)
  );

  return allowedRole;
};

export const allowCloseReq = (infoState, dataDetail) => {
  const { departmentId, siteId, role, id, teamId, site, areaId, groupId } =
    infoState;

  const isAdmin = role.roleCode === ROLES.ADMIN;
  const isPCQ = role.roleCode === ROLES.PCQ;
  const isHVL = role.roleCode === ROLES.HLV;
  const isQL = role.roleCode === ROLES.QL;
  const isTL = role.roleCode === ROLES.TL;
  const isTLB = role.roleCode === ROLES.TLB;
  const isTT = role.roleCode === ROLES.TT;
  const isMember = role.roleCode === ROLES.MEMBER;
  const isAssignTo = id === dataDetail?.assignTo;
  const isSupCamp = id === dataDetail?.userSupervisorCamp?.id;
  const isSuperSite = site?.isSupper;

  const sameSite = dataDetail?.siteId === siteId;
  const sameDepart = dataDetail?.departmentId === departmentId;
  const sameArea = dataDetail?.areaId === areaId;
  const sameTeam = dataDetail?.teamId === teamId;
  const sameGroup = dataDetail?.groupId === groupId;

  const allowedRole =
    isAdmin ||
    (isPCQ && sameSite) ||
    (isQL && (isSuperSite || sameSite) && sameDepart) ||
    (isHVL && isSuperSite && sameDepart) ||
    (isHVL && sameSite && sameDepart && sameArea) ||
    ((isTL || isTLB) && (sameSite || isSuperSite) && sameDepart) ||
    ((isTT || isMember) && isSuperSite && sameDepart) ||
    ((isTT || isMember) && sameSite && sameDepart && sameTeam) ||
    (isMember &&
      (isAssignTo || isSupCamp) &&
      sameSite &&
      sameDepart &&
      sameTeam &&
      sameGroup);

  return allowedRole;
};

export const allowDecreaseReq = (infoState, dataDetail) => {
  const { departmentId, siteId, role, id, teamId, site, areaId, groupId } =
    infoState;

  const isAdmin = role.roleCode === ROLES.ADMIN;
  const isPCQ = role.roleCode === ROLES.PCQ;
  const isHVL = role.roleCode === ROLES.HLV;
  const isQL = role.roleCode === ROLES.QL;
  const isTL = role.roleCode === ROLES.TL;
  const isTLB = role.roleCode === ROLES.TLB;
  const isTT = role.roleCode === ROLES.TT;
  const isMember = role.roleCode === ROLES.MEMBER;
  const isAssignTo = id === dataDetail?.assignTo;
  const isSupCamp = id === dataDetail?.userSupervisorCamp?.id;
  const isSuperSite = site?.isSupper;

  const sameSite = dataDetail?.siteId === siteId;
  const sameDepart = dataDetail?.departmentId === departmentId;
  const sameArea = dataDetail?.areaId === areaId;
  const sameTeam = dataDetail?.teamId === teamId;
  const sameGroup = dataDetail?.groupId === groupId;

  const allowedRole =
    isAdmin ||
    (isPCQ && sameSite) ||
    (isQL && (isSuperSite || sameSite) && sameDepart) ||
    (isHVL && isSuperSite && sameDepart) ||
    (isHVL && sameSite && sameDepart && sameArea) ||
    isTL ||
    isTLB ||
    ((isTT || isMember) && isSuperSite && sameDepart) ||
    ((isTT || isMember) && sameSite && sameDepart && sameTeam) ||
    (isMember &&
      (isAssignTo || isSupCamp) &&
      sameSite &&
      sameDepart &&
      sameTeam &&
      sameGroup);

  return allowedRole;
};

export const showModalReject = (id, setLoading, fetchExpenseDetail) => {
  let note = "";

  const setNote = (value) => {
    note = value;
  };

  Modal.confirm({
    title: "Từ chối",
    content: (
      <div>
        <p className="mt-0">Xác nhận từ chối ngân sách ?</p>
        <TextArea
          rows={4}
          placeholder="Ghi chú..."
          onChange={(e) => setNote(e.target.value)}
        />
      </div>
    ),
    okText: "Đồng ý",
    okType: "primary",
    cancelText: "Hủy",
    onOk: () => handleReject(id, note, setLoading, fetchExpenseDetail),
    cancelButtonProps: {
      type: "primary",
      danger: true,
    },
  });
};

const handleReject = async (id, note, setLoading, fetchExpenseDetail) => {
  if (note.trim() === "") {
    message.warning("Lý do không được để trống!");
  } else {
    try {
      setLoading(true);
      const res = await handleRejectExpense({
        expenseRequestId: id,
        processNote: note,
      });
      if (res.isError) {
        message.error(res.message);
      } else {
        message.success("Đã từ chối");
        fetchExpenseDetail(id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }
};

export const goToPage = (id, page, navigate, isOpenNewTab = false) => {
  const routes = {
    NS: `/expense-request/${id}`,
    BC: `/daily-report/${id}`,
    KT: `/close-request/${id}`,
    DXG: `/decrease-request/${id}`,
    DXT: `/increase-request/${id}`,
  };

  const url = routes[page];

  if (url) {
    if (isOpenNewTab) {
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      navigate(url);
    }
  } else {
    console.warn(`Unknown page: ${page}`);
  }
};

export const validateTotalAmount = (
  groupSummary,
  totlAmount,
  isSpecial,
  specialApproval
) => {
  const isAchieve = groupSummary?.statusCheck === "Achieve";
  const isNotAchieve = groupSummary?.statusCheck === "NotAchieve";
  const isAvailable = groupSummary?.statusCheck === "Available";
  const maxBudget = groupSummary?.budgetAvailable || 0;
  const nsqc = groupSummary?.nsqc || 0;
  const totalBank = groupSummary?.totalBankingDaily || 0;

  if (isSpecial || specialApproval) {
    return;
  }
  // } else if (nsqc && totalBank && totalBank > nsqc) {
  //   return `Ngân sách có thể bank trong ngày còn lại: ${formatCurrencyVND(nsqc - totalBank)}`
  // } else if (isNotAchieve) {
  //   return "Không thể tạo ngân sách do hậu đài chưa đạt tiêu chí"
  // } else if (isAvailable) {
  //   if (maxBudget && totlAmount && totlAmount > maxBudget) {
  //     return `Số tiền không được vượt quá hạn mức ngân sách: ${formatCurrencyVND(maxBudget)}`
  //   }
  // } else if (isAchieve && totlAmount > maxBudget) {
  //   return `Số tiền không được vượt quá ${formatCurrencyVND(maxBudget)}`
  // } else if (!groupSummary) {
  //   return "Không thể tạo ngân sách do hậu đài không có tiêu chí"
  // }
};

export const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const downloadExcelFile = (base64Data, filename) => {
  const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`;
  const fileName = `${filename}-${new Date().toISOString().slice(0, 10)}.xlsx`;

  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const getAmountDetail = (index, dataDetail) => {
  const calAmountExpenseDetail = dataDetail?.calAmountExpenseDetail || [];
  if (calAmountExpenseDetail.length > 0 && calAmountExpenseDetail[index]) {
    return {
      calAmountExpen:
        calAmountExpenseDetail[index].amount -
        calAmountExpenseDetail[index].amountInUse,
      amount: calAmountExpenseDetail[index].amount,
      amountInUse: calAmountExpenseDetail[index].amountInUse,
    };
  }

  return {
    amount: 0,
    amountInUse: 0,
    calAmountExpen: 0,
  };
};

export const handleLoadMore =
  (paginationParams, setPaginationParams) => async (e) => {
    const { target } = e;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      paginationParams.total > paginationParams.pageSize
    ) {
      setPaginationParams((prev) => ({
        ...prev,
        pageSize: prev.pageSize + 10,
      }));
    }
  };

export const handleSearchLoadMore = debounce((e, setPaginationParams) => {
  setPaginationParams((prev) => ({ ...prev, textSearch: e }));
}, 300);

export const handleClearLoadMore = (setPaginationParams) => () => {
  setPaginationParams((prev) => ({ ...prev, textSearch: "" }));
};

export const transformData = (data) => {
  let transformedData = { ...data };

  const propertiesToTransform = [
    "siteId",
    "departmentId",
    "areaId",
    "teamId",
    "groupId",
  ];

  propertiesToTransform.forEach((prop) => {
    if (transformedData[prop] && transformedData[prop].label) {
      transformedData[prop] = transformedData[prop].value;
    }
  });

  return transformedData;
};

export const uploadFileAsync = async (fileList) => {
  const uploadedFiles = [];

  for (let indexFile = 0; indexFile < fileList?.length; indexFile++) {
    const fileItem = fileList[indexFile];

    const dataFile = {
      file: fileItem?.originFileObj,
    };

    const resFile = await postFile(dataFile);
    const fileObject = { ...resFile, description: fileItem?.description };
    uploadedFiles.push(fileObject);
  }

  return uploadedFiles;
};

export const getFormattedTimeDescription = (isoString) => {
  const inputDate = new Date(isoString);
  const now = new Date();

  const format24Hour = (date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const diffInMilliseconds = now - inputDate;
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

  const daysOfWeek = [
    "Chủ nhật",
    "Thứ hai",
    "Thứ ba",
    "Thứ tư",
    "Thứ năm",
    "Thứ sáu",
    "Thứ bảy",
  ];

  if (diffInSeconds < 60) {
    return `${diffInSeconds} giây trước`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} phút trước`;
  } else if (diffInHours < 24) {
    return `${diffInHours} giờ trước`;
  } else if (diffInDays === 1) {
    return `Hôm qua, ${format24Hour(inputDate)}`;
  } else if (diffInDays <= 7) {
    const dayOfWeek = daysOfWeek[inputDate.getDay()];
    return `${dayOfWeek}, ${format24Hour(inputDate)}`;
  } else {
    const day = inputDate.getDate().toString().padStart(2, "0");
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const year = inputDate.getFullYear();
    return `${day}/${month}/${year}, ${format24Hour(inputDate)}`;
  }
};

export const isAdmin = (userData) =>
  [ROLES.ADMIN].includes(userData?.role?.roleCode);
export const isTLB = (userData) =>
  [ROLES.TLB].includes(userData?.role?.roleCode);
export const isTLQC = (userData) =>
  [DEPARTMENT__CODE.TLQC].includes(userData?.department?.departmentCode);

export const objectToSearchString = (obj = {}) => {
  const ignoreKeys = [
    "id",
    "isActive",
    "creationDate",
    "createdBy",
    "modifiedDate",
    "modifiedBy",
  ];

  return Object.entries(obj)
    .filter(([key]) => !ignoreKeys.includes(key))
    .map(([_, value]) => value)
    .join(" ");
};

export const autoFillAmountFields = (
  changedValues,
  allValues,
  form,
  setTotalAmount,
  plusCost,
  isDailyReport,
  isCloseRequest
) => {
  const { expenseDetails } = allValues;
  let total = 0;
  let hasChangedAmountField = false;
  let amountExpenseDetails = expenseDetails;

  // Auto fill the amount field
  if (changedValues?.expenseDetails) {
    changedValues?.expenseDetails?.forEach((expenseAmount) => {
      if (
        expenseAmount?.hasOwnProperty("amountCamp") ||
        expenseAmount?.hasOwnProperty("amountAdditional")
      ) {
        hasChangedAmountField = true;
      }
    });

    if (hasChangedAmountField) {
      const newExpenseDetails = expenseDetails.map((item) => {
        const amountAdditional = item?.amountAdditional || 0;
        const amountCamp = item?.amountCamp || 0;
        const amountInUse = item?.amountInUse || 0;

        if (plusCost) {
          const caculateAmount =
            (amountCamp / 100) * amountAdditional + amountCamp;

          return {
            ...item,
            amount: parseFloat(caculateAmount),
          };
        } else {
          if (isCloseRequest) {
            return {
              ...item,
              amount: parseFloat(amountCamp) - parseFloat(amountAdditional),
            };
          } else if (isDailyReport) {
            return {
              ...item,
              amount: parseFloat(amountCamp) + parseFloat(amountAdditional),
            };
          }

          return {
            ...item,
            amount: parseFloat(amountCamp) + parseFloat(amountAdditional),
          };
        }
      });

      form.setFieldsValue({
        expenseDetails: newExpenseDetails,
      });

      amountExpenseDetails = newExpenseDetails;
    }
  }

  amountExpenseDetails?.forEach((item) => {
    total += parseFloat(item?.amount || 0);
  });

  setTotalAmount(total);
};
