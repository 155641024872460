import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Flex, Steps, Tooltip } from "antd";
import { useNavigate } from "react-router";

const ProgressBar = ({ dataDetail, steps }) => {
  const navigate = useNavigate();
  const { processStatus: { statusCode } = {} } = dataDetail || {};
  const renderProgress = () => {
    const currentStepPriority = dataDetail?.processStep?.stepPriority;    

    const getStatus = (step) => {
      const stepId = step.stepPriority;
      if (currentStepPriority === stepId) {
        switch (statusCode) {
          case "New":
          case "Inprogress":
            return "process";
          case "Pending":
            return "process";
          case "Aproved":
          case "Done":
          case "Close":
            return "finish";
          default:
            return "error";
        }
      } else if (currentStepPriority > stepId) {
        return "finish";
      } else {
        return "wait";
      }
    };

    return steps?.map((step) => ({
      title: <Tooltip title={step.note}>{step.stepName}</Tooltip>,
      status: getStatus(step),
    }));
  };

  return (
    <Flex>
      {window.history.length > 1 && (
        <div>
            <span
              title="Trở về"
              className="p-2 block cursor-pointer hover:opacity-80 -m-[2px]"
              onClick={() => navigate(-1)}
            >
              <ArrowLeftOutlined />
            </span>
        </div>
      )}
      <Steps className="mx-4" labelPlacement="vertical" items={renderProgress()} />
    </Flex>
  );
};

export default ProgressBar;
