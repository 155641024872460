import React from "react";
import { Form, Input} from "antd";

const DescriptionForm = () => {
  return (
    <Form.Item
      label="Mô tả"
      name="note"
      rules={[
        {
          required: true,
          message: "Vui lòng Nhập mô tả!",
        },
        {
          max: 4000,
          message: "Số lượng ký tự tối đa là 4000!",
        },
      ]}
    >
      <Input.TextArea
        showCount
        maxLength={4000}
        rows={6}
        placeholder="Nhập mô tả..."
      />
    </Form.Item>
  );
};

export default DescriptionForm;
