import React from "react";
import { Typography } from "antd";

const TextLabel = ({ children }) => {
  const { Text } = Typography;

  return (
    <Text className="text-[#00000078] font-medium">{children && children}</Text>
  );
};

export default TextLabel;
