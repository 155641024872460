import React from 'react';
import { Col, Row, Typography } from "antd";

const { Text } = Typography;

const PartnerInfo = ({ partner }) => {
  const { partnerFullName, partnerAccount, phoneTele, phoneZalo } = partner || {}
  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <Text strong>Tên đối tác: </Text>
        <Text>{partnerFullName ?? "--"}</Text>
      </Col>
      <Col span={16}>
        <Text strong>Tài khoản đối tác: </Text>
        <Text>{partnerAccount ?? "--"}</Text>
      </Col>
      <Col span={8}>
        <Text strong>Telegram: </Text>
        <Text>{phoneTele ?? "--"}</Text>
      </Col>
      <Col span={16}>
        <Text strong>Zalo: </Text>
        <Text>{phoneZalo ?? "--"}</Text>
      </Col>
    </Row>
  );
};

export default PartnerInfo;