import React, { useEffect, useState } from "react";
import { Form, Input, message, Modal, Select } from "antd";
import CompForm from "@/common/CompForm";
import { addTeam, updateTeam } from "@/services/base";
import { StatusResponse } from "@/utils/const";
import { getListAreas } from "@/utils/fetchData";
import { handleClearLoadMore, handleLoadMore, handleSearchLoadMore, transformData } from "@/utils/utils";
import { useRecoilValue } from "recoil";
import { allDepartments, allSites } from "@/recoil/atoms";

const TeamsFormAction = ({ stateModal, closeModal, reloadPage }) => {
  const sites = useRecoilValue(allSites);
  const departments = useRecoilValue(allDepartments);
  const [form] = Form.useForm();
  const { isOpenModal, isCreate, titleModal, buttonOkModal, editData } = stateModal;
  const [loading, setLoading] = useState(false);
  const [areas, setAreas] = useState([]);
  const [areaParams, setAreaParams] = useState({
    siteId: editData?.siteId,
    departmentId: editData?.departmentId
  });

  useEffect(() => {
    getListAreas(areaParams, setAreas, setAreaParams);
  }, [areaParams.pageSize, areaParams.textSearch, areaParams.siteId, areaParams.departmentId]);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
        siteId: {
          label: editData?.site?.name,
          value: editData?.siteId,
        },
        departmentId: {
          label: editData?.department?.departmentName,
          value: editData?.departmentId,
        },
        areaId: {
          label: editData?.area?.name,
          value: editData?.areaId,
        },
      });
      setAreaParams((prev) => ({
        ...prev,
        siteId: editData?.siteId,
        departmentId: editData?.departmentId,
      }));
    }

    return () => {
      form.resetFields();
    };
  }, [isOpenModal, editData, form]);

  const onFinish = async (body) => {
    const data = transformData(body);
    setLoading(true);
    try {
      const res = isCreate ? await addTeam(data) : await updateTeam(data);
      if (res.code !== StatusResponse.SUCCESS) {
        message.error(res.message);
        return;
      }
      message.success(isCreate ? "Tạo mới tổ thành công" : "Cập nhật tổ thành công.");
      closeModal();
      reloadPage();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSite = (value) => {
    setAreaParams((prev) => ({
      ...prev,
      siteId: value
    }));

    form.resetFields(["departmentId", "areaId"]);
  }

  const handleChangeDepartment = (value) => {
    setAreaParams((prev) => ({
      ...prev,
      departmentId: value
    }));

    form.resetFields(["areaId"]);
  }

  const formItemsConfig = [
    {
      name: "id",
      hidden: true,
      component: <Input hidden />,
    },
    {
      label: "Tên tổ",
      name: "teamName",
      rules: [{ required: true, message: "Tên tổ không được bỏ trống!" }],
      component: <Input placeholder="Nhập tên tổ..." />,
    },
    {
      label: "Trang",
      name: "siteId",
      rules: [{ required: true, message: "Vui lòng chọn trang!" }],
      component: (
        <Select
          allowClear
          showSearch
          placeholder="Chọn trang"
          filterOption={(input, option) =>
            option?.label.toLowerCase().includes(input.toLowerCase())
          }
          onChange={handleChangeSite}
          options={sites.map((site) => ({
            value: site.id,
            label: site.siteName
          }))}
        />
      ),
    },
    {
      label: "Bộ phận",
      name: "departmentId",
      rules: [{ required: true, message: "Vui lòng chọn bộ phận!" }],
      component: (
        <Select
          allowClear
          showSearch
          placeholder="Chọn bộ phận"
          filterOption={(input, option) =>
            option?.label.toLowerCase().includes(input.toLowerCase())
          }
          onChange={handleChangeDepartment}
          options={departments.map((department) => ({
            value: department.id,
            label: department.departmentName
          }))}
        />
      ),
    },
    {
      label: "Khu",
      name: "areaId",
      rules: [{ required: true, message: "Vui lòng chọn khu!" }],
      component: (
        <Select
          allowClear
          showSearch
          placeholder="Khu"
          onSearch={(e) => handleSearchLoadMore(e, setAreaParams)}
          onClear={handleClearLoadMore(setAreaParams)}
          filterOption={(input, option) =>
            option?.label.toLowerCase().includes(input.toLowerCase())
          }
          options={areas.map((area) => ({
            value: area.id,
            label: area.areaName
          }))}
          onPopupScroll={handleLoadMore(areaParams, setAreaParams)}
        />
      ),
    },
  ];

  return (
    <Modal
      title={titleModal}
      open={isOpenModal}
      width="600px"
      onCancel={closeModal}
      footer={null}
    >
      <CompForm
        loading={loading}
        buttonOkModal={buttonOkModal}
        onFinish={onFinish}
        form={form}
        formItemsConfig={formItemsConfig}
        activeBtnSub={true}
      />
    </Modal>
  );
};

export default TeamsFormAction;
