import React from "react";
import { Typography } from "antd";

const TextValue = ({ children }) => {
  const { Text } = Typography;

  return (
    <Text className="text-[#000000e6] font-medium">
      {children && children}
    </Text>
  );
};

export default TextValue;
