export const processStatus = [
  {
    "id": 1,
    "statusCode": "New",
    "statusName": "Mới"
  },
  {
    "id": 2,
    "statusCode": "Inprogress",
    "statusName": "Đang xử lý"
  },
  {
    "id": 3,
    "statusCode": "Pending",
    "statusName": "Đang ngừng xử lý"
  },
  {
    "id": 4,
    "statusCode": "Aproved",
    "statusName": "Xác nhận"
  },
  {
    "id": 5,
    "statusCode": "Done",
    "statusName": "Đã phê duyệt"
  },
  {
    "id": 6,
    "statusCode": "Reject",
    "statusName": "Từ chối"
  },
  {
    "id": 7,
    "statusCode": "Close",
    "statusName": "Đã kết toán"
  }
]