import { Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import React, { useState } from "react";

import { exportExpense } from "@/services/base";
import { downloadExcelFile } from "@/utils/utils";

const ExportExpense = ({ expenseCode, params }) => {
  const [loading, setLoading] = useState(false);

  const handleExportExpense = async () => {
    setLoading(true);
    try {
      const res = await exportExpense(expenseCode, params);
      if (res.code === 200 && res.error === 0) {
        downloadExcelFile(res.data, `Exported-${expenseCode}`);
        message.success("Export file thành công!");
      } else {
        message.error("Export file thất bại!");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div onClick={handleExportExpense}>
      <DownloadOutlined className="mr-2 text-[1.2rem]" />
      Export file
    </div>
  );
};

export default ExportExpense;
