import React, {useEffect, useRef, useState} from "react";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {Button, Collapse, Dropdown, Flex, message, Spin, Modal} from "antd";
import {ArrowsAltOutlined, DownOutlined, ShrinkOutlined} from "@ant-design/icons";
import {HiOutlineUserGroup} from "react-icons/hi";
import {IoIosInformationCircleOutline} from "react-icons/io";
import {MdAutoDelete, MdHistory} from "react-icons/md";
import {getDetailExpenseApi, processingUserExpense} from "@/services/base";
import {infoUser} from "@/recoil/atoms";
import {EXPENSE_TYPE_CODES, PROCESS_STATUS, PROCESSING_EXPENSE_CODE, ROLES} from "@/utils/const";
import {isFullPermission} from "@/utils/common";
import {allowCloseReq, allowDecreaseReq, getDateTimeOfNextMonth} from "@/utils/utils";
import {useNavigate} from "react-router";
import "./styles.css";
import {fetchGroupSummary, getSteps} from "@/utils/fetchData";
import {
    ApproveModalForm,
    CloseRequestForm,
    GroupButtonProcess,
    HistoryProcess,
    HistorySpending,
    ModalDailyReportForm,
    ModalExpenseDecreaseIncrease,
    ModalFormIncreaseDecreaseBudget,
    PartnerInfo
} from "@/components/expenseDetail";
import ProgressBar from "@/common/ProgressBar";
import TagStatus from "@/components/listRequset/TagStatus";
import RejectReason from "@/common/RejectReason";
import ExpenseRequestInfo from "@/components/expenseDetail/ExpenseRequestInfo";
import HistoryDeleteProcess from "@/components/expenseDetail/HistoryDeleteProcess";

const ExpenseDetail = () => {
    const nav = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const requestSegment = currentPath?.split('/')[1];
    const [dataDetail, setDataDetail] = useState([]);
    const [dailyModal, setDailyModal] = useState({
        isOpen: false,
        isConfirm: false,
        title: "",
        isSetMessageValue: false,
    });
    const [approveModal, setApproveModal] = useState({
        isOpen: false,
    });
    const [historyForm, setHistoryForm] = useState({
        isOpen: false,
    });
    const [loading, setLoading] = useState(false);
    const [steps, setSteps] = useState([]);
    const [groupSummary, setGroupSummary] = useState();
    const refExpenseDecreaseIncrease = useRef(null);
    const refModalInDecrease = useRef(null);
    const [modalCloseRequest, setModalCloseRequest] = useState({
        isOpen: false,
    });
    const [isWithinDateRange, setIsWithinDateRange] = useState(false);
    const [modalTypeFormDeInCrease, setModalTypeFormDeInCrease] = useState("");
    const {id} = useParams();
    const [searchParams] = useSearchParams();
    const infoState = useRecoilValue(infoUser);
    const {role} = infoState || {}
    const {roleLevel, roleCode} = role || {};
    const {processStatus, supervisorLeader, assignTo, assignGroupUser, supervisorId, expenseType} = dataDetail || {};
    const {statusCode} = processStatus || {};
    const [isIncreaseBudgetForm, setIsIncreaseBudgetForm] = useState(Boolean(searchParams.get("increaseBudgetForm")));
    const [isOpenIncreaseDecreaseModal, setIsOpenIncreaseDecreaseModal] = useState(false);
    const [activeKeys, setActiveKeys] = useState(['1']);

    useEffect(() => {
        if (id) getExpenseDetail(id);
    }, [id]);

    const handleCancel = () => {
        setDailyModal({isOpen: false});
        setHistoryForm({isOpen: false});
        setApproveModal({isOpen: false});
        setModalCloseRequest({isOpen: false});
    };

    const getExpenseDetail = async (id) => {
        setLoading(true);
        try {
            const expenseDetailCode = {
                "expense-request": EXPENSE_TYPE_CODES.Proposal,
                "increase-request": EXPENSE_TYPE_CODES.IncreaseAdj,
                "decrease-request": EXPENSE_TYPE_CODES.DecreaseAdj
            };

            const res = await getDetailExpenseApi(id, expenseDetailCode[requestSegment]);
            if (
                (res.code === 400 && res.error === -4) ||
                (res.code === 404 && res.error === -5)
            )
                return nav("/404");
            else if (res.code === 403) return nav("/forbidden");
            else if (res.code !== 200 && res.error !== 0)
                return message.error(res?.error);
            setDataDetail(res.data);

            // Open increaseForm
            if (isIncreaseBudgetForm) {
                setIsOpenIncreaseDecreaseModal(true);
                setModalTypeFormDeInCrease("up");
                const url = new URL(window.location.href);
                url.searchParams.delete("increaseBudgetForm");
                window.history.replaceState(null, "", url.toString());
                setIsIncreaseBudgetForm(false);
            }
            fetchGroupSummary(res?.data?.groupId, setGroupSummary);

            document.title = res.data.expenseType.name;
            const startDate = new Date(res.data.startDate);
            const endDateReport = getDateTimeOfNextMonth(res.data.endDate, 1);
            const currentDate = new Date();

            setIsWithinDateRange(currentDate >= startDate && currentDate <= endDateReport);

            await getSteps(res?.data?.processTypeId, setSteps);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const handleSuccessIncreaseDecrease = () => {
        getExpenseDetail(id);
        setIsOpenIncreaseDecreaseModal(false);
    };

    const confirmProcessingExpense = () => {
        Modal.confirm({
            title: "Xác nhận",
            content: "Bạn có chắc chắn chắn muốn tiếp nhận xử lý phiếu này?",
            cancelText: "Hủy",
            onOk: async () => await handleProcessingUserExpense(),

            cancelButtonProps: {
                type: "primary",
                danger: true,
            },
        });
    }

    const handleProcessingUserExpense = async () => {
        try {
            const res = await processingUserExpense(dataDetail?.id);
            if (res.isError) {
                message.error(res.message);
                return;
            }

            message.success(res?.message);
            getExpenseDetail(id);
        } catch (error) {
            message.error("Error: " + error);
        }
    };

    const isAssignee = assignGroupUser?.some((user) => user?.id === infoState?.id);
    const isSupervisor = supervisorId === infoState?.id;
    const finishExpenseRequest = statusCode === PROCESS_STATUS.done;
    const supervisorLeaderIds = supervisorLeader?.map(item => item.id);
    const allowedReport = (isSupervisor || roleCode === ROLES.TL || isFullPermission(roleCode) || supervisorLeaderIds?.includes(infoState?.id) || isAssignee) && finishExpenseRequest;

    let dropdownItems = [
        {
            key: "handleProcess",
            label: <a href onClick={confirmProcessingExpense}>Tiếp nhận xử lý</a>,
            disabled: [PROCESS_STATUS.done, PROCESS_STATUS.close, PROCESS_STATUS.reject].includes(statusCode),
        },
        {
            key: "dailyReport",
            label: <a href onClick={() => setDailyModal({isOpen: true, title: "BÁO CÁO CHI TIÊU HẰNG NGÀY"})}>Báo cáo
                chi tiêu</a>,
            disabled: !(isWithinDateRange && allowedReport),
        },
        {
            key: "historyExpense",
            label: <a href onClick={() => setHistoryForm({isOpen: true})}>Lịch sử chi tiêu</a>,
        },
        {
            key: "increaseRequest",
            label: <a href onClick={() => {
                refModalInDecrease.current.toggle();
                setModalTypeFormDeInCrease("up")
            }}
            >
                Ứng thêm ngân sách
            </a>,
            disabled: !finishExpenseRequest,
        },
        {
            key: "decreaseRequest",
            label: (
                <a href onClick={() => {
                    refModalInDecrease.current.toggle();
                    setModalTypeFormDeInCrease("down")
                }}
                >
                    Giảm ngân sách
                </a>
            ),
            disabled: !(finishExpenseRequest && (isAssignee || allowDecreaseReq(infoState, dataDetail))),
        },
        {
            key: "increaseDecreaseRequest",
            label: <a href onClick={() => refExpenseDecreaseIncrease.current.toggle()}>Xem đề xuất ứng thêm, giảm ngân
                sách</a>
        },
        {
            key: "requestClose",
            label: <a href onClick={() => setModalCloseRequest({isOpen: true})}>Gửi yêu cầu kết toán</a>,
            disabled: !(finishExpenseRequest && (isAssignee || allowCloseReq(infoState, dataDetail))),
        },
    ];

    dropdownItems = dropdownItems.filter(item => {
        if (requestSegment === 'expense-request') {
            return item.key !== 'rollback';
        } else if (requestSegment === 'increase-request') {
            return item.key === 'handleProcess';
        }
        return true;
    });

    const items = [
        {
            key: '1',
            label: <div className="m-0 p-0 flex ">
                <span className="font-semibold text-xl flex items-center"><IoIosInformationCircleOutline/><span
                    className="ml-2">{expenseType?.name}</span></span>
                <TagStatus className="text-[1rem] ml-3 flex items-center" record={dataDetail}/>
                <RejectReason dataDetail={dataDetail}/>
            </div>,
            children: <ExpenseRequestInfo dataDetail={dataDetail} handleReload={() => getExpenseDetail(id)}/>,
        },
        {
            key: '4',
            label: <span className="font-semibold text-xl flex items-center"><HiOutlineUserGroup/><span
                className="ml-2">Đối tác</span></span>,
            children: <PartnerInfo partner={dataDetail?.partner}/>,
            show: dataDetail?.partner ? "1" : "0"
        },
        {
            key: '7',
            label: <span className="font-semibold text-xl flex items-center"><MdHistory/><span
                className="ml-2">Lịch sử</span></span>,
            children: <HistoryProcess approvalHistory={dataDetail?.approvalHistory}/>,
        },
        {
            key: '8',
            label: <span className="font-semibold text-xl flex items-center"><MdAutoDelete/><span className="ml-2">Lịch sử xóa</span></span>,
            children: <HistoryDeleteProcess approvalHistory={dataDetail?.approvalHistory}/>,
        },
    ];

    const panels = items?.map(item => item.key)
    const handleExpandAll = () => setActiveKeys(panels);
    const handleCollapseAll = () => setActiveKeys([]);
    const visiableItems = items?.filter(e => e?.show !== "0");

    return loading ? (
        <div className="w-full min-h-[80vh] flex items-center justify-center">
            <Spin size="large"/>
        </div>
    ) : (
        <>
            <Flex className="flex-col min-h-[80vh]">
                <ProgressBar dataDetail={dataDetail} steps={steps}/>
                <section className='mt-6 relative flex-grow'>
                    <Flex justify="space-between" className="mb-3">
                        <Flex gap={16}>
                            <Button className="px-3" onClick={handleExpandAll}><ArrowsAltOutlined
                                className="text-[18px]"/>Mở tất cả</Button>
                            <Button className="px-3" onClick={handleCollapseAll}><ShrinkOutlined
                                className="text-[18px]"/>Đóng tất cả</Button>
                        </Flex>
                        {(requestSegment === 'expense-request' || requestSegment === 'increase-request') && (
                            <Dropdown menu={{items: dropdownItems}} trigger={['click']}>
                                <Button type="primary"
                                        className="bg-gradient-primary hover:!bg-gradient-secondary active:!bg-gradient-secondary border-l-0">
                                    Chức năng
                                    <DownOutlined/>
                                </Button>
                            </Dropdown>
                        )}
                    </Flex>

                    <Collapse activeKey={activeKeys} items={visiableItems} onChange={setActiveKeys}/>
                </section>
                <GroupButtonProcess
                    dataDetail={dataDetail}
                    id={id}
                    steps={steps}
                    loading={loading}
                    setApproveModal={setApproveModal}
                    getExpenseDetail={getExpenseDetail}
                    setLoading={setLoading}
                />
            </Flex>
            <ModalExpenseDecreaseIncrease
                handleReloadExpendetail={getExpenseDetail}
                ref={refExpenseDecreaseIncrease}
            />
            <ModalFormIncreaseDecreaseBudget
                isOpen={isOpenIncreaseDecreaseModal}
                ref={refModalInDecrease}
                handleReload={() => handleSuccessIncreaseDecrease()}
                dataDetail={dataDetail}
                IncreaseDecreaseType={modalTypeFormDeInCrease}
            />
            <CloseRequestForm
                id={id}
                dataDetail={dataDetail}
                handleCancel={handleCancel}
                modalCloseRequest={modalCloseRequest}
                setLoadingForm={setLoading}
            />
            <ApproveModalForm
                IncreaseDecreaseType="up"
                groupSummary={groupSummary}
                approveModal={approveModal}
                handleCancel={handleCancel}
                dataDetail={dataDetail}
                handleReload={() => getExpenseDetail(id)}
            />
            <ModalDailyReportForm
                dataDetail={dataDetail}
                modalState={dailyModal}
                handleCancel={handleCancel}
                setLoadingForm={setLoading}
            />
            <HistorySpending
                isModalOpen={historyForm.isOpen}
                handleCancel={handleCancel}
                dataDetail={dataDetail}
                handleReload={() => getExpenseDetail(id)}
                setLoadingForm={setLoading}
            />
        </>
    );
};

export default ExpenseDetail;
