import React from "react";
import { Link } from "react-router-dom";

const ExpenseLink = ({ id, page, children }) => {
  const routes = {
    NS: `/expense-request/${id}`,
    BC: `/daily-report/${id}`,
    KT: `/close-request/${id}`,
    DXG: `/decrease-request/${id}`,
    DXT: `/increase-request/${id}`,
  };

  return <Link to={routes[page]}>{children && children}</Link>;
};

export default ExpenseLink;
