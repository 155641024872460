import React from "react";
import { Empty, Modal, Tag, Tooltip } from "antd";
import { AuditOutlined } from "@ant-design/icons";
import DataTable from "@/common/DataTable";
import ExpenseLink from "@/common/ExpenseLink";
import moment from "moment/moment";
import { formatCurrencyVND } from "@/utils/common";
import TagStatus from "@/components/listRequset/TagStatus";
import { useRecoilValue } from "recoil";
import { infoUser } from "@/recoil/atoms";

const HistorySpending = ({ isModalOpen, handleCancel, dataDetail }) => {
  const infoUserState = useRecoilValue(infoUser);

  const renderStatusCol = (value) => {
    return <TagStatus record={value}/>;
  };

  const columns = [
    {
      title: "Mã BC",
      dataIndex: "expenseId",
      key: "expenseId",
      align: "center",
      render: (value, record) => (
        <ExpenseLink id={record?.expenseId} page="BC">BC{record?.expenseId ?? "--"}{record?.isLastReport && (
          <Tooltip title="Đã tắt camp chi tiêu">
            <AuditOutlined className="ml-2" />
          </Tooltip>
        )}</ExpenseLink>
      ),
    },
    {
      title: "Người báo cáo",
      dataIndex: "userCreate",
      key: "userCreate",
      width: 130,
      align: 'center',
      render: (value) => {
        return <>{value?.fullName ?? "--"}</>;
      },
    },
    {
      title: "Tiền camp chi tiêu",
      dataIndex: "amountCamp",
      key: "amountCamp",
      align: "center",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Dôi camp",
      dataIndex: "amountAdditional",
      width: 130,
      key: "amountAdditional",
      align: "center",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng tiền",
      dataIndex: "amount",
      width: 130,
      key: "amount",
      align: "center",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "QUY TRÌNH",
      children: [
        {
          title: "Tên quy trình duyệt",
          dataIndex: "processType",
          key: "processType",
          align: 'center',
          render: (value) => (value?.name ? value?.name : "--"),
        },
        {
          title: "Bước xử lý",
          dataIndex: "processStep",
          key: "processStep",
          align: 'center',
          render: (value, rec) => {
            const isProcessingCurrentUser =
              infoUserState?.departmentId == value?.departmentId;
            const stepName = value?.stepName ? value?.stepName : "--";
            const allowStatus = ["Inprogress", "Pending"].includes(rec?.processStatus?.statusCode)
            return isProcessingCurrentUser && allowStatus && value?.stepName ? (
              <Tag color="blue">{stepName}</Tag>
            ) : (
              stepName
            );
          },
        },
      ],
    },
    {
      title: "Ngày tạo",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 130,
      align: 'center',
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
    {
      title: "Trạng thái",
      dataIndex: "processStatus",
      key: "processStatus",
      width: 140,
      align: 'center',
      fixed: "right",
      render: (_, rec) => renderStatusCol(rec),
    },
  ];

  return (
    <>
      <Modal
        title="DANH SÁCH LỊCH SỬ CHI TIÊU"
        open={isModalOpen}
        width="1600px"
        onCancel={handleCancel}
        footer={null}
      >
        <div className="mb-4">
          {dataDetail && dataDetail?.dailyReportHistory?.length > 0 ? (
            <DataTable
              columns={columns}
              dataSource={dataDetail?.dailyReportHistory || []}
              scroll={{ x: "max-content", y: 500 }}
              isBorder
            />
          ) : (
            <Empty/>
          )}
        </div>
      </Modal>
    </>
  );
};

export default HistorySpending;
