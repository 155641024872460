import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getDailyReport, } from "@/services/base";
import { StatusResponse } from "@/utils/const";
import { Flex, message, Tag, Tooltip, Button, Dropdown } from "antd";
import { AuditOutlined } from "@ant-design/icons";
import DataTable from "@/common/DataTable";
import { infoUser } from "@/recoil/atoms";
import FormSearch from "@/common/FormSearch";
import ExportExpense from "@/common/ExportExpense";
import CountData from "@/common/CountData";
import ExpenseLink from "@/common/ExpenseLink";
import CategoryTag from "@/common/CategoryTag";
import { formatCurrencyVND } from "@/utils/common";
import moment from "moment/moment";
import { QuestionCircleOutlined, DownOutlined } from "@ant-design/icons";

const DailyReport = () => {
  document.title = "Báo cáo hằng ngày";
  const [dailyReport, setDailyReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const infoUserState = useRecoilValue(infoUser);
  const [params, setParams] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  const [totalPages, setTotalPages] = useState(0);

  const columns = [
    {
      key: "id",
      title: "Mã BC",
      dataIndex: "id",
      align: "center",
      fixed: 'left',
      render: (id, record) =>
        record.isRequestClose ? (
          "--"
        ) : (
          <ExpenseLink id={id} page={record.expenseType.shortName}>
            {`${record.expenseType.shortName}${id}`}
            {record?.isLastReport && (
              <Tooltip title="Đã tắt camp chi tiêu">
                <AuditOutlined className="ml-2" />
              </Tooltip>
            )}
          </ExpenseLink>
        ),
    },
    {
      key: "refId",
      title: "Mã NS",
      dataIndex: "refId",
      align: "center",
      render: (redId, record) => (
        <ExpenseLink
          id={redId || record.id}
          page="NS"
        >{`NS${redId || record.id}`}</ExpenseLink>
      ),
    },
    {
      title: "Loại hình",
      dataIndex: "purpose",
      width: 120,
      align: "center",
      key: "purpose",
      render: (value) => (value?.purposeName ? <CategoryTag text={value?.purposeName} /> : "--"),
    },
    {
      title: <span>Số tiền <Tooltip title="Số tiền chi tiêu"><QuestionCircleOutlined /></Tooltip></span>,
      dataIndex: "totalAmount",
      width: 130,
      key: "totalAmount",
      align: "center",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng ngân sách",
      dataIndex: "calculateAmount",
      width: 130,
      key: "calculateAmount",
      align: "center",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Tổng chi tiêu",
      dataIndex: "calculateAmountInUse",
      width: 130,
      key: "calculateAmountInUse",
      align: "center",
      render: (value) => (value ? formatCurrencyVND(value) : "--"),
    },
    {
      title: "Nhóm HD",
      dataIndex: "group",
      key: "group",
      align: "center",
      render: (value, record) => (value ? `${value.groupName} - ${record?.groupInfo?.groupInfoCode}` : "--"),
    },
    {
      title: "QUY TRÌNH",
      children: [
        {
          title: "Tên quy trình duyệt",
          dataIndex: "processType",
          key: "processType",
          align: "center",
          render: (value) => (value?.name ? value?.name : "--"),
        },
        {
          title: "Bước xử lý",
          dataIndex: "processStep",
          key: "processStep",
          align: "center",
          render: (value, rec) => {
            const isProcessingCurrentUser =
              infoUserState?.departmentId == value?.departmentId;
            const stepName = value?.stepName ? value?.stepName : "--";
            const allowStatus = ["Inprogress", "Pending"].includes(rec?.processStatus?.statusCode)
            return isProcessingCurrentUser && allowStatus && value?.stepName ? (
              <Tag color="blue">{stepName}</Tag>
            ) : (
              stepName
            );
          },
        },
      ]
    },
    {
      key: "userCreate",
      title: "Người báo cáo",
      dataIndex: "userCreate",
      align: "center",
      width: 120,
      render: (rec) => rec?.fullName,
    },
    {
      title: "Ngày tạo",
      dataIndex: "creationDate",
      key: "creationDate",
      align: "center",
      width: 130,
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
    {
      title: "Ngày chỉnh sửa",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      align: "center",
      width: 130,
      render: (value) => moment(value + "z").format("D/M/YY HH:mm"),
    },
    {
      title: "Trạng thái",
      dataIndex: "processStatus",
      key: "processStatus",
      align: "center",
      fixed: "right",
      render: ({ statusCode, statusName }, { processingUser }) => {
        const fullNameProcessingUser = processingUser?.fullName;
        const textProcessingUser = statusCode == "Inprogress" && fullNameProcessingUser ? `${fullNameProcessingUser} - ` : "";
        const colorMap = {
          Done: "green",
          Inprogress: "blue",
          Reject: "red",
          Pending: "yellow",
          default: "default"
        };

        const color = colorMap[statusCode] || colorMap.default;

        return <Tag className="mx-0" color={color}>{textProcessingUser}{statusName}</Tag>;
      },
    },
  ];

  const ctaItems = [
    {
      key: "1",
      label: <ExportExpense expenseCode="SpendingHistory" params={params}/>
    }
  ];

  const fetchDailyReport = async (value) => {
    setLoading(true);
    try {
      const res = await getDailyReport(value);
      if (res?.code !== StatusResponse.SUCCESS) {
        message.error(res?.message);
      }
      setTotalPages(res?.pagination?.total);
      const dataWithKey = res?.data
        ? res?.data.map((i) => {
            return { ...i, key: i.id };
          })
        : [];
      setDailyReport(dataWithKey);
    } catch (error) {
      console.log("Error Fetching Daily Report", error);
      message.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDailyReport(params);
  }, [params]);

  const handleOnchangeSearch = useCallback(
    (searchFields) => {
      const newParams = { ...params, ...searchFields };
      setParams(newParams);
    },
    [params]
  );

  const handleOnchangePage = (pageIndex, pageSize) => {
    setParams((prev) => ({ ...prev, pageIndex, pageSize }));
  };

  return (
    <>
      <section className="flex items-end justify-between mb-4">
        <FormSearch
          setSearchParam={setParams}
          onchangeSearch={handleOnchangeSearch}
          storageKey="dailyReportFilter"
          isDailyReportPage={true}
        />
      </section>
      <Flex justify="space-between">
        <CountData loading={loading} total={totalPages} text="báo cáo"/>
        <Dropdown
          menu={{
            items: ctaItems,
          }}
        >
          <Button
            type="primary"
            className="bg-gradient-primary hover:!bg-gradient-secondary active:!bg-gradient-secondary border-l-0"
            onClick={(e) => e.preventDefault()}
          >
            Chức năng
            <DownOutlined />
          </Button>
        </Dropdown>
      </Flex>
      <DataTable
        className="mt-2"
        columns={columns}
        dataSource={dailyReport}
        currentPage={params.pageIndex}
        pageSize={params.pageSize}
        totalPage={totalPages}
        scroll={{ x: "max-content", y: 560 }}
        loading={loading}
        isBorder={true}
        onPageChange={handleOnchangePage}
      />
    </>
  );
};

export default DailyReport;
