import React from "react";
import { Form, Switch } from "antd";

const PriorityNotification = () => {
  return (
    <Form.Item
      label="Thông báo ưu tiên"
      name="isTopNotification"
      tooltip="Nếu bật thông báo ưu tiên, thông báo của bước duyệt này sẽ xuất hiện đầu tiên trong danh sách, giúp người tham gia dễ nhận biết và duyệt nhanh."
    >
      <Switch />
    </Form.Item>
  );
};

export default PriorityNotification;
