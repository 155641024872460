import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  createUpdatePurchaseRequest,
  getInitProcessingStep,
  getSupervisorByProcessIdPaging,
  postFile,
} from "@/services/base";
import { omit } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router";
import { formatCurrencyVND } from "@/utils/common";
import {
  PROCESSING_EXPENSE_CODE,
  StatusResponse,
  TYPE_INCREASE,
} from "@/utils/const";
import {
  autoFillAmountFields,
  getAmountDetail,
  goToPage,
  uploadFileAsync,
  validateTotalAmount,
} from "@/utils/utils";
import CardFormRequest from "@/common/FormCardRequest";
import FormDailyReportForm from "@/components/listRequset/FormDailyReportFormRequest";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { fetchGroupSummary, getListProcessTypes } from "@/utils/fetchData";
import SupervisorInfoForm from "@/components/form/SupervisorInfoForm";
import FigureSiteInfor from "@/common/FigureSiteInfor";
import SpecialApprovalForm from "@/components/form/SpecialApprovalForm";
import BankInfoForm from "@/components/form/BankInfoForm";
import UploadVoiceForm from "@/components/form/UploadVoiceForm";
import UploadImageForm from "@/components/form/UploadImageForm";
import PageNumberForm from "@/components/form/PageNumberForm";
import DescriptionForm from "@/components/form/DescriptionForm";
import { allProducts, paramsSuperivorState } from "@/recoil/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import PartnerInfo from "@/components/form/PartnerInfo";
import DetailAmountForm from "@/components/form/DetailAmountForm";

const { RangePicker } = DatePicker;

const ModalFormIncreaseDecreaseBudget = forwardRef((props, ref) => {
  const { dataDetail, IncreaseDecreaseType, handleReload, isOpen } = props;
  const listProducts = useRecoilValue(allProducts);
  const [listImage, setListImage] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [dataSupervior, setDataSupervior] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [processInit, setProcessInit] = useState(null);
  const [processTypeId, setProcessTypeId] = useState(null);
  const [processTypes, setProcessTypes] = useState([]);
  const [voice, setVoice] = useState(null);
  const [groupSummary, setGroupSummary] = useState();
  const [paramsSuperivor, setParamsSupervior] =
    useRecoilState(paramsSuperivorState);
  const navigate = useNavigate();
  const isIncreaseType = IncreaseDecreaseType === TYPE_INCREASE;
  const isSpecialGroup = dataDetail?.group?.isSupper;

  const [state, setState] = useState({
    open: false,
  });
  const { id } = useParams();
  let location = useLocation();
  const isDailyReportPage = location.pathname === "/daily-report";

  const [form] = Form.useForm();
  const toggle = useCallback(() => {
    setState((p) => ({
      ...p,
      open: !p.open,
    }));
    setProcessInit(null);
    setProcessTypeId(null);
  }, []);

  useEffect(() => {
    if (isOpen) {
      toggle();
    }
  }, [isOpen]);

  useEffect(() => {
    if (processTypeId) {
      fetchInitProcess();
    }
  }, [processTypeId]);

  useEffect(() => {
    if (processInit?.id) {
      fetchSuperviorProcessStep({
        ...paramsSuperivor,
        groupId: dataDetail?.group?.id || null,
      });
    }
  }, [processInit?.id, paramsSuperivor?.groupId]);

  const handleChange = (file) => {
    setListImage(file.fileList);
    form.setFieldsValue({ url: file.fileList });
  };

  const handleSubmitSuccess = (data) => {
    form.resetFields();
    toggle();
    handleReload();
    setLoadingForm(false);
    goToPage(
      data?.expenseRequest?.id,
      isIncreaseType ? "DXT" : "DXG",
      navigate
    );
  };

  const fetchSuperviorProcessStep = async (data) => {
    setLoadingSearch(true);
    try {
      const res = await getSupervisorByProcessIdPaging({
        ...data,
        processStepId: processInit?.id || data?.processStepId,
      });

      if (res.code !== StatusResponse.SUCCESS)
        return message.error(res.message);
      setDataSupervior(res.data);
      setParamsSupervior((prev) => {
        return {
          ...prev,
          pageIndex: res.pagination.pageIndex,
          pageSize: res.pagination.pageSize,
          total: res.pagination.total,
          processStepId: processInit?.id || data.processStepId,
          textSearch: data?.textSearch || "",
        };
      });
      setLoadingSearch(false);
    } catch (error) {
      console.log("Error", error);
      setLoadingSearch(false);
    }
  };

  const onFinish = async (value) => {
    setLoadingForm(true);
    try {
      const formatDate = (date, addDays = 0) =>
        dayjs(date).add(addDays, "day").toISOString();

      const startDate = formatDate(
        value?.timeUseBudget && value?.timeUseBudget[0],
        1
      );
      const endDate = formatDate(
        value.timeUseBudget && value.timeUseBudget[1],
        1
      );

      // Banking information
      let bankInfor = {};
      if (processInit?.isBankInfor) {
        bankInfor = {
          bankName: value?.bankName || null,
          accountName: value?.accountName || null,
          numberAccount: value?.numberAccount || null,
          bankNote: value?.bankNote || null,
        };
      }

      // Upload image, video
      const uploadedFiles = await uploadFileAsync(listImage);

      const attachFiles = uploadedFiles.map((file) => ({
        expenseId: 0,
        filePath: file.data.filePath,
        fileType: file.data.fileType,
        description: file?.description,
      }));

      // Upload Voice
      let attachUploadVoice = null;
      if (processInit?.isUploadVoice && voice) {
        await postFile({
          file: voice,
          fileType: "video/webm",
        })
          .then((res) => {
            attachUploadVoice = {
              expenseId: dataDetail ? dataDetail.id : 0,
              filePath: res?.data?.filePath,
              fileType: res?.data?.fileType,
              isVoice: true,
            };
          })
          .catch((err) => {
            console.log("Error uploadFile", err);
          });
      }
      const expenseDetailAdd = value?.expenseDetails?.map((expenseDetail) => {
        const { categoryId, amount, amountCamp, amountAdditional } =
          expenseDetail;
        return {
          expenseId: 0,
          categoryId,
          amount,
          amountCamp,
          amountAdditional,
        };
      });
      let data;

      const expenseRequest = omit(value, [
        "timeUseBudget",
        "url",
        "expenseDetails",
      ]);

      const isRequestClose = isDailyReportPage ? true : null;

      data = {
        expenseRequest: {
          ...value,
          ...expenseRequest,
          id: 0,
          refId: dataDetail?.id || id,
          expenseTypeCode: isIncreaseType ? "IncreaseAdj" : "DecreaseAdj",
          startDate,
          endDate,
          teamId: dataDetail?.teamId,
          areaId: dataDetail?.areaId,
          totalAmount: totalAmount,
          numOfPage: value?.numOfPage,
          supervisorId: isIncreaseType
            ? value?.supervisorId
            : dataDetail?.supervisorId,
          isRequestClose,
          ...bankInfor,
        },
        expenseDetailAddOrEdit: { expenseDetailAdd },
        attachFiles:
          attachUploadVoice !== null
            ? [...attachFiles, attachUploadVoice]
            : [...attachFiles],
      };

      const res = await createUpdatePurchaseRequest(data);
      if (!res.isError) {
        if (isDailyReportPage) {
          message.success("Kết toán thành công!");
        } else {
          message.success(
            `Tạo đề xuất ${
              isIncreaseType ? "tăng" : "giảm"
            } ngân sách thành công!`
          );
        }
        handleSubmitSuccess(res?.data);
      } else {
        message.error(res.message);
      }
    } catch (err) {
      console.log(err);
      setLoadingForm(false);
    } finally {
      setLoadingForm(false);
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        toggle,
      };
    },
    [toggle]
  );

  const handleAmountChange = (changedValues, allValues) => {
    autoFillAmountFields(changedValues, allValues, form, setTotalAmount, true);
  };

  useEffect(() => {
    form.setFieldsValue({ totalAmount: totalAmount });
  }, [totalAmount, form]);

  useEffect(() => {
    if (isDailyReportPage) {
      const calAmountExpenseDetail = dataDetail?.calAmountExpenseDetail?.map(
        (item) => {
          return {
            id: item.id,
            categoryName: item.categoryName,
            categoryId: item.categoryId,
            amount: item?.amount - item?.amountInUse,
          };
        }
      );
      form.setFieldsValue({
        ...dataDetail,
        timeUseBudget:
          [dayjs(dataDetail?.startDate), dayjs(dataDetail?.endDate)] || null,
        purposeId: dataDetail?.purposeId || null,
        note: "",
        expenseDetails: calAmountExpenseDetail,
      });
    } else {
      isIncreaseType
        ? form.setFieldsValue({
            ...dataDetail,
            processTypeId: null,
            supervisorId: dataDetail?.userSupervisorCamp?.id,
            expenseDetails: [{ categoryId: undefined, amount: "" }],
            departmentId: dataDetail?.department?.id || null,
            assignTo: dataDetail?.assignTo || null,
            siteId: dataDetail?.site?.id || null,
            purposeId: dataDetail?.purposeId || null,
            note: "",
          })
        : form.setFieldsValue({
            ...dataDetail,
            processTypeId: null,
            supervisorId: dataDetail?.userSupervisorCamp?.id,
            timeUseBudget:
              [dayjs(dataDetail?.startDate), dayjs(dataDetail?.endDate)] ||
              null,
            purposeId: dataDetail?.purposeId || null,
            note: "",
            expenseDetails: dataDetail?.expenseDetails?.map((detail) => {
              const newAmount =
                detail?.calculateAmount - detail?.calculateAmountInUse;
              return {
                categoryId: detail?.categoryId,
                amount: newAmount,
              };
            }),
          });
    }

    return () => {
      form.resetFields();
      setTotalAmount(0);
    };
  }, [state.open]);

  useEffect(() => {
    if (isIncreaseType) {
      setTotalAmount(
        dataDetail?.calAmountExpenseRequest?.calculateAmount -
          dataDetail?.calAmountExpenseRequest?.calculateAmountInUse || 0
      );
    }

    if (state.open) {
      getListProcessTypes(
        {
          expenseTypeId: isIncreaseType
            ? PROCESSING_EXPENSE_CODE.IncreaseAdj
            : PROCESSING_EXPENSE_CODE.DecreaseAdj,
          purposeId: dataDetail?.purpose?.id,
        },
        setProcessTypes
      );
      fetchGroupSummary(dataDetail?.groupId, setGroupSummary);
    }
  }, [state.open]);

  const fetchInitProcess = async () => {
    const resProcessType = await getInitProcessingStep(processTypeId);
    setProcessInit(resProcessType?.data);
  };

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err)
  );

  const renderFormListExpenDetail = () => {
    return (
      <Form.List name="expenseDetails">
        {isIncreaseType
          ? (fields, { add, remove }) => (
              <>
                {fields?.map(({ key, name, ...restField }) => {
                  return (
                    <Col
                      span={24}
                      key={key}
                      className="flex gap-2 items-center"
                    >
                      <Row gutter={[10, 0]} className="w-full">
                        <Col span={6}>
                          <Form.Item
                            label="Danh mục đề xuất"
                            name={[name, "categoryId"]}
                            // className="w-[265px]"
                            {...restField}
                            rules={[
                              {
                                required: true,
                                message: "Vui lòng chọn danh mục",
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              showSearch
                              placeholder="Chọn danh mục đề xuất"
                              filterOption={(input, option) =>
                                (option?.children ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            >
                              {listProducts?.map((product) => (
                                <Select.Option
                                  key={product.id}
                                  value={product.id}
                                >
                                  {product.categoryName}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <DetailAmountForm
                          spanNumber={6}
                          name={name}
                          restField={restField}
                        />
                        <Col span={5}>
                          <Form.Item
                            label="Tổng tiền"
                            {...restField}
                            name={[name, "amount"]}
                            rules={[
                              {
                                required: true,
                                message: "Vui lòng nhập số tiền",
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              className={"w-full"}
                              placeholder="Nhập số tiền"
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              parser={(value) =>
                                value?.replace(/\$\s?|(,*)/g, "")
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          {fields.length > 1 && (
                            <MinusCircleOutlined
                              className="text-[16px] absolute right-1 top-[52px]"
                              onClick={() => remove(name)}
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Thêm danh mục đề xuất
                  </Button>
                </Form.Item>
              </>
            )
          : (fields) => (
              <>
                {fields?.map(({ key, name, ...restField }) => {
                  return (
                    <Row gutter={[8, 8]}>
                      <Col span={6}>
                        <Form.Item
                          label="Danh mục đề xuất"
                          name={[name, "categoryId"]}
                          {...restField}
                        >
                          <Select
                            disabled
                            allowClear
                            showSearch
                            placeholder="Chọn danh mục đề xuất"
                            filterOption={(input, option) =>
                              (option?.children ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={dataDetail.calAmountExpenseDetail.map(
                              (item) => {
                                return {
                                  label: item.categoryName,
                                  value: item.categoryId,
                                };
                              }
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <DetailAmountForm
                        spanNumber={6}
                        name={name}
                        restField={restField}
                      />
                      <Col span={6}>
                        <Form.Item
                          label={
                            <>
                              Tổng tiền
                              <Tooltip
                                overlayClassName="!min-w-[320px]"
                                title={
                                  <>
                                    Số tiền NS đã sử dụng:{" "}
                                    {formatCurrencyVND(
                                      getAmountDetail(key, dataDetail)
                                        .amountInUse
                                    )}
                                    .<br></br>Số tiền NS đã được duyệt:{" "}
                                    {formatCurrencyVND(
                                      getAmountDetail(key, dataDetail).amount
                                    )}
                                    .<br></br>
                                  </>
                                }
                              >
                                <QuestionCircleOutlined className="ml-2" />
                              </Tooltip>
                            </>
                          }
                          {...restField}
                          name={[name, "amount"]}
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                            {
                              validator: (_, value) => {
                                const amountItem = getAmountDetail(
                                  key,
                                  dataDetail
                                );
                                const reachedAmount =
                                  amountItem.amount === amountItem.amountInUse;

                                if (value <= 0) {
                                  return Promise.reject(
                                    new Error("Số tiền không được bé hơn 0.")
                                  );
                                } else if (
                                  !isIncreaseType &&
                                  reachedAmount &&
                                  value > 0
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      `Số tiền NS sử dụng đã chạm mốc, không thể đề xuất giảm.`
                                    )
                                  );
                                } else if (value > amountItem.amount) {
                                  return Promise.reject(
                                    new Error(
                                      `Số tiền không được vượt quá ngân sách phê duyệt cho mục này: ${formatCurrencyVND(
                                        amountItem.amount
                                      )}`
                                    )
                                  );
                                }

                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <InputNumber
                            min={0}
                            className={"w-full"}
                            placeholder="Nhập số tiền"
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) =>
                              value?.replace(/\$\s?|(,*)/g, "")
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
      </Form.List>
    );
  };

  const handleOnCancel = () => {
    if (loadingForm) {
      return;
    }

    toggle();
  };

  console.log(
    dataDetail?.userSupervisorCamp?.id,
    "dataDetail?.userSupervisorCamp?.id"
  );

  return (
    <Modal
      title={
        isDailyReportPage
          ? "PHIẾU KẾT TOÁN"
          : isIncreaseType
          ? "ỨNG THÊM NGÂN SÁCH"
          : "GIẢM NGÂN SÁCH"
      }
      open={state.open}
      // maskClosable={false}
      width="1000px"
      onCancel={handleOnCancel}
      footer={null}
    >
      <Divider />
      <Spin spinning={loadingForm} size={"large"}>
        <div className="modal-body">
          <Form
            layout="vertical"
            className="mx-2"
            form={form}
            onFinish={onFinish}
            onValuesChange={handleAmountChange}
            initialValues={{
              expenseDetails: [{ categoryId: undefined, amount: "" }],
              url: [],
              siteId: dataDetail?.site?.id || null,
              teamId: dataDetail?.teamId || null,
              groupId: dataDetail?.group?.id || null,
              departmentId: dataDetail?.department?.id || null,
              timeUseBudget: [],
              assignTo: dataDetail?.assignTo || null,
              supervisorId: dataDetail?.userSupervisorCamp?.id,
            }}
            autoComplete="off"
          >
            <Row gutter={[10, 0]}>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <CardFormRequest title="Thông tin chung của phiếu">
                      <Row gutter={[8, 8]}>
                        <Col span={8}>
                          <Form.Item
                            label="Loại hình quảng cáo"
                            name="purposeId"
                            rules={[
                              {
                                required: true,
                                message: "Vui lòng chọn loại hình quảng cáo!",
                              },
                            ]}
                          >
                            <Select
                              disabled={true}
                              placeholder="Chọn loại hình quảng cáo"
                            >
                              <Select.Option
                                key={dataDetail?.purpose?.id}
                                value={dataDetail?.purpose?.id}
                              >
                                {dataDetail?.purpose?.purposeName}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Chọn quy trình"
                            name="processTypeId"
                            rules={[
                              {
                                required: true,
                                message: "Vui lòng chọn quy trình!",
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              defaultValue={null}
                              onChange={(e) => {
                                if (e === undefined) {
                                  setDataSupervior([]);
                                  setProcessTypeId(e);
                                  setProcessInit(null);
                                  return;
                                }
                                setProcessTypeId(e);
                              }}
                              placeholder="Chọn quy trình"
                              options={processTypes?.map((process) => {
                                return {
                                  value: process.id,
                                  label: process.name,
                                };
                              })}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Chọn trang"
                            name="siteId"
                            rules={[
                              {
                                required: true,
                                message: "Vui lòng chọn trang",
                              },
                            ]}
                          >
                            <Select disabled={true} placeholder="Chọn trang">
                              <Select.Option
                                key={dataDetail?.site?.id}
                                value={dataDetail?.site?.id}
                              >
                                {dataDetail?.site?.siteName}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Bộ phận"
                            name="departmentId"
                            rules={[
                              {
                                required: true,
                                message: "Vui lòng không để trống.",
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              showSearch
                              disabled
                              placeholder="Chọn bộ phận"
                              filterOption={(input, option) =>
                                (option?.children ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            >
                              <Select.Option value={dataDetail?.department?.id}>
                                {dataDetail?.department?.departmentName}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            label="Khu "
                            name="areaId"
                            rules={[
                              {
                                required: true,
                                message: "Vui lòng không để trống.",
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              showSearch
                              disabled
                              placeholder="Chọn khu làm việc"
                              filterOption={(input, option) =>
                                (option?.children ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            >
                              <Select.Option value={dataDetail?.area?.id}>
                                {dataDetail?.area?.areaName}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            label="Tổ làm việc"
                            name="teamId"
                            rules={[
                              {
                                required: true,
                                message: "Vui lòng tổ làm việc",
                              },
                            ]}
                          >
                            <Select
                              disabled={true}
                              placeholder="Chọn tổ làm việc"
                            >
                              <Select.Option
                                key={dataDetail?.team?.id}
                                value={dataDetail?.team?.id}
                              >
                                {dataDetail?.team?.teamName}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            label="Nhóm hậu đài"
                            name="groupId"
                            rules={[
                              {
                                required: true,
                                message: "Vui lòng tổ làm việc",
                              },
                            ]}
                          >
                            <Select disabled={true} placeholder="Nhóm hậu đài">
                              <Select.Option
                                key={dataDetail?.group?.id}
                                value={dataDetail?.group?.id}
                              >
                                {dataDetail?.group?.groupName}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="Người được giao"
                            name="assignTo"
                            rules={[
                              {
                                required: true,
                                message: "Người được giao không được bỏ trống!",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Chọn người được giao"
                              disabled={true}
                            >
                              <Select.Option
                                key={dataDetail?.userAssignTo?.id}
                                value={dataDetail?.userAssignTo?.id}
                              >
                                {dataDetail?.userAssignTo?.userName}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        {processInit?.isNumOfPage && (
                          <Col span={8}>
                            <PageNumberForm />
                          </Col>
                        )}

                        <Col span={8}>
                          <Form.Item
                            label="Thời gian sử dụng ngân sách"
                            name="timeUseBudget"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Vui lòng chọn thời gian sử dụng ngân sách!",
                              },
                            ]}
                          >
                            <RangePicker
                              placeholder={["Ngày Bắt Đầu", "Ngày Kết Thúc"]}
                              format={"DD/MM/YYYY"}
                              allowClear
                              size="medium"
                              className="w-full"
                              disabledDate={(current) =>
                                current && current < moment().startOf("day")
                              }
                            />
                          </Form.Item>
                        </Col>
                        {processInit?.isNote && (
                          <Col span={24}>
                            <DescriptionForm />
                          </Col>
                        )}
                      </Row>
                    </CardFormRequest>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[10, 10]}>
                      {processInit?.isAmount && (
                        <Col span={24}>
                          <CardFormRequest title="Thông tin những danh mục đề xuất">
                            {renderFormListExpenDetail()}
                            <Form.Item
                              label={
                                <>
                                  Tổng tiền danh mục đề xuất
                                  <Tooltip
                                    overlayClassName="!min-w-[320px]"
                                    title={
                                      <>
                                        Số tiền NS đã sử dụng:{" "}
                                        {formatCurrencyVND(
                                          dataDetail?.calAmountExpenseRequest
                                            ?.calculateAmountInUse || 0
                                        )}
                                        .<br></br>Số tiền NS đã được duyệt:{" "}
                                        {formatCurrencyVND(
                                          dataDetail?.calAmountExpenseRequest
                                            ?.calculateAmount || 0
                                        )}
                                        .<br></br>
                                      </>
                                    }
                                  >
                                    <QuestionCircleOutlined className="ml-2" />
                                  </Tooltip>
                                </>
                              }
                              name="totalAmount"
                              rules={[
                                {
                                  validator: (_, value) => {
                                    const calculateAmount =
                                      dataDetail?.calAmountExpenseRequest
                                        ?.calculateAmount;
                                    if (
                                      calculateAmount &&
                                      totalAmount > calculateAmount &&
                                      !isIncreaseType
                                    ) {
                                      return Promise.reject(
                                        new Error(
                                          `Tổng số tiền không được vượt quá ngân sách phê duyệt ${formatCurrencyVND(
                                            calculateAmount
                                          )}`
                                        )
                                      );
                                    } else if (isIncreaseType) {
                                      const isSpecial =
                                        processInit?.isSpecialApprove ||
                                        isSpecialGroup;
                                      const isValidated = validateTotalAmount(
                                        groupSummary,
                                        value,
                                        isSpecial
                                      );
                                      if (isValidated) {
                                        return Promise.reject(
                                          new Error(isValidated)
                                        );
                                      }
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <Typography.Text>
                                <b>{formatCurrencyVND(totalAmount || 0)}</b>
                              </Typography.Text>
                            </Form.Item>
                            {isIncreaseType && (
                              <FigureSiteInfor
                                groupSummary={groupSummary}
                                totalAmount={totalAmount}
                                isSpecial={isSpecialGroup}
                              />
                            )}
                          </CardFormRequest>
                        </Col>
                      )}
                      {processInit?.isUploadBill && (
                        <Col span={12}>
                          <CardFormRequest title={"Thông tin hình ảnh"}>
                            <UploadImageForm
                              listImage={listImage}
                              form={form}
                              handleChange={handleChange}
                            />
                          </CardFormRequest>
                        </Col>
                      )}
                      {processInit?.isSpecialApprove && (
                        <Col span={12}>
                          <CardFormRequest
                            className="overflow-auto mb-4"
                            title="Phê duyệt đặc biệt"
                          >
                            <SpecialApprovalForm />
                          </CardFormRequest>
                        </Col>
                      )}
                      {processInit?.supervisorDepartmentId && (
                        <Col span={12}>
                          <CardFormRequest title="Thông tin người phụ trách camp">
                            <SupervisorInfoForm dataSupervior={dataSupervior} />
                          </CardFormRequest>
                        </Col>
                      )}
                      {processInit?.isUploadVoice && (
                        <Col span={12}>
                          <CardFormRequest title="Xác nhận bằng giọng nói">
                            <UploadVoiceForm
                              recorderControls={recorderControls}
                              setVoice={setVoice}
                            />
                          </CardFormRequest>
                        </Col>
                      )}
                      {processInit?.isPartnerInfor && (
                        <Col span={12}>
                          <CardFormRequest
                            className="overflow-auto mb-4"
                            title="Thông tin đối tác"
                          >
                            <PartnerInfo />
                          </CardFormRequest>
                        </Col>
                      )}
                      {processInit?.isBankInfor && (
                        <Col span={12}>
                          <CardFormRequest title={"Thông tin ngân hàng"}>
                            <BankInfoForm />
                          </CardFormRequest>
                        </Col>
                      )}
                      {processInit?.isMessInfor && (
                        <Col span={12}>
                          <CardFormRequest title={"Báo cáo chi tiêu"}>
                            <FormDailyReportForm
                              dataDetail={dataDetail}
                              form={form}
                              dataSupervior={dataSupervior}
                              loadingSearch={loadingSearch}
                            />
                          </CardFormRequest>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row justify="end" className="flex gap-4">
              <Button
                type="primary"
                danger
                className="w-[100px]"
                onClick={handleOnCancel}
              >
                Hủy bỏ
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingForm}
                disabled={loadingForm}
                className="w-[100px]"
              >
                Lưu
              </Button>
            </Row>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
});

export default ModalFormIncreaseDecreaseBudget;
