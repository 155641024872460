import { selector } from "recoil";
import { getAccessTokenBase, getUserInfor, sortRolesByRoleLevel } from "@/utils/common";
import { StatusResponse } from "@/utils/const";
import {
  getAllSites,
  getListDepartmentTypes,
  getListPurpose,
  getPagingDepartments,
  getPagingRole,
  getProposedCategoryList,
  getUserById
} from "@/services/base";

const isAuthenticated = () => {
  const accessToken = getAccessTokenBase();
  const userInfor = getUserInfor();
  if (!accessToken || !userInfor) return null;
  return JSON.parse(userInfor);
};

export const selectorUserInfo = selector({
  key: "selectorUserInfo",
  get: async () => {
    const accessToken = getAccessTokenBase();
    let userInfor = getUserInfor();
    if (!accessToken || !userInfor) return;

    userInfor = JSON.parse(userInfor);
    const res = await getUserById(userInfor.id);

    if (res?.code !== StatusResponse.SUCCESS)
      return localStorage.removeItem("access_token");
    return res?.data;
  },
});

export const selectorSite = selector({
  key: "selectorAllSite",
  get: async () => {
    const userInfor = isAuthenticated();
    if (!userInfor) return [];

    try {
      const response = await getAllSites();
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching sites:", error);
      return [];
    }
  },
});

export const selectorDepartments = selector({
  key: "selectorDepartments",
  get: async () => {
    const userInfor = isAuthenticated();
    if (!userInfor) return [];

    try {
      const response = await getPagingDepartments();
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching departments:", error);
      return [];
    }
  },
});

export const selectorPurposes = selector({
  key: "selectorPurposes",
  get: async () => {
    const userInfor = isAuthenticated();
    if (!userInfor) return [];

    try {
      const response = await getListPurpose();
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching purposes:", error);
      return [];
    }
  },
});

export const selectorProducts = selector({
  key: "selectorProducts",
  get: async () => {
    const userInfor = isAuthenticated();
    if (!userInfor) return [];

    try {
      const response = await getProposedCategoryList({});
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching products:", error);
      return [];
    }
  },
});

export const selectorRoles = selector({
  key: "selectorRoles",
  get: async () => {
    const userInfor = isAuthenticated();
    if (!userInfor) return [];

    try {
      const response = await getPagingRole();
      const sortedRoles = sortRolesByRoleLevel(response?.data);
      return sortedRoles || [];
    } catch (error) {
      console.error("Error fetching roles:", error);
      return [];
    }
  },
});

export const selectorDepartType = selector({
  key: "selectorDepartType",
  get: async () => {
    const userInfor = isAuthenticated();
    if (!userInfor) return [];

    try {
      const response = await getListDepartmentTypes();
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching selectorDepartType:", error);
      return [];
    }
  },
});
