import React from 'react';
import { Col, Form, InputNumber, Row, Select, Tooltip } from "antd";
import { formatCurrencyVND } from "@/utils/common";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getAmountDetail } from "@/utils/utils";
import DetailAmountCampForm from "./DetailAmountCampForm";
import DetailAmountForm from "./DetailAmountForm";

const CategoryForm = ({ dataDetail, isDailyReport, isCloseRequest }) => {
  const spanNumber = 6;

  const validateAmount = (key, value) => {
    const amountItem = getAmountDetail(key, dataDetail);
    const reachedAmount = amountItem.amount === amountItem.amountInUse;

    if (value < 0) {
      return Promise.reject("Số tiền không được âm!");
    }

    if (isDailyReport && value > dataDetail?.calAmountExpenseRequest?.calculateAmount) {
      return Promise.reject(`Số tiền không được lớn hơn ngân sách khả dụng: ${formatCurrencyVND(dataDetail?.calAmountExpenseRequest?.calculateAmount)}`);
    }

    if (isCloseRequest) {
      if (amountItem.calAmountExpen > 0 && value > amountItem.calAmountExpen) {
        return Promise.reject(`Số tiền không được lớn hơn ngân sách đã sử dụng cho mục này: ${formatCurrencyVND(amountItem.calAmountExpen)}`);
      }
      if (reachedAmount && value > 0) {
        return Promise.reject("Số tiền không thể lớn hơn 0 vì NS sử dụng đã chạm mốc.");
      }
    }

    if (!isCloseRequest && !isDailyReport && value < amountItem.amountInUse) {
      return Promise.reject(`Số tiền không được thấp hơn ngân sách đã sử dụng cho mục này: ${formatCurrencyVND(amountItem.amountInUse)}`);
    }

    if (value > amountItem.amount) {
      return Promise.reject(`Số tiền không được vượt quá ngân sách phê duyệt cho mục này: ${formatCurrencyVND(amountItem.amount)}`);
    }

    return Promise.resolve();
  };

  const renderTooltipContent = (key) => {
    const { amountInUse, amount } = getAmountDetail(key, dataDetail);
    return (
      <>
        Số tiền NS đã sử dụng: {formatCurrencyVND(amountInUse)}.<br />
        Số tiền NS đã được duyệt: {formatCurrencyVND(amount)}.<br />
      </>
    );
  };

  return (
    <Form.List name="expenseDetails">
      {(fields) => (
        <>
          {fields?.map(({ key, name, ...restField }) => (
            <Row gutter={[8, 8]} key={key}>
              <Col span={spanNumber}>
                <Form.Item
                  label="Danh mục đề xuất"
                  name={[name, "categoryId"]}
                  {...restField}
                >
                  <Select
                    disabled
                    allowClear
                    showSearch
                    placeholder="Chọn danh mục đề xuất"
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={dataDetail.calAmountExpenseDetail.map(item => ({
                      label: item.categoryName,
                      value: item.categoryId,
                    }))}
                  />
                </Form.Item>
              </Col>
              {(isDailyReport || isCloseRequest) ? (
                <DetailAmountCampForm
                  spanNumber={spanNumber}
                  name={name}
                  restField={restField}
                />
              ) : (
                <DetailAmountForm
                  spanNumber={spanNumber}
                  name={name}
                  restField={restField}
                />
              )}
              
              <Col span={spanNumber}>
                <Form.Item
                  label={
                    <>
                      Tổng tiền
                      <Tooltip
                        overlayClassName="!min-w-[320px]"
                        title={renderTooltipContent(key)}
                      >
                        <QuestionCircleOutlined className="ml-2" />
                      </Tooltip>
                    </>
                  }
                  {...restField}
                  name={[name, "amount"]}
                  rules={[
                    {
                      required: isDailyReport ? true : false,
                      message: "Vui lòng nhập số tiền",
                    },
                    {
                      validator: (_, value) => validateAmount(key, value),
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    disabled={isDailyReport ? false : true}
                    className="w-full"
                    placeholder={isDailyReport ? "Nhập số tiền" : ""}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
        </>
      )}
    </Form.List>
  );
};

export default CategoryForm;