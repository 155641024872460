import React, { memo, useCallback } from "react";
import { Input, Select, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "debounce";
import { useRecoilValue } from "recoil";
import { allDepartments, allSites } from "@/recoil/atoms";

const AreaFilterForm = ({ onchangeSearch }) => {
  const sites = useRecoilValue(allSites);
  const departments = useRecoilValue(allDepartments);

  const debouncedSearch = useCallback(
    debounce(onchangeSearch, 300),
    [onchangeSearch]
  );

  const handleInputChange = useCallback((event) => {
    debouncedSearch("textSearch", event.target.value);
  }, [debouncedSearch]);

  const handleSelectChange = useCallback((key) => (value) => {
    onchangeSearch(key, value);
  }, [onchangeSearch]);

  const filterOption = useCallback((input, option) =>
      option?.label.toLowerCase().includes(input.toLowerCase()),
    []);

  return (
    <Space className="flex flex-wrap">
      <Input
        placeholder="Tìm kiếm khu..."
        allowClear
        onChange={handleInputChange}
        addonBefore={<SearchOutlined />}
        className="w-[220px]"
      />
      <Select
        allowClear
        className="w-[220px]"
        showSearch
        placeholder="Chọn trang"
        onChange={handleSelectChange("siteId")}
        filterOption={filterOption}
        options={sites?.map(({ id, siteName }) => ({
          value: id,
          label: siteName,
        }))}
      />
      <Select
        allowClear
        className="w-[220px]"
        showSearch
        placeholder="Chọn bộ phận"
        onChange={handleSelectChange("departmentId")}
        filterOption={filterOption}
        options={departments?.map(({ id, departmentName }) => ({
          value: id,
          label: departmentName,
        }))}
      />
    </Space>
  );
};

export default memo(AreaFilterForm);